const urls = {
  USER_MGT_BASE_PATH: process.env.REACT_APP_USER_MGT_BASE_PATH,
  WALLET_SERVICE_BASE_PATH_WALLET: process.env.REACT_APP_WALLET_SERVICE_BASE_PATH_WALLET,
  WALLET_SERVICE_BASE_PATH_CURRENCY: process.env.REACT_APP_WALLET_SERVICE_BASE_PATH_CURRENCY,
  WALLET_SERVICE_BASE_PATH_TRANSACTIONS: process.env.REACT_APP_WALLET_SERVICE_BASE_PATH_TRANSACTION,
  ESCROW_SERVICE_PRODUCT_BASE_PATH: process.env.REACT_APP_ESCROW_SERVICE_PRODUCT_BASE_PATH,
  ESCROW_SERVICE_ORDER_DETAILS_BASE_PATH: process.env.REACT_APP_ESCROW_SERVICE_ORDER_DETAILS_BASE_PATH,
  ESCROW_SERVICE_BANK_BASE_PATH: process.env.REACT_APP_ESCROW_SERVICE_BANKS_BASE_PATH,
  ESCROW_SERVICE_DASHBOARD_PATH: process.env.REACT_APP_ESCROW_SERVICE_DASHBOARD_PATH,
  ESCROW_SERVICE_DISPUTE_PATH: process.env.REACT_APP_ESCROW_SERVICE_DISPUTE_PATH,
  ESCROW_SERVICE_OTP_PATH: process.env.REACT_APP_ESCROW_SERVICE_OTP_PATH,
  WALLET_SERVICE_OTP_PATH: process.env.REACT_APP_WALLET_SERVICE_OTP_PATH,
  ESCROW_THIRD_PARTY_SERVICE_PATH: process.env.REACT_APP_ESCROW_THIRD_PARTY_SERVICE_PATH,
  ESCROW_STORE_FRONT_SERVICE_PATH: process.env.REACT_APP_ESCROW_STORE_FRONT_SERVICE_PATH
}
export default urls