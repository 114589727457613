import { toastConstants } from "../constants";

export const initialState = {
    message: '',
    open: false,
    className: "",
    icon: "",
    category: ""
};

export const toast = (state = initialState, action) => {
    switch (action.type) {
        case toastConstants.SHOW_TOAST:
            return {
                ...state,
                message: action.payload,
                open: true,
                className: "show",
                icon: action.icon,
                category: action.category
            };

        case toastConstants.CLEAR_TOAST:
            return {
                ...state,
                message: "",
                open: false,
                className: "",
                icon: "",
                type: ""
            };

        default:
        return {
            ...state
        };
    }
}