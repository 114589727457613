const urls = {
  generateProductLink: "/generate-product-link",
  productReferenceUrl: "/product-reference",
  deleteProductAndOrdersByOrderReference: "/delete-product-and-order-by-order-reference",
  verifyTransaction: "/verify-transaction",
  doNameEnquiry: "/do-name-enquiry",
  getAllProductsByReferenceList: "/by-references",
  getCartSummary: "/cart-summary",
  getAllCategories: "/categories"
}
   
export default urls