import request from '../../../shared/services/request.service'
import UrlService from './url.service'
import { Component } from 'react';

class DashboardService extends Component{

    static getMerchantByUserId(userId){
        let options = Object();

        options.url = UrlService.getMerchantByUserId+"/"+userId;
        options.repo = "USER-MANAGEMENT";
        options.authenticated = true;
      
        return request.get(options);
    }

    static getWalletsByUserId(userId){
        let options = Object();

        options.url = UrlService.getWalletsByUserId+"?userId="+userId;
        options.repo = "WALLET_WALLET";
        options.authenticated = true;
      
        return request.get(options);
    }

    static resendConfirmationToken(userId){
        let options = Object();

        options.url = UrlService.resendConfirmationToken+"/"+userId;
        options.repo = "USER-MANAGEMENT";
        options.authenticated = false;
      
        return request.get(options);
    }

    static getCardDetails(userId){
        let options = Object();

        options.url = UrlService.getCardDetails+"/"+userId;
        options.repo = "ESCROW_DASHBOARD";
        options.authenticated = true;
      
        return request.get(options);
    }

    static getChartDetails(userId){
        let options = Object();

        options.url = UrlService.getChartDetails+"/"+userId;
        options.repo = "ESCROW_DASHBOARD";
        options.authenticated = true;
      
        return request.get(options);
    }
    
    static getRecentOrders(userId){
        let options = Object();

        options.url = UrlService.getRecentOrders+"/"+userId;
        options.repo = "ESCROW_DASHBOARD";
        options.authenticated = true;
      
        return request.get(options);
    }

}

export default DashboardService