const urls = {
  getWalletsByUserId: '/user',
  getTransactionsByIdAndCurr: '/get-transactions',
  getCurrencies: '/fetch-all',
  getWalletBalance: '/get-wallet-balance',
  doTransfer: "/transfer",
  withdraw: "/withdraw",
  validateTransferRequest: "/validate-transfer-request",
  validateWithdrawalRequest: "/validate-withdrawal-request",
  generateOtp: "/generate-opt"
}
export default urls