// import SharedService from './shared.service';
import Cookies from 'universal-cookie';

var _ = require('lodash');
const cookies = new Cookies();
let merchantDetails = {};

export class UtilService {
    
    static setRememberMeToken (response) {
        cookies.set('user', response);
    }

    static getRememberMeToken () {
        return (cookies.get('user'));
    }

    static removeRememberMeToken () {
        cookies.remove('user');
    }

    static hasRememberMeToken () {
        if((cookies.get('user')) === undefined)
            return false;
        else
            return true;
    }

    static setSession (token, userId, role) {
        
        this.setToken(token);
        this.setUserId(userId);
        this.setRole(role);
        // this.setUserId(response.Username);
        // this.setAuthType(response.authType);
        // this.setRole(response.Role);
        // this.setId(response.Id);
        // this.setPermissions(response.Permissions)
    }

    static removeSession () {
        
        this.setToken(null);
        this.setUserId(null);
        this.setRole(null);
        this.setMerchantDetails(null);
        this.setBank(null);
        this.setComponentActive(null);

        // this.setUserId(null);
        // this.setAuthType(null);
        // this.setRole(null);
        // this.setId(null);
        // this.setPermissions(null)
    }

    static hasSession () {
        
        return (sessionStorage.getItem('auth:token') !== null
            // &&
            // sessionStorage.getItem('trade-saas-admin:auth:user_id') !== null &&
            // sessionStorage.getItem('trade-saas-admin:auth:user_role') !== null  &&
            // sessionStorage.getItem('trade-saas-admin:auth:id') !== null  &&
            // sessionStorage.getItem('trade-saas-admin:auth:user_auth_type') !== null &&
            // sessionStorage.getItem('trade-saas-admin:auth:permissions') !== null
        );

    }

    static getToken () { return sessionStorage.getItem('auth:token');    }

    static getUserId () { return sessionStorage.getItem('merchant:userId');    }
    
    static getRole () { return sessionStorage.getItem('merchant:role');    }

    static getMerchantDetails(){
        return JSON.parse(sessionStorage.getItem('merchant:details'));
    }
    static getBank () { return sessionStorage.getItem('merchant:bank');    }

    static setBank(bank) {
        if(_.isNull(bank)) {
            sessionStorage.removeItem('merchant:bank');
        } else {
            sessionStorage.setItem('merchant:bank', bank);
        }
    }

    static getComponentActive () { return sessionStorage.getItem('componentActive');    }

    static setComponentActive(component) {
        if(_.isNull(component)) {
            sessionStorage.removeItem('componentActive');
        } else {
            sessionStorage.setItem('componentActive', component);
        }
    }

    static setToken (token) {

        if(_.isNull(token)) {
            sessionStorage.removeItem('auth:token');
        } else {
            sessionStorage.setItem('auth:token', token);
        }

    }

    static setUserId (userId){
        if(_.isNull(userId)) {
            sessionStorage.removeItem('merchant:userId');
        } else {
            sessionStorage.setItem('merchant:userId', userId);
        }
    }

    static setRole (role){
        if(_.isNull(role)) {
            sessionStorage.removeItem('merchant:role');
        } else {
            sessionStorage.setItem('merchant:role', role);
        }
    }

    static setMerchantDetails(response){
        if(_.isNull(response)) {
            sessionStorage.removeItem('merchant:details');
        } else {
            merchantDetails = {
                firstName: response.firstName,
                lastName: response.lastName,
                status: response.status
            }
            sessionStorage.setItem('merchant:details', JSON.stringify(merchantDetails));
        }
    }
    
    // static getId () { return sessionStorage.getItem('trade-saas-admin:auth:id');  }
        
    // static setId (id) {

    //     if (_.isNull(id)) {
    //         sessionStorage.removeItem('trade-saas-admin:auth:id');
    //     } else {
    //         sessionStorage.setItem('trade-saas-admin:auth:id', id);
    //     }

    // }
        
    // static getUserId () { return sessionStorage.getItem('trade-saas-admin:auth:user_id');  }
        
    // static setUserId (userId) {

    //     if (_.isNull(userId)) {
    //         sessionStorage.removeItem('trade-saas-admin:auth:user_id');
    //     } else {
    //         sessionStorage.setItem('trade-saas-admin:auth:user_id', userId);
    //     }

    // }
        
    // static getAuthType () { return sessionStorage.getItem('trade-saas-admin:auth:user_auth_type'); }
        
    // static setAuthType (authType) {

    //     if(_.isNull(authType)) {
    //         sessionStorage.removeItem('trade-saas-admin:auth:user_auth_type');
    //     } else {
    //         sessionStorage.setItem('trade-saas-admin:auth:user_auth_type', authType);
    //     }

    // }
        
    // static getRole () { return JSON.parse(sessionStorage.getItem('trade-saas-admin:auth:user_role')); }
        
    // static setRole (role) {

    //     if (_.isNull(role)) {
    //         sessionStorage.removeItem('trade-saas-admin:auth:user_role');
    //     } else {
    //         sessionStorage.setItem('trade-saas-admin:auth:user_role', JSON.stringify(role));
    //     }

    // }

    // static setPermissions (permission) {

    //     if (_.isNull(permission)) {
    //         sessionStorage.removeItem('trade-saas-admin:auth:permissions');
    //     } else {
    //         sessionStorage.setItem('trade-saas-admin:auth:permissions', JSON.stringify(permission));
    //     }

    // }

    // static getPermission (permissionId) { 
    //     let permissions = JSON.parse(sessionStorage.getItem('trade-saas-admin:auth:permissions'))
    //     let permission = _.find(permissions, {name:  permissionId});
    //     return permission === undefined ? false : true  
    // }
        
    // static isAdmin () {
    //     return this.hasSession() && this.getRole().name === "ROLE_ADMIN";
    // }

    // static isLdap () {
    //     return this.hasSession() && this.getAuthType() !== "DB";
    // }

    // static hasPermission (permissionId) {
    //     return this.hasSession() && this.getPermission(permissionId);
    // }
    
    static signOut () {
        this.removeSession();
        return window.location.href = "#/";
        
    }

    // static getParamBanks () {
    //     return SharedService.getAppParam("BANK_CODES")
    //     .then(response => {            
    //         if (response.value) {
    //             this.setBank(response.value);
    //             return response.value
    //         }
    //     })
    // }

    // static setBank (result) {
    //     sessionStorage.setItem('trade-saas-admin:banks', result);
    // }

    // static getBanks () {
    //     const banks = JSON.parse(sessionStorage.getItem('trade-saas-admin:banks'));
    //     if (banks === null) return []
    //     return banks
    // }

    // static getBank (bankCode) {
    //     let banks = this.getBanks();
    //     return _.find(banks, {code: bankCode });
    // }
}

export default UtilService;