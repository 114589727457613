import React, { Component }  from 'react';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import { Table, Input, Button, Space, Tag, Row, Col, Popconfirm, Typography, Modal } from 'antd';
import EditProduct from '../editProduct';
import OrderService from '../../order/services/order.service'
import UtilService from '../../../services/util.service'
import WalletService from '../../../components/wallet/services/wallet.service'
import ProductService from '../../../components/product/services/product.service'
import Notification from '../../../shared/components/notification';
import host from '../../../shared/services/host.service'

import "./index.css"

const { Paragraph } = Typography;
const { TextArea } = Input;

class ProductTable extends Component{
  state = {
    searchText: '',
    searchedColumn: '',
    isEditModalVisible: false,
    setConfirmLoading: false,
    data: this.props.data,
    pendingOrder: [],
    pendingOrderModal: false,
    cancellationFee: undefined,
    notification: {},
    affectedOrders: 0
  };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            <Input
            ref={node => {
                this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
            <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
            >
                Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
            </Button>
            <Button
                type="link"
                size="small"
                onClick={() => {
                confirm({ closeDropdown: false });
                    this.setState({
                        searchText: selectedKeys[0],
                        searchedColumn: dataIndex,
                    });
                }}
            >
                Filter
            </Button>
            </Space>
        </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'red' : undefined }} />,
        onFilter: (value, record) =>
        record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
        if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
        }
        },
        render: text =>
        this.state.searchedColumn === dataIndex ? (
            <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    processDelete = (reference) => {
        this.setState({
            setConfirmLoading: true
        });
        let userId = UtilService.getUserId();
        WalletService.getWalletsByUserId(userId)
        .then(walletResponse => {
            OrderService.getPendingOrdersByWalletIdAndProductReference(walletResponse.id, reference)
            .then(response => {
                if(response && response.length > 0){
                    this.getCancellationFee(response)
                }else{
                    this.handleDelete(reference);
                }
            })
        })
    };

    getCancellationFee = (response) =>{
        OrderService.getCancellationFee(response)
        .then(data =>{
            this.setState({
                cancellationFee: data.fee,
                pendingOrder: response,
                affectedOrders: data.affectedOrders,
                pendingOrderModal: true,
                setConfirmLoading: false
            })
        })
    };

    handleDelete = (reference, pendingOrder) => {
        if(this.state.pendingOrderModal){
            this.setState({
                pendingOrderModal: false,
                setConfirmLoading: true
            })
        }
        let userId = UtilService.getUserId();
        WalletService.getWalletsByUserId(userId)
        .then(response => {
            let orderList = this.getOrderReferenceIfExist(pendingOrder);
            ProductService.deleteProductAndOrdersByOrderReference(response.id, reference, orderList)
            .then(response => {
                if(response.status === "success"){
                    this.notify("success", "Success", "Product deleted successfully")
                    setTimeout(function(){ window.location.reload(); }, 1000);
                }else{
                    this.setState({
                        setConfirmLoading: false
                    });
                    this.notify("error", "Unable to complete request.", "An error occurred")
                }
            })
        })
    };

    getOrderReferenceIfExist = (pendingOrder) =>{
        if(pendingOrder === undefined){
            return [];
        }else if(pendingOrder.length > 0){
            let referenceList = [];
            pendingOrder.map((data) => {
                referenceList.push(data.orderReference)
            });
            return referenceList;
        }
    };

    formatLink = link => {
        return link.replace(/,/g, '-');
    };

    notify = (type, title, message) => {
        let notification = {
          type: type,
          title: title,
          message: message,
        };
        this.setState({ notification });
    };

    clearNotificationType = () => {
        this.setState({notification: {}})
    };

  render() {
    const { data, pendingOrder, pendingOrderModal, cancellationFee, affectedOrders } = this.state
    let index = 0;
    let tableData = [];
    const columns = [
        {
            title: 'S/N',
            dataIndex: 'sn',
        },
        {
            title: 'Name',
            dataIndex: 'productName',
            ...this.getColumnSearchProps('productName'),
        },
        {
            title: 'Price',
            dataIndex: 'price',
            ...this.getColumnSearchProps('price'),
        },
        {
            title: 'Qty',
            dataIndex: 'quantity',
            ...this.getColumnSearchProps('quantity'),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            ...this.getColumnSearchProps('description'),
        },
        ,
        {
            title: 'Category',
            dataIndex: 'category',
            ...this.getColumnSearchProps('category'),
        },
        {
          title: 'Status',
          dataIndex: 'status',
          sorter: {
            compare: (a, b) => a.status - b.status,
            multiple: 1,
          },
        },
        {
          title: 'Discount (%)',
          dataIndex: 'discount',
        },
        {
          title: 'Reference',
          dataIndex: 'productRef',
          ...this.getColumnSearchProps('productRef'),
        },
        {
            title: 'Link',
            dataIndex: 'link'
          },
        {
          title: 'Action',
          dataIndex: 'actions',
        },
    ];

    tableData = data && data.length > 0 && data.map(item => {
        return{
            sn: index = index + 1,
            productName: item.productName.substring(0, 50) + (item.productName.length > 50 ? "..." : ""),
            price: item.price.toLocaleString(),
            quantity: item.quantity,
            description: item.description.substring(0, 50) + (item.description.length > 50 ? "..." : ""),
            category: item.category.name,
            status: item.status === "ACTIVE" ? 
                            <Tag color="#65D6C9" className="status">Active</Tag> :
                            <Tag color="#FE9C96" className="status">Inactive</Tag>,
            discount: item.discount,
            productRef: item.productRef,
            link:   <a href={`${host.host}/product/reference/${item.productRef}`}>
                        <Paragraph copyable type="danger">{this.formatLink(`${host.host}/product/reference/${item.productRef}`)}</Paragraph>
                    </a>,
            actions: <Row justify="space-between">
                        <Col span={24}>
                            <EditProduct product={item}/>
                        </Col>
                        <Col span={24}>
                            <Popconfirm title="Confirm delete?" okText="YES" onConfirm={() => this.processDelete(item.productRef)}>
                                <Button type="text" disabled={this.state.setConfirmLoading}><DeleteOutlined /> </Button>
                            </Popconfirm>
                        </Col>
                    </Row>
        }
    });

    return(
      <div>
          <Notification notification={this.state.notification} clearNotificationType = {this.clearNotificationType} />    
          <div style={{background: "#fff", padding: 30, boxShadow: "0 1px 9px -3px rgba(0, 0, 0, 0.2)"}}>
              <Row>
                <Col xs={0} sm={0} md={24}><h4>All products</h4></Col>
                <Col md={0} lg={0} style={{margin: -10, fontSize: "12px"}}><h4>All products</h4></Col>
              </Row>
          </div>
          <Row>
              <Col xs={0} sm={0} md={24} style={{padding: 30, background: "#fff"}}>
                    <Table columns={columns} dataSource={tableData} size="middle" loading={this.state.setConfirmLoading} scroll={{ x: 1000 }}/>
              </Col>


              {/* ===========>>>>RESPONSIVE MOBILE PRODUCT VIEW<<<<================  */}
              <Col md={0} lg={0} style={{width: "100%"}}>
                  {this.state.data.map((data) => {
                      return(<>
                          <div className="TT-responsive-card">
                            <Row className="product-responsive-view-row">
                                <Col span={20}>
                                    <div style={{fontSize: 10, color: "gray"}}>Product Name: </div>
                                    <div style={{fontSize: 10, fontWeight: "bold"}}>{data.productName && data.productName !== null ? data.productName : "NIL" }</div>
                                </Col>

                                <Col span={4}>
                                    <div style={{fontSize: 10, color: "gray"}}>Price: </div>
                                    <span style={{fontSize: "10px"}}>{data.price && data.price !== null ? <b>{'N' + data.price.toLocaleString()}</b> : "NIL" }</span>
                                </Col>
                            </Row>
                            <br/>
                            <Row className="product-responsive-view-row">
                                <Col span={20} style={{fontSize: "8px"}}>
                                    <div style={{fontSize: 10, color: "gray"}}>Product Ref: </div>
                                    <span style={{fontSize: "10px"}}>{data.productRef && data.productRef !== null ? data.productRef : "NIL" }</span>
                                </Col>
                                <Col span={4} style={{float: "left"}}>
                                    <div style={{fontSize: 10, color: "gray"}}>
                                        Discount:<br></br> <b>{data.discount && (data.discount !== null || data.discount === 0) ? data.discount : "0" }%</b>
                                    </div>
                                </Col>
                            </Row>
                            <br/>
                            <Row className="product-responsive-view-row">
                                <Col span={24} style={{textAlign: "left"}}>
                                    <div style={{fontSize: 10, color: "gray"}}>Description: </div>
                                    <TextArea rows={1} disabled={true} style={{fontSize: 10, border: "0.5px solid #eee"}}
                                        placeholder ={data.description && data.description !== null ? data.description : "NIL"}>
                                    </TextArea>
                                </Col>
                            </Row>
                            <br/>
                            <Row className="product-responsive-view-row">
                                <Col span={24} style={{fontSize: "8px", color: "gray"}}>
                                    <div style={{fontSize: 10, color: "gray"}}>Product Link: </div>
                                    {data.productRef && data.productRef !== null && 
                                        <a href={`${host.host}/product/reference/${data.productRef}`}>
                                            <Paragraph copyable type="danger">{this.formatLink(`${host.host}/product/reference/${data.productRef}`)}</Paragraph>
                                        </a>
                                    }
                                </Col>
                            </Row>
                            <br/>
                            <Row justify="space-between">
                                <Col span={8} style={{fontSize: 10, color: "gray"}}>
                                    {data.status && data.status === "ACTIVE" ? 
                                    <Tag color="#65D6C9" className="status">Active</Tag> :
                                    <Tag color="#FE9C96" className="status">Inactive</Tag>}
                                </Col>
                                <Col span={8}>
                                    <EditProduct product={data}/>
                                </Col>
                                <Col span={8}>
                                    <Popconfirm title="Confirm delete?" okText="YES" onConfirm={() => this.processDelete(data.productRef)}>
                                        <Button type="text" disabled={this.state.setConfirmLoading}><DeleteOutlined /> </Button>
                                    </Popconfirm>
                                </Col>
                            </Row>
                        </div>
                        <br/>
                      </>
                      )
                  })}
              </Col>
          </Row>

          <Modal visible={pendingOrderModal}
            width={500}
            onOk={() => {this.handleDelete(pendingOrder[0].productReference, pendingOrder)}}
            onCancel={() => this.setState({pendingOrderModal: !pendingOrderModal})}
            maskClosable={true}
            footer={[
                <Button key="cancel" onClick={() => this.setState({pendingOrderModal: !pendingOrderModal})}>
                    Cancel
                </Button>,
                <Button key="proceed" type="primary" onClick={() => {this.handleDelete(pendingOrder[0].productReference, pendingOrder)}}>
                    Proceed
                </Button>,
            ]}
        >
            <h2 style={{color: "red"}}>WARNING!</h2>
            <h3 style={{color: "red"}}>You have {affectedOrders} order(s) for this products,
                deleting it will cancel the order(s) and attract a fee of N{cancellationFee}</h3>
            <br></br>
            <span  style={{color: "red"}}>Do you wish to proceed?</span>
        </Modal>
      </div>
    )
  }
}

export default ProductTable