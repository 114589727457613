import React, {Component} from "react";
import {Row, Col} from "antd";
import { CaretRightFilled } from '@ant-design/icons';
import './index.css';

class ResponsiveView extends Component{
    state = {
        data: this.props.data,
    }

    render() {
        const {data} = this.state;
        return(
            <div className="TT-responsive-card">
                <Row className="responsive-view-row">
                    <Col span={12}>
                        {data.type.id === "C" && <CaretRightFilled style={{fontSize: 12, color: "#65D6C9"}}/>}
                        {data.type.id === "D" && <CaretRightFilled style={{fontSize: 12, color: "#FE9C96"}}/>}
                        <span style={{fontSize: 9, fontWeight: "bold"}}>{data.description && data.description !== null ? data.description : "NIL" }</span>
                    </Col>

                    <Col span={12}>
                        {data.amount && data.amount !== null 
                            ? 
                            <b>{data.type.id === "C" ? 'N' + data.amount.toLocaleString() : '-N' + data.amount.toLocaleString()}</b> 
                            : 
                            "NIL"
                        }
                    </Col>
                </Row>
                <br></br>
                <Row className="responsive-view-row">
                    <Col span={12} style={{fontSize: "8px"}}>
                        <span style={{fontSize: 10, color: "gray"}}>Transaction ID: </span><br/>
                        {data.globalId && data.globalId !== null ? data.globalId : "NIL" }
                    </Col>

                    <Col span={12}>
                        <span style={{fontSize: 10, color: "gray"}}>
                            Fee: {data.fee && (data.fee !== null || data.fee === 0) ? 'N' + data.fee.toLocaleString() : "0.00" }
                        </span>
                    </Col>
                </Row>
                <br></br>
                <Row className="responsive-view-row">
                    <Col span={12} style={{fontSize: 10, color: "gray"}}>
                        <span style={{fontSize: 10, color: "gray"}}>Date: </span>
                        {data.createdAt && data.createdAt !== null ? data.createdAt : "NIL" }
                    </Col>

                    <Col span={12} style={{fontSize: 10, color: "gray"}}>
                        From {data.sourceAccount && data.sourceAccount !== null ? data.sourceAccount : "NIL" } to {data.destinationAccount && data.destinationAccount !== null ? data.destinationAccount : "NIL" }
                    </Col>
                </Row>
            </div>
        )
    }
}

export default ResponsiveView;