import React, { Component } from 'react';
import {Layout, Breadcrumb, Row, Col, Button, Empty, Modal, Form, Input, Select, Spin, Tag} from 'antd';
import { Wrapper } from '../index'
import Notification from '../../shared/components/notification';

import { DashboardOutlined, WalletOutlined, SwapOutlined, CreditCardOutlined } from '@ant-design/icons';
import TransactionTable from './transactionsTable/index'
import CardComponent from "../../shared/components/cardComponent/index";
import UtilService from '../../services/util.service'
import WalletService from './services/wallet.service'
import OrderService from '../order/services/order.service'
import ProductService from '../product/services/product.service'
import empty from '../../assets/empty.svg'
import Loading from '../../shared/components/loading/index'
import WalletWidget from '../../shared/components/walletWidget';

import './index.css'

const { Content } = Layout;
const { Option } = Select;
  
class WalletComponent extends Component {

    componentDidMount() {
        this.getTransactionsByIdAndCurr();
    }

    componentDidUpdate(){
        UtilService.setComponentActive("wallet");
    }

    getTransactionsByIdAndCurr = () => {
        let userId = UtilService.getUserId();
        WalletService.getWalletsByUserId(userId)
        .then(response => {
            if(response.id){
                this.setState({
                    isMerchantHasWallet: true,
                    bankName: response.bankName,
                    accountNumber: response.accountNumber,
                    balance: response.balance,
                    walletName: response.walletName
                });
                let formData = {
                    userId: userId,
                    currency: response.currency
                };
                WalletService.getTransactionsByIdAndCurr(formData)
                .then(response => {
                    if(response && response.length > 0){
                        this.setState({
                            data: response,
                            loading: false
                        })
                    }else {
                        this.setState({
                            loading: false
                        })
                    }
                })
            }else {
                this.setState({
                    isMerchantHasWallet: false,
                    loading: false
                })
            }
        })
    };

    state = {
        bankName: "",
        accountNumber: "",
        balance: null,
        data: [],
        isMerchantHasWallet: undefined,
        isWalletModalVisible: false,
        currency: [],
        bankList: [],
        formData: {
            walletName: undefined,
            businessName: undefined,
            address: undefined,
            accountNumber: undefined,
            bankName: undefined,
            bvn: undefined,
            currency: undefined
        },
        setConfirmLoading: false,
        notification: {},
        loading: true,
        nameEnquiryResponse: undefined,
        accountVerificationLoading: false,
        walletName: undefined,
        showWalletWidget: false,
        walletWidgetAction: undefined,
        visible: false
    };

    showModal = () => {
        if(UtilService.getMerchantDetails() !== null &&
           UtilService.getMerchantDetails().status !== null &&
           UtilService.getMerchantDetails().status === "ACTIVE"){
            this.setState({
                isWalletModalVisible: true
            })
            this.getCurrencies();
            this.getBanks();
        }else{
            this.notify("error", "Account not activated", "Please activate account to proceed")
        }
    };

    handleCancel = () => {
        this.setState({
            isWalletModalVisible: false,
        })
        this.clearForm();
    };

    isShowTransfer = () =>{
        this.setState({
            showWalletWidget: !this.state.showWalletWidget,
            walletWidgetAction: "Transfer",
            visible: true,
        })
    };
    
    isShowWithdraw = () =>{
        this.setState({
            showWalletWidget: !this.state.showWalletWidget,
            walletWidgetAction: "Withdraw",
            visible: true,
        })
    };

    hideDrawer = () => {
        this.setState({
            showWalletWidget: !this.state.showWalletWidget,
            visible: false,
        });
    };
    
    clearForm = () => {
        this.setState({
            formData: {
                walletName: undefined,
                businessName: undefined,
                address: undefined,
                accountNumber: undefined,
                bankName: undefined,
                bvn: undefined,
                currency: undefined
            },
            currency: '',
            bankList: ''
        })
    };

    getCurrencies = () => {
        WalletService.getCurrencies()
            .then(response => {
                if(response.length > 0){
                    this.setState({
                        currency: response,
                    })
                }
            })
    };

    getBanks = () => {
        OrderService.getAllBanks()
            .then(response => {
                this.setState({
                    bankList: response,
                })
            })
    };


    handleOk = () => {
        if(this.validateForm()) {
            let {formData} = this.state;
            formData.userId = UtilService.getUserId();

            this.setState({
                setConfirmLoading: true
            });
            WalletService.createWallet(formData)
            .then(response => {
                if(response.status === "success"){
                    let wallet = JSON.parse(response.message);
                    this.setState({
                        balance: wallet.balance,
                        isMerchantHasWallet: true,
                        setConfirmLoading: false
                    });
                    this.notify("success", "Success", "Wallet created successfully")
                }else{
                    this.setState({
                        setConfirmLoading: false
                    });
                    this.notify("error", "An error occurred", response.message)
                }
            })
        }
    };
    
    validateForm = () => {
        const { formData, nameEnquiryResponse } = this.state;

        if(formData.walletName === undefined || formData.walletName === "") {
            this.notify("error", "Unable to process request", "Wallet Name is required");
            return false;
        }
        if(formData.businessName === undefined || formData.businessName === "") {
            this.notify("error", "Unable to process request", "Business Name is required");
            return false;
        }
        if(formData.currency === undefined || formData.currency === "") {
            this.notify("error", "Unable to process request", "Currency is required");
            return false;
        }
        if(formData.address === undefined || formData.address === "") {
            this.notify("error", "Unable to process request", "Address is required");
            return false;
        }
        if(formData.bankName === undefined || formData.bankName === "") {
            this.notify("error", "Unable to process request", "Bank Name is required");
            return false;
        }
        if(formData.accountNumber === undefined || formData.accountNumber === "") {
            this.notify("error", "Unable to process request", "Account Number is required");
            return false;

        }else if(isNaN(formData.accountNumber) || formData.accountNumber.length < 10 || formData.accountNumber.length > 10){
            this.notify("error", "Unable to process request", "Invalid Account Number Supplied");
            return false;
        }
        if(formData.bvn === undefined || formData.bvn === "") {
            this.notify("error", "Unable to process request", "BVN is required");
            return false;
        }else if(isNaN(formData.bvn) || formData.bvn.length < 11 || formData.bvn.length > 11){
            this.notify("error", "Unable to process request", "Invalid BVN Supplied");
            return false;
        }
        if(nameEnquiryResponse === undefined || nameEnquiryResponse === "") {
            this.notify("error", "Unable to process request", "Account number not resolved");
            return false;
        }
        return true;
    };

    notify = (type, title, message) => {
        let notification = {
          type: type,
          title: title,
          message: message,
        };
        this.setState({ notification });
    };

    clearNotificationType = () => {
        this.setState({notification: {}})
    };

    handleInputChange = (e) => {
        const { name, value} = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        })
    };

    handleValidateAccountInputChange = (e) => {
        const { name, value} = e.target;
        const regexp = /^[0-9/b]+$/;

        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        }, () =>{
            if(this.state.formData.accountNumber.length === 10){
                if(!regexp.test(value)){
                    this.notify("error", "Invalid input supplied", "Account number should be only digits")
                }else{
                    this.setState({
                        accountVerificationLoading: true,
                        nameEnquiryResponse: undefined
                    });
                    const accountNumber = this.state.formData.accountNumber;
                    const bankCode = this.state.formData.bankName;

                    ProductService.doNameEnquiry(accountNumber, bankCode)
                    .then(response =>{
                        if(response.status === "SUCCESS" && response.message !== undefined && response.message.length > 0){
                            this.setState({
                                accountVerificationLoading: false,
                                nameEnquiryResponse: response.message === "null" ? "Invalid account details" : response.message,
                            });
                        }else {
                            this.setState({
                                accountVerificationLoading: false,
                            });
                            this.notify("error", "Unable to resolve account", response.message);
                        }
                    })
                }
            }else {
                this.setState({
                    nameEnquiryResponse: undefined,
                });
            }
        });
    };

    handleCurrencyChange = value => {
        this.setState({
            formData : {
                ...this.state.formData,
                currency:{
                    name: value
                }
            }
        })
    };

    handleSelectChange = (e) => {
        this.setState({
            nameEnquiryResponse: undefined,
            formData: {
                ...this.state.formData,
                bankName: e,
                accountNumber: undefined,
            }
        })
    };

    render() {
        const { isWalletModalVisible, formData, setConfirmLoading, currency, isMerchantHasWallet, data, loading, bankList,
            nameEnquiryResponse, accountVerificationLoading, walletName, showWalletWidget, walletWidgetAction } = this.state;

        const currencyOption = currency.length > 0 && currency.map((currency) => 
            <Option key={currency.name} value={currency.name}>
                    {currency.name}
            </Option>
        )

        const bankListOption = bankList.length > 0 && bankList.map((option) =>
            <Option key={option.name} value={option.code}>
                {option.name}
            </Option>
        )

        return (
            <Wrapper>
                <Content className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <Notification notification={this.state.notification} clearNotificationType = {this.clearNotificationType} />
                    <Breadcrumb style={{ margin: '16px 0' }}>
                        <Breadcrumb.Item><DashboardOutlined /> &nbsp;Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item><WalletOutlined />&nbsp; {walletName} Wallet</Breadcrumb.Item>
                    </Breadcrumb>

                    {this.state.loading && <Loading background={"#fff"}/>}

                    <Row>
                        <Col span={24}>
                           
                            {isMerchantHasWallet && !this.state.loading &&
                            <Row justify="space-between">
                                <Col span={7} xs={24} sm={24} md={7} xl={7}>
                                    <CardComponent 
                                        name = "Available Balance"
                                        content= {"N"+this.state.balance.toLocaleString()}
                                        icon = "WalletOutlined"
                                        gradient = {this.state.balance > 1000 ? "bg-gradient-success" : "bg-gradient-danger"}
                                    />
                                </Col>
                                <Col span={3}></Col>
                                {/*<Col span={14} xs={24} sm={24} md={14} xl={14} style={{ paddingTop: 30 }}>*/}
                                {/*    <div>*/}
                                {/*        <h4>Fund Your Wallet</h4>*/}
                                {/*        <h5>Bank: {this.state.bankName}</h5>*/}
                                {/*        <Paragraph className="account-number" copyable>{this.state.accountNumber}</Paragraph>*/}
                                {/*    </div>*/}
                                {/*    <div style={{paddingRight: 50}}>*/}
                                {/*        <h6>Kindly fund your wallet by transferring into the Access Bank account above,*/}
                                {/*            and your wallet will be automatically funded.*/}
                                {/*        </h6>*/}
                                {/*    </div>*/}
                                {/*</Col>*/}
                            </Row>}
                        </Col>
                        
                        {isMerchantHasWallet && data.length > 0 && !this.state.loading &&
                        <>
                            <Col xs={0} sm={0} md={24} span={24}> 
                                <div>
                                    <Button type="primary" shape="round" icon = {<CreditCardOutlined />} onClick={this.isShowWithdraw}
                                    size={"medium"} style={{float: "right", background: "#65D6C9", border: "none", marginTop: -50, marginRight: 120}}>
                                        Withdraw
                                    </Button>
                                </div>
                                <div>
                                    <Button type="primary" shape="round" icon = {<SwapOutlined />} onClick={this.isShowTransfer}
                                    size={"medium"} style={{float: "right", background: "#65D6C9", border: "none", marginTop: -50}}>
                                        Transfer
                                    </Button>
                                </div>
                                {/*TODO - implement export to csv for web*/}
                                {/* <Button type="primary" shape="round" icon={<DownloadOutlined />} size={"medium"} style={{float: "right", background: "#65D6C9", border: "none", marginTop: -50}}>
                                    Export CSV
                                </Button> */}
                                <TransactionTable 
                                    data = {data}
                                    loading = {loading}
                                />
                            </Col>
                            
                            <Col md={0} lg={0} span={24}>
                                <div>
                                    <Button type="primary" shape="round" icon = {<CreditCardOutlined />} onClick={this.isShowWithdraw}
                                    size={"medium"} style={{float: "left", background: "#65D6C9", border: "none"}}>
                                        Withdraw
                                    </Button>
                                    
                                    <Button type="primary" shape="round" icon = {<SwapOutlined />} onClick={this.isShowTransfer}
                                    size={"medium"} style={{float: "right", background: "#65D6C9", border: "none"}}>
                                        Transfer
                                    </Button>
                                </div>
                               
                                <br/><br/>
                                {/*TODO - implement export to csv for mobile*/}
                                {/* <Button type="primary" shape="round" icon={<DownloadOutlined />} size={"small"} style={{float: "right", background: "#65D6C9", border: "none", margin: 10}}>
                                    CSV
                                </Button> */}
                                <TransactionTable 
                                    data = {data}
                                    loading = {loading}
                                />
                            </Col>
                        </>}

                        {isMerchantHasWallet && data.length === 0 && !this.state.loading &&
                        <Col span={24}>
                            <div className="" style={{background: "#fff", padding:150, paddingTop: 100, height: "500px",  boxShadow: "0 1px 9px -3px rgba(0, 0, 0, 0.2)"}}>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </div>
                        </Col>}
                        
                        {isMerchantHasWallet === false && !this.state.loading &&
                        <Col span={24}>
                            <div className="" style={{background: "#fff", marginTop: 50, paddingTop: 100, height: "500px", boxShadow: "0 1px 9px -3px rgba(0, 0, 0, 0.2)"}}>
                                <Empty
                                    image={empty}
                                    imageStyle={{
                                        height: 100,
                                    }}
                                    description={
                                        <span>
                                            You do not have any active wallet. Click below to create one now
                                        </span>
                                    }
                                >
                                    <Button type="primary" shape="round" size={"large"}  onClick={this.showModal}>Create Wallet</Button>
                                    <Modal visible={isWalletModalVisible}
                                        width={500}
                                        title="Create Wallet"
                                        onOk={this.handleOk}
                                        onCancel={this.handleCancel}
                                        maskClosable={true}
                                        footer={[
                                            <Button key="back" onClick={this.handleCancel}>
                                            Cancel
                                            </Button>,
                                            <Button key="submit" type="primary" loading={setConfirmLoading} disabled={accountVerificationLoading} onClick={this.handleOk}>
                                            Submit
                                            </Button>,
                                        ]}
                                    >
                                        <Form>
                                            <Form.Item>
                                                <label>Business Name:</label>
                                                <Input name="businessName" placeholder="Business Name"
                                                    value={formData.businessName} onChange={this.handleInputChange} />
                                            </Form.Item>
                                            <Form.Item>
                                                <label>Wallet Name:</label>
                                                <Input name="walletName" placeholder="Wallet Name"
                                                    value={formData.walletName} onChange={this.handleInputChange} />
                                            </Form.Item>

                                            <Form.Item>
                                                <label>Currency:</label>
                                                <Select
                                                    style={{ width: '100%' }}
                                                    onChange={this.handleCurrencyChange}
                                                    placeholder="Select Currency"
                                                    value={currency ? currencyOption.key : ''}>
                                                    {currencyOption}
                                                </Select>
                                            </Form.Item>

                                            <Form.Item>
                                                <label>Address:</label>
                                                <Input name="address" placeholder="Address"
                                                    value={formData.address} onChange={this.handleInputChange} />
                                            </Form.Item>
                                            <Form.Item>
                                                <label>Bank:</label>
                                                <Select onChange={this.handleSelectChange} placeholder="Select Bank" value={bankList ? bankListOption.key : ''}>
                                                    {bankListOption}
                                                </Select>
                                            </Form.Item>

                                            {formData.bankName !== undefined && formData.bankName.length > 0 &&
                                            <Form.Item>
                                                {formData.accountNumber !== undefined && formData.accountNumber.length !== 10 &&
                                                <span style={{color: "red", fontSize: 10, float: "right"}}>10 digits required</span>}

                                                {accountVerificationLoading && <Spin style={{float: "right"}} />}

                                                {nameEnquiryResponse !== undefined  && <p style={{float: "right"}}><Tag color="#1890FF">{nameEnquiryResponse}</Tag></p>}
                                                <label>Account Number:</label>
                                                <Input name="accountNumber" placeholder="Account Number"
                                                    value={formData.accountNumber} onChange={this.handleValidateAccountInputChange} />
                                            </Form.Item>}

                                            <Form.Item>
                                                <label>BVN:</label>
                                                <Input name="bvn" placeholder="Bvn"
                                                    value={formData.bvn} onChange={this.handleInputChange} />
                                            </Form.Item>
                                        </Form>
                                    </Modal>
                                </Empty>
                            </div>
                        </Col>}
                    </Row>
                    {showWalletWidget &&
                        <>
                            <WalletWidget action={walletWidgetAction} isShowDrawer={this.showDrawer} isHideDrawer={this.hideDrawer} isVisible={this.state.visible}/>
                        </>
                    }
                </Content>
            </Wrapper>
        );
    }
}

export default WalletComponent