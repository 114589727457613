import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Input, Form } from 'antd'
import Notification from '../../shared/components/notification';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import AuthService from './services/auth.service'
import UtilService from '../../services/util.service'
import Loading from '../../shared/components/loading/index'
import logo from '../../assets/Paydoor.png'
import './index.css'

const FormItem = Form.Item

class LoginForm extends Component {


    componentDidMount(){
        this.isRememberMe();
    }

    state = {
        formData: {
          email: '',
          password: '',
          rememberMe: false
        },
        showPassword: false,
        notification: {},
        loading: false
    }
    
    isRememberMe(){
        if(UtilService.hasRememberMeToken()){
            this.setState({
                formData: {
                    email: UtilService.getRememberMeToken(),
                    rememberMe: UtilService.hasRememberMeToken()
                }
            })
        }
    }

    handleInputChange = (event) => {
        this.setState({
          formData: {
            ...this.state.formData,
            [event.currentTarget.name]: event.currentTarget.value
          }
        });
    }

    handleRememberMeInputChange = (event) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [event.target.name]: event.target.checked
            }
        });
    }

    submit = () => {
        if (this.validForm()) {
            this.setState({
                loading: true
            });
            const { formData } = this.state;
        
            AuthService.loginMerchant(formData)
            .then(response => {
                if(response.access_token && response.email && response.role[0].authority){
                    UtilService.setSession(response.access_token, response.email, response.role[0].authority)
                    this.handleRememberMeToken(formData);
                    window.location.href = "/dashboard"
                }else{
                    this.setState({
                        loading: false
                    });
                    this.notify("error", response.error, response.message)
                }
            })
        }
    }

    handleRememberMeToken = (data) =>{
        if(data.rememberMe){
            UtilService.setRememberMeToken(data.email)
        }else{
            UtilService.removeRememberMeToken()
        }
    }

    validForm = () => {
        const {formData} = this.state;
        const emailValidation = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(formData.email === undefined || formData.email === "") {
            this.notify("error", "Unable to process request", "Email is required");
            return false;
        }else {
            if(!emailValidation.test(formData.email)){
                this.notify("error", "Unable to process request", "Invalid Email Supplied");
                return false;
            }
        }
        if(formData.password === undefined || formData.password === "") {
            this.notify("error", "Unable to process request", "Password is required")
            return false;
        }

        return true;
    };

    notify = (type, title, message) => {
        let notification = {
          type: type,
          title: title,
          message: message,
        };
        this.setState({ notification });
    };

    clearNotificationType = () => {
        this.setState({notification: {}})
    };

    showPassword = () => {
        this.setState({
            showPassword: this.state.showPassword ? false : true
        })
    };

    render() {
        return(
            <div className="wrapper fadeInDown">
                <Notification notification={this.state.notification} clearNotificationType = {this.clearNotificationType} />

                {this.state.loading &&
                <div id="formContent" style={{zIndex: '9', background: 'rgba(255, 255, 255, 0.77)'}}>
                    <Loading />
                </div>}
                <div id="formContent">
                    {/* Icon */}
                    <div className="fadeIn first" style={{marginTop: 25}}>
                        <img src={logo} id="icon" alt="User Icon" style={{height: 30, width: 120}}/>
                    </div>
                    
                    {/* <Tabs Titles */}
                    <h2 className="active h2"> Sign In </h2>
                    <Link to="/register"><h2 className="inactive underlineHover h2">Sign Up</h2></Link>

                    <Form className="form">
                        <FormItem
                        rules={[{ required: true }]} hasFeedback>
                            <Input
                                name="email"
                                onChange={this.handleInputChange}
                                type="email"
                                placeholder="Email"
                                className="fadeIn second"
                                value = {this.state.formData.email}
                            />
                        </FormItem>
                        <FormItem
                        rules={[{ required: true }]} hasFeedback>
                            <Input
                                name="password"
                                onChange={this.handleInputChange}
                                type={this.state.showPassword ? "text" : "password"}
                                placeholder="Password"
                                className="fadeIn third"
                                value = {this.state.formData.password}
                                autoComplete="off"
                            />
                            <div className="fadeIn third">
                                {this.state.showPassword ? <EyeOutlined className="showPassword" onClick={this.showPassword} /> :
                                <EyeInvisibleOutlined className="showPassword" onClick={this.showPassword}/>}
                            </div>
                        </FormItem>
                        <FormItem style={{textAlign: "left", marginLeft: 30}}>
                            <Input
                                name="rememberMe"
                                type="checkbox"
                                className="fadeIn fourth"
                                checked={this.state.formData.rememberMe}
                                onChange={this.handleRememberMeInputChange}
                            />
                            <label className="fadeIn fourth">&nbsp;&nbsp;Remember me</label>
                        </FormItem>
                        <input type="submit" className="fadeIn fourth" value="Sign in" onClick={this.submit}/>
                    </Form>

                    {/* <Remind Passowrd */}
                    <div id="formFooter" style={{fontSize: 12}}>
                        <Link to="/forgot-password"><a className="underlineHover forgot-password" href="#">Forgot Password?</a></Link>
                    </div>

                </div>
            </div>
        );
    }
}

export default LoginForm