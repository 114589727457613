import request from '../../../shared/services/request.service'
import UrlService from './url.service'
import { Component } from 'react';

 class SettingsService extends Component{

    static changePassword(data){
        let options = Object();

        options.url = UrlService.changePassword;    
        options.repo = "USER-MANAGEMENT";
        options.authenticated = true;
        options.data = data;

        return request.post(options);
    }

    static createStoreFront(data){
        let options = Object();

        options.url = UrlService.createStoreFront;    
        options.repo = "ESCROW_STORE_FRONT";
        options.authenticated = true;
        options.data = data;

        return request.post(options);
    }

    static updateStoreFront(data){
        let options = Object();

        options.url = UrlService.updateStoreFront;    
        options.repo = "ESCROW_STORE_FRONT";
        options.authenticated = true;
        options.data = data;

        return request.post(options);
    }

    static fetchStoreFrontByWalletID(id){
        let options = Object();

        options.url = UrlService.fetchStoreFront+"?walletId="+id;
        options.repo = "ESCROW_STORE_FRONT";
        options.authenticated = true;
      
        return request.get(options);
    }
}
export default  SettingsService