import React, { Component } from 'react';
import {Breadcrumb, Layout, Menu, Row, Col, Input, Select, Button, Tag, Spin, Modal, Divider, Form, Card} from 'antd';
import Notification from '../../shared/components/notification';
import host from '../../shared/services/host.service'
import logo from '../../assets/Paydoor.png'
import "../storeFront/index.css"
import FormItem from 'antd/lib/form/FormItem';
import OrderService from '../order/services/order.service'
import ProductService from '../product/services/product.service'
import { FlutterWaveButton, closePaymentModal } from 'flutterwave-react-v3';


const { Header, Content, Footer } = Layout;
const { Option } = Select;

class CheckoutComponent extends Component {

    componentDidMount(){
        this.validateRequest();
        this.getBanks();
    }

    state = {
        loading: false,
        notification: {},
        formData: {
            name: undefined,
            phone: undefined,
            email: undefined,
            address: undefined,
            amount: undefined,
            paymentReference: undefined,
            transactionReference: undefined,
            transactionStatus: undefined,
            message: undefined,
            accountNumber: undefined,
            bankCode: undefined,
            nameEnquiryResponse: undefined,
        },
        bankList: [],
        previewCustomerDeliveryDetailsModal: false
    }

    validateRequest = () =>{
        if(this.props.location.amount === undefined){
            window.location.href = `${host.host}/user/cart`
        }
    }
    getBanks = () => {
        OrderService.getAllBanks()
        .then(response => {
            this.setState({
                bankList: response
            })
        })
    };

    handleSelectChange = (e) => {
        this.setState({
            nameEnquiryResponse: undefined,
            formData: {
                ...this.state.formData,
                bankCode: e,
                accountNumber: undefined,
            }
        })
    };

    handleInputChange = (e) => {
        const { name, value} = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        })
    };

    handleValidateAccountInputChange = (e) => {
        const { name, value} = e.target;
        const regexp = /^[0-9/b]+$/;

        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        }, () =>{
            if(this.state.formData.accountNumber.length === 10){
                if(!regexp.test(value)){
                    this.notify("error", "Invalid input supplied", "Account number should be only digits")
                }else{
                    this.setState({
                        loading: true,
                        nameEnquiryResponse: undefined
                    });
                    const accountNumber = this.state.formData.accountNumber;
                    const bankCode = this.state.formData.bankCode;

                    ProductService.doNameEnquiry(accountNumber, bankCode)
                    .then(response =>{
                        if(response.status === "SUCCESS" && response.message !== undefined && response.message.length > 0){
                            this.setState({
                                    loading: false,
                                    nameEnquiryResponse: response.message === "null" ? "Invalid account details" : response.message,
                                });
                        }else {
                            this.setState({
                                loading: false,
                            });
                            this.notify("error", "Unable to resolve account", response.message);
                        }
                    })
                }
            }else {
                this.setState({
                    nameEnquiryResponse: undefined,
                });
            }
        });
    };
   
    handleSubmit = () => {

        if(this.validateForm()) {
            this.setState({
                previewCustomerDeliveryDetailsModal: true
            });
        }
    };

    handleFlutterTransactionSuccess = (transactionResponse) => {

        if(transactionResponse.status === "successful" || transactionResponse.status === "completed"){
            window.location.href = `${host.host}/order/success/${transactionResponse.tx_ref}`
        }else{
            this.notify("error", "An error occurred", transactionResponse.message)
            this.setState({
                loading: false
            })
        }
    };

    validateForm = () => {
        const { formData, nameEnquiryResponse } = this.state;
        const emailValidation = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(formData.name === undefined || formData.name === "") {
            this.notify("error", "Unable to process request", "Full Name is required");
            return false;
        }
        if(formData.phone && formData.phone.length > 0 && (isNaN(formData.phone) || formData.phone.length !== 11 )) {
            this.notify("error", "Unable to process request", "Phone number should be 11 digits")
            return false;
        }
        if(formData.email === undefined || formData.email === "") {
            this.notify("error", "Unable to process request", "Email is required");
            return false;
        }else {
            if(!emailValidation.test(formData.email)){
                this.notify("error", "Unable to process request", "Invalid Email Supplied");
                return false;
            }
        }
        if(formData.address === undefined || formData.address === "") {
            this.notify("error", "Unable to process request", "Enter Delivery Address");
            return false;
        }
        if(formData.bankCode === undefined || formData.bankCode === "") {
            this.notify("error", "Unable to process request", "Kindly Select Bank");
            return false;
        }
        if(formData.accountNumber === undefined || formData.accountNumber === "") {
            this.notify("error", "Unable to process request", "Account Number is required");
            return false;
        }else if(isNaN(formData.accountNumber) || formData.accountNumber.length < 10 || formData.accountNumber.length > 10){
            this.notify("error", "Unable to process request", "Invalid Account Number Supplied");
            return false;
        }
        if(nameEnquiryResponse === undefined || nameEnquiryResponse === "" || nameEnquiryResponse === "Invalid account details") {
            this.notify("error", "Unable to process request", "Account number not resolved");
            return false;
        }
        return true;
    };


    notify = (type, title, message) => {
        let notification = {
          type: type,
          title: title,
          message: message,
        };
        this.setState({ notification });
    };

    clearNotificationType = () => {
        this.setState({notification: {}})
    };

  render(){
        const {loading, formData, nameEnquiryResponse, previewCustomerDeliveryDetailsModal} = this.state

        const amount  = this.props.location.amount

        let transactionReference = Date.now();

        const config = {
            public_key: process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
            tx_ref: transactionReference,
            amount: this.props.location.amount,
            currency: 'NGN',
            payment_options: 'card,mobilemoney,ussd',
            customer: {
              email: formData.email,
              phonenumber: formData.phone,
              name: formData.name,
            },
            meta: {
                name: formData.name,
                phone: formData.phone,
                email: formData.email,
                address: formData.address,
                accountNumber: formData.accountNumber,
                bankCode: formData.bankCode,
                cart: JSON.stringify(this.props.location.cart)
            },
            customizations: {
              title: 'Paydoor',
              description: 'Payment for '+{transactionReference},
            },
            text: 'Place Order',
            callback: (response) => {
                this.handleFlutterTransactionSuccess(response);
                localStorage.removeItem("cart")
                closePaymentModal() // this will close the modal programmatically
            },
            onClose: () => {},
        };

    return (
        <Layout>
          <Notification notification={this.state.notification} clearNotificationType = {this.clearNotificationType} />
          <Header style={{ position: 'sticky', top: 0, zIndex: 1, width: '100%' }}>
            <div>
                <div className="logo" style={{marginTop: -10, marginLeft: -10, textAlign: "left"}}>
                    <img src={ logo } style={{width: 100, height: 25}} alt =""/>
                </div>
            </div>
            <Menu
              theme="dark"
            />
          </Header>
          <Content className="site-layout">
            <Row style={{marginTop: 50}}>
                <Col xs={0} sm={0} md={24} style={{padding: "0px 30px"}}>

                    <Card
                        title={"Checkout"}
                        bordered={false}
                        style={{
                        width: 600,
                        margin: "auto",
                        borderRadius: 10,
                        minHeight: 600
                        }}
                    >
                        <div>
                            <Row>
                                <Col span={12}>
                                    <h4>Customer Delivery Details</h4>
                                </Col>
                                <Col span={12}>
                                    <span style={{float:"right",fontWeight:"bold", fontSize:18}}>
                                        {"N"+Number(amount).toLocaleString()}
                                        </span>
                                </Col>
                            </Row>
                            
                        </div>
                        <br></br>
                        <div>
                            <span style={{color: "red", fontSize: 10}}>* All fields are required (Note: This information would be used should you require a refund)</span>
                            <br/><br/>
                            <Row gutter={24}>
                                <Col span={12} >
                                    <FormItem>
                                        <Input type="text" name="name" placeholder="Enter full name" style={{width: "100%"}} onChange={this.handleInputChange} required/>
                                    </FormItem>
                                </Col>
                                <Col span={12}>
                                    <FormItem>
                                        <Input type="text" name="phone" maxLength="11" placeholder="Enter your 11 digits phone number" style={{width: "100%"}} onChange={this.handleInputChange}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem>
                                        <Input type="email" name="email" placeholder="Enter your valid email" style={{width: "100%"}} onChange={this.handleInputChange} required/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem>
                                        <Input type="text" name="address" placeholder="Enter delivery address" style={{width: "100%"}} onChange={this.handleInputChange} required/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem style={{textAlign: "center"}}>
                                        <Select onChange={this.handleSelectChange} placeholder="Select Bank">
                                            {this.state.bankList.map((option) =>
                                            <Option key={option.name} value={option.code}>
                                                {option.name}
                                            </Option>
                                            )}
                                        </Select> 
                                    </FormItem>
                                </Col>
                                {formData.bankCode !== undefined && formData.bankCode.length > 0 &&
                                    <Col span={24}>
                                        {formData.accountNumber !== undefined && formData.accountNumber.length !== 10 &&
                                        <span style={{color: "red", fontSize: 10}}>10 digits required</span>}

                                        {loading && <Spin />}

                                        {nameEnquiryResponse !== undefined  && <p><Tag color="#1890FF">{nameEnquiryResponse}</Tag></p>}
                                        <FormItem>
                                            <Input type="text" name="accountNumber" value={formData.accountNumber} placeholder="Enter valid account number" style={{width: "100%"}}
                                                onChange={this.handleValidateAccountInputChange} required
                                            />
                                        </FormItem>
                                    </Col>
                                }
                            </Row>
                        
                            <FormItem>
                                <Button type="primary" shape="round" size={"large"} onClick={this.handleSubmit}
                                style={{float: "right", background: "#65D6C9", border: "none"}} loading={loading}>
                                    Place Order
                                </Button>
                            </FormItem>
                       </div>
                    </Card>

                </Col> 
            
                {/* ===========>>>>RESPONSIVE MOBILE PRODUCT VIEW<<<<================  */}
                <Col md={0} lg={0} style={{width: "100%"}}>
                    <Card
                        title={"Checkout"}
                        bordered={false}
                        style={{
                        width: 300,
                        margin: "auto",
                        borderRadius: 10,
                        minHeight: 600
                        }}
                    >
                        <div>
                            <Row>
                                <Col span={12}>
                                    <h5>Customer Delivery Details</h5>
                                </Col>
                                <Col span={12}>
                                    <span style={{float:"right",fontWeight:"bold", fontSize:15}}>
                                        {"N"+Number(amount).toLocaleString()}
                                        </span>
                                </Col>
                            </Row>
                            
                        </div>
                        <br></br>
                        <div>
                            <span style={{color: "red", fontSize: 10}}>* All fields are required (Note: This information would be used should you require a refund)</span>
                            <br/><br/>
                            <Row gutter={24}>
                                <Col xs={24} sm={24} md={12} >
                                    <FormItem>
                                        <Input type="text" name="name" placeholder="Enter full name" style={{width: "100%"}} onChange={this.handleInputChange} required/>
                                    </FormItem>
                                </Col>
                                <Col xs={24} sm={24} md={12}>
                                    <FormItem>
                                        <Input type="text" name="phone" maxLength="11" placeholder="Enter your 11 digits phone number" style={{width: "100%"}} onChange={this.handleInputChange}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem>
                                        <Input type="email" name="email" placeholder="Enter your valid email" style={{width: "100%"}} onChange={this.handleInputChange} required/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem>
                                        <Input type="text" name="address" placeholder="Enter delivery address" style={{width: "100%"}} onChange={this.handleInputChange} required/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem style={{textAlign: "center"}}>
                                        <Select onChange={this.handleSelectChange} placeholder="Select Bank">
                                            {this.state.bankList.map((option) =>
                                            <Option key={option.name} value={option.code}>
                                                {option.name}
                                            </Option>
                                            )}
                                        </Select> 
                                    </FormItem>
                                </Col>
                                {formData.bankCode !== undefined && formData.bankCode.length > 0 &&
                                    <Col span={24}>
                                        {formData.accountNumber !== undefined && formData.accountNumber.length !== 10 &&
                                        <span style={{color: "red", fontSize: 10}}>10 digits required</span>}

                                        {loading && <Spin />}

                                        {nameEnquiryResponse !== undefined  && <p><Tag color="#1890FF">{nameEnquiryResponse}</Tag></p>}
                                        <FormItem>
                                            <Input type="text" name="accountNumber" value={formData.accountNumber} placeholder="Enter valid account number" style={{width: "100%"}}
                                                onChange={this.handleValidateAccountInputChange} required
                                            />
                                        </FormItem>
                                    </Col>
                                }
                            </Row>
                        
                            <FormItem>
                                <Button type="primary" shape="round" size={"large"} onClick={this.handleSubmit}
                                style={{float: "right", background: "#65D6C9", border: "none"}} loading={loading}>
                                    Place Order
                                </Button>
                            </FormItem>
                       </div>
                    </Card>
                </Col>
            </Row>

            <Modal visible={previewCustomerDeliveryDetailsModal}
                    width={500}
                    title="Preview delivery details"
                    onCancel={() => this.setState({previewCustomerDeliveryDetailsModal: !previewCustomerDeliveryDetailsModal, loading: false})}
                    maskClosable={true}
                    footer={[
                        <Button key="cancel" loading={loading} onClick={() => this.setState({previewCustomerDeliveryDetailsModal: !previewCustomerDeliveryDetailsModal, loading: false})}>
                            Cancel
                        </Button>,
                        <>&nbsp;&nbsp;</>,
                        <FlutterWaveButton {...config} />
                        // <PaystackButton {...config} className="paystack-button"/>,
                    ]}
                >
                    <Form>
                        <FormItem>
                            <Row style={{marginBottom: -35, fontSize: 12}}>
                                <Col span={7}>
                                    <span style={{color: "#72859C"}}>Full Name: </span>
                                </Col>
                                <Col span={17} >
                                    <span>{formData.name}</span>
                                    <Divider />
                                </Col>
                            </Row>
                        </FormItem>

                        <FormItem>
                            <Row style={{marginBottom: -35, fontSize: 12}}>
                                <Col span={7}>
                                    <span style={{color: "#72859C"}}>Phone: </span>
                                </Col>
                                <Col span={17} >
                                    <span>{formData.phone ? formData.phone : "NIL"}</span>
                                    <Divider />
                                </Col>
                            </Row>
                        </FormItem>

                        <FormItem>
                            <Row style={{marginBottom: -35, fontSize: 12}}>
                                <Col span={7}>
                                    <span style={{color: "#72859C"}}>Email: </span>
                                </Col>
                                <Col span={17} >
                                    <span>{formData.email}</span>
                                    <Divider />
                                </Col>
                            </Row>
                        </FormItem>

                        <FormItem>
                            <Row style={{marginBottom: -35, fontSize: 12}}>
                                <Col span={7}>
                                    <span style={{color: "#72859C"}}>Delivery Address: </span>
                                </Col>
                                <Col span={17} >
                                    <span>{formData.address}</span>
                                    <Divider />
                                </Col>
                            </Row>
                        </FormItem>

                        <FormItem>
                            <Row style={{marginBottom: -35, fontSize: 12}}>
                                <Col span={7}>
                                    <span style={{color: "#72859C"}}>Account Name: </span>
                                </Col>
                                <Col span={17} >
                                    <span>{nameEnquiryResponse}</span>
                                    <Divider />
                                </Col>
                            </Row>
                        </FormItem>

                        <FormItem>
                            <Row style={{marginBottom: -35, fontSize: 12}}>
                                <Col span={7}>
                                    <span style={{color: "#72859C"}}>Account Number: </span>
                                </Col>
                                <Col span={17} >
                                    <span>{formData.accountNumber}</span>
                                    <Divider />
                                </Col>
                            </Row>
                        </FormItem>
                    </Form>
                </Modal>

          </Content>
          <Footer style={{ textAlign: 'center'}}>Paydoor ©2021 Created by Blaise Leo</Footer>
        </Layout>
      );
  }
};

export default CheckoutComponent;