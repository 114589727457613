import React, { Component } from 'react';
import { Layout, Breadcrumb, Row, Col, Empty } from 'antd';
import { Wrapper } from '../index'
import Notification from '../../shared/components/notification';

import {DashboardOutlined, ShoppingOutlined} from '@ant-design/icons';
import ProductTable from './productTable/index'
import CreateProductModal from './createProduct';
import ProductService from './services/product.service'
import WalletService from '../wallet/services/wallet.service'
import UtilService from '../../services/util.service'
import Loading from '../../shared/components/loading/index'

import './index.css'
import empty from "../../assets/empty.svg";

const { Content } = Layout;

class ProductComponent extends Component {

    componentDidMount(){
        this.getAllProducts()
    }

    componentDidUpdate(){
        UtilService.setComponentActive("products");
    }

    getAllProducts = () =>{
        let userId = UtilService.getUserId();
        WalletService.getWalletsByUserId(userId)
        .then(response => {
            if(response.id){
                this.setState({
                    isMerchantHasWallet: true
                });
                ProductService.getAllProducts(response.id)
                .then(response => {
                    if(response && response.length > 0){
                        this.setState({
                            data: response,
                            loading: false
                        })
                    }else {
                        this.setState({
                            loading: false
                        });
                    }
                })
            }else {
                this.setState({
                    loading: false
                });
            }
        });
    };

    state={
        isModalVisible: false,
        setConfirmLoading: false,
        notification: {},
        data: [],
        loading: true,
        isMerchantHasWallet: false
    };

    notify = (type, title, message) => {
        let notification = {
          type: type,
          title: title,
          message: message,
        };
        this.setState({ notification });
    };

    clearNotificationType = () => {
        this.setState({notification: {}})
    };

    render() {
        return (
            <Wrapper>
                <Content className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <Notification notification={this.state.notification} clearNotificationType = {this.clearNotificationType} />
                    <Breadcrumb style={{ margin: '16px 0' }}>
                        <Breadcrumb.Item><DashboardOutlined /> &nbsp;Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item><ShoppingOutlined />&nbsp; Product</Breadcrumb.Item>
                    </Breadcrumb>

                    {this.state.loading && <Loading background={"#fff"}/>}

                    {!this.state.loading &&
                    <Row type="flex" justify="space-between">

                        {this.state.isMerchantHasWallet &&
                        <Col span={24}>
                            <CreateProductModal />
                        </Col>}
                        {this.state.data.length > 0 &&
                        <Col span={24}>
                            <ProductTable
                                data = {this.state.data}
                                loading = {this.state.loading}
                            />
                        </Col>}
                        {!this.state.isMerchantHasWallet &&
                        <Col span={24}>
                            <div className="" style={{background: "#fff", marginTop: 50, paddingTop: 100, height: "500px", boxShadow: "0 1px 9px -3px rgba(0, 0, 0, 0.2)"}}>
                                <Empty
                                    image={empty}
                                    imageStyle={{
                                        height: 100,
                                    }}
                                    description={
                                        <span>
                                            Oops! Create a wallet to proceed.
                                        </span>
                                    }
                                />

                            </div>
                        </Col>}
                        {this.state.isMerchantHasWallet && this.state.data.length === 0 &&
                        <Col span={24}>
                            <div className="" style={{background: "#fff", marginTop: 80, paddingTop: 100, height: "500px", boxShadow: "0 1px 9px -3px rgba(0, 0, 0, 0.2)"}}>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </div>
                        </Col>}
                    </Row>}
                </Content>
            </Wrapper>
        );
    }
}

export default ProductComponent