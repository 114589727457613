const urls = {
  getMerchantByUserId: '/email',
  getWalletsByUserId: '/user',
  resendConfirmationToken: '/resend-token',
  getCardDetails: '/card-details',
  getRecentOrders: '/get-recent-orders',
  getChartDetails: '/chart-details',
};

export default urls;
   