import React, { Component }  from 'react';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { Table, Input, Button, Space, Tag, Row, Col } from 'antd';
import ResponsiveView from './responsive-view';

import "./index.css"

class Transactions extends Component{
  state = {
    searchText: '',
    searchedColumn: '',
    data: this.props.data
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'red' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    let tableData = []
    let index = 0;
    const columns = [
      {
        title: 'S/N',
        dataIndex: 'sn',
      },
      {
        title: 'Source',
        dataIndex: 'sourceAccount',
        ...this.getColumnSearchProps('sourceAccount'),
      },
      {
        title: 'Destination',
        dataIndex: 'destinationAccount',
        ...this.getColumnSearchProps('destinationAccount'),
      },
      {
        title: 'Description',
        dataIndex: 'description',
        ...this.getColumnSearchProps('description'),
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        width: "10%",
        ...this.getColumnSearchProps('amount'),
        render: amount => amount
      },
      {
        title: 'Fee',
        dataIndex: 'fee',
        ...this.getColumnSearchProps('fee'),
        render: fee => "N"+fee
      },
      {
        title: 'Type',
        dataIndex: 'type',
        sorter: {
          compare: (a, b) => a.type - b.type,
          multiple: 3,
        }
      },
      {
        title: 'Transaction ID',
        dataIndex: 'globalId',
        ...this.getColumnSearchProps('globalId'),
      },
      {
        title: 'Date',
        dataIndex: 'createdAt',
        width: "10%",
        ...this.getColumnSearchProps('createdAt'),
      }
    ];

    tableData = this.state.data && this.state.data.length > 0 && this.state.data.map(item => {
      return{
          sn: index = index + 1,
          sourceAccount: item.sourceAccount,
          destinationAccount: item.destinationAccount,
          description: item.description,
          amount: item.type.id === "C" ? "N"+item.amount.toLocaleString() : "-N"+item.amount.toLocaleString(),
          fee: undefined === item.fee || 0 === item.fee.toLocaleString() ? "0.00" : item.fee.toLocaleString(),
          type: item.type.id === "C" ? 
                          <Tag color="#65D6C9" className="tranType">{"Credit"}</Tag> :
                          <Tag color="#FE9C96" className="tranType">{"Debit"}</Tag>,
          globalId: item.globalId,
          createdAt: item.createdAt
      }
  });
  
  
    return(
      <div>
          <div style={{padding:30, background: "#fff", boxShadow: "0 1px 9px -3px rgba(0, 0, 0, 0.2)"}}>
            <Row>
              <Col xs={0} sm={0} md={24}><h4>All transactions</h4></Col>
              <Col md={0} lg={0} style={{margin: -10, fontSize: "12px"}}><h4>All transactions</h4></Col>
            </Row>
          </div>

          <Row>
              <Col xs={0} sm={0} md={24} style={{padding: 30, background: "#fff"}}>
                  <div>
                      <Table columns={columns} dataSource={tableData} size="middle" loading={this.props.loading} scroll={{ x: 1000 }}/>
                  </div>
              </Col>

              <Col md={0} lg={0} style={{width: "100%"}}>
                  {this.state.data.map((item) => {
                      return(<>
                          <ResponsiveView data={item} />
                          <br />
                      </>
                      )
                  })}
              </Col>
          </Row>
      </div>
    )
  }
}

export default Transactions