import React, { Component }  from 'react';
import { Result } from 'antd';

class SuccessPage extends Component{

    render(){
        return(
            <Result
                status="success"
                title="You Successfully Placed an Order"
                subTitle={`Transaction reference: ${this.props.match.params.reference}. Kindly check your email for more details.`}
                style={{paddingTop: "25vh"}}
            />
        )
    }
}

export default SuccessPage
