import request from '../../../shared/services/request.service'
import UrlService from './url.service'
import { Component } from 'react';

class DisputeService extends Component {
    static getDisputeByWalletId(walletId) {
        let options = Object();

        options.url = UrlService.getDisputesByWalletId + "/" + walletId;
        options.repo = "ESCROW_DISPUTE";
        options.authenticated = true;

        return request.get(options);
    }

    static sendResolutionCodeToCustomer(reference) {
        let options = Object();

        options.url = UrlService.sendResolutionCodeToCustomer + "/" + reference;
        options.repo = "ESCROW_DISPUTE";
        options.authenticated = true;

        return request.post(options);
    }

    static resolveDispute(reference, data) {
        let options = Object();

        options.url = UrlService.resolveDispute + "/" + reference;
        options.repo = "ESCROW_DISPUTE";
        options.authenticated = true;
        options.data = data;

        return request.post(options);
    }

    static refundCustomer(reference, data) {
        let options = Object();

        options.url = UrlService.refundCustomer + "/" + reference;
        options.repo = "ESCROW_DISPUTE";
        options.authenticated = true;
        options.data = data;

        return request.post(options);
    }

    static escalateDispute(reference, data) {
        let options = Object();

        options.url = UrlService.escalateDispute + "/" + reference;
        options.repo = "ESCROW_DISPUTE";
        options.authenticated = true;
        options.data = data;

        return request.post(options);
    }
    static getOpenDisputeCountByWalletId(walletId) {
        let options = Object();

        options.url = UrlService.getOpenDisputeCountByWalletId + "/" + walletId;
        options.repo = "ESCROW_DISPUTE";
        options.authenticated = true;

        return request.get(options);
    }
}

export default DisputeService