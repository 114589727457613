import React, { Component }  from 'react';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Table, Input, Button, Space, Row, Col, Tag, Modal, Divider, Tooltip} from 'antd';
import Notification from '../../../shared/components/notification'
import Dispute from '../services/dispute.service'

import "./index.css"

const { TextArea } = Input

class DisputeTable extends Component{
  state = {
    searchText: '',
    searchedColumn: '',
    data: this.props.data,
    loading: this.props.loading,
    isShowProductModal: false,
    isShowOrderModal: false,
    rejectionReason: undefined,
    productItem: {},
    orderItem: {},
    notification: {},
    isShowDisputeResolutionModal: false,
    isShowResolutionModal: false,
    isShowEscalateResolutionModal: false,
    formData: {
        code: '',
        resolutionComment: ''
    },
    disputeItemReference: undefined,
    triggerResolveDisputeModal: false,
  };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            <Input
            ref={node => {
                this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
            <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
            >
                Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
            </Button>
            <Button
                type="link"
                size="small"
                onClick={() => {
                confirm({ closeDropdown: false });
                    this.setState({
                        searchText: selectedKeys[0],
                        searchedColumn: dataIndex,
                    });
                }}
            >
                Filter
            </Button>
            </Space>
        </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'red' : undefined }} />,
        onFilter: (value, record) =>
        record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
        if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
        }
        },
        render: text =>
        this.state.searchedColumn === dataIndex ? (
            <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    showProductModal = (item) =>{
        this.setState({
            productItem: item.productDto,
            isShowProductModal: !this.state.isShowProductModal,
        })
    }

    showOrderModal = (item) =>{
        this.setState({
            orderItem: item.customerOrderResponseDto,
            rejectionReason: item.rejectionReason,
            isShowOrderModal: !this.state.isShowOrderModal,
        })
    }

    openDisputeResolutionModal = (reference) => {
        this.sendResolutionCodeToCustomer(reference);
    }

    openResolveDisputeTrigger = (reference) => {
        this.setState({
            triggerResolveDisputeModal: true,
            disputeItemReference: reference
        })
    }

    closeResolveDisputeTrigger = () => {
        this.setState({
            triggerResolveDisputeModal: false,
            disputeItemReference: undefined
        })
    }

    openResolutionModal = (reference) => {
        this.setState({
            isShowResolutionModal: true,
            disputeItemReference: reference
        })
    }

    closeResolutionModal = () => {
        this.setState({
            isShowResolutionModal: false,
            formData: {
                code: '',
                resolutionComment: '',
            },
            disputeItemReference: undefined
        })
    }

    closeDisputeResolutionModal = () => {
        this.setState({
            isShowDisputeResolutionModal: false,
            formData: {
                code: '',
                resolutionComment: '',
            },
            disputeItemReference: undefined
        })
    }

    openEscalateResolutionModal = (reference) => {
        this.setState({
            isShowEscalateResolutionModal: true,
            disputeItemReference: reference
        })
    }

    closeEscalateResolutionModal = () => {
        this.setState({
            isShowEscalateResolutionModal: false,
            formData: {
                code: '',
                resolutionComment: '',
            },
            disputeItemReference: undefined
        })
    }

    
    sendResolutionCodeToCustomer = (reference) =>{

        const { disputeItemReference } = this.state;

        this.setState({
            loading: true
        })

        Dispute.sendResolutionCodeToCustomer(disputeItemReference)
        .then(response => {
            if(response.status === "success"){
                this.setState({
                    loading: false,
                    triggerResolveDisputeModal: false,
                    isShowDisputeResolutionModal: true,
                    disputeItemReference: reference
                })
                this.notify("success", "Successful", response.message)
            }else{
                this.setState({
                    loading: false,
                })
                this.notify("error", "Unable to complete request", response.message)
            }
        })
    }

    handleTextAreaChange = (event) => {
        this.setState({
            formData: {
                ...this.state.formData,
                resolutionComment: event.target.value
            }
        })
    };

    handleInputChange = (event) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [event.currentTarget.name]: event.currentTarget.value
            }
        })
    };

    resolveDispute = () =>{
        const { formData, disputeItemReference } = this.state;
        if(this.validForm(formData)){
            
            this.setState({
                loading: true
            })
        
            Dispute.resolveDispute(disputeItemReference, formData)
            .then(response => {
                if(response.status === "success"){
                    this.notify("success", "Success", "Dispute has been resolved successfully")
                    setTimeout(function(){ window.location.reload(); }, 1000);
                }else{
                    this.setState({
                        loading: false
                    })
                    this.notify("error", "Unable to complete request", response.message)
                }
            })
        }
    }

    validForm = (formData) => {

        if(formData.code === undefined || formData.code === "") {
            this.notify("error", "Unable to process request", "Please enter resolution code");
            return false;
        }else if(isNaN(formData.code) || formData.code.length < 8 || formData.code.length > 8){
            this.notify("error", "Unable to process request", "Invalid Resolution Code Supplied");
            return false;
        }

        return true;
    };

    validateResolutionComment = (resolutionComment) => {
        if(resolutionComment === undefined || resolutionComment === "") {
            this.notify("error", "Unable to process request", "Please enter dispute description");
            return false;
        }
        return true;
    }

    refundCustomer = () =>{
        const { formData, disputeItemReference } = this.state;

        this.setState({
            loading: true
        })
    
        Dispute.refundCustomer(disputeItemReference, formData)
        .then(response => {
            if(response.status === "success"){
                this.notify("success", "Success", "Refund request has been created successfully")
                setTimeout(function(){ window.location.reload(); }, 1000);
            }else{
                this.setState({
                    loading: false
                })
                this.notify("error", "Unable to complete request", response.message)
            }
        })
    }

    escalate = () =>{
        const { formData, disputeItemReference } = this.state;

        if(this.validateResolutionComment(formData.resolutionComment)){
            
            this.setState({
                loading: true
            })

            Dispute.escalateDispute(disputeItemReference, formData)
            .then(response => {
                if(response.status === "success"){
                    this.notify("success", "Success", "Dispute has been escalated successfully")
                    setTimeout(function(){ window.location.reload(); }, 1000);
                }else{
                    this.setState({
                        loading: false
                    })
                    this.notify("error", "Unable to complete request", response.message)
                }
            })
        }
    }

    notify = (type, title, message) => {
        let notification = {
          type: type,
          title: title,
          message: message,
        };
        this.setState({ notification });
    };

    clearNotificationType = () => {
        this.setState({notification: {}})
    }

  render() {
    const { data, loading, isShowProductModal, productItem, isShowOrderModal, orderItem, rejectionReason,
        isShowDisputeResolutionModal, formData, disputeItemReference, isShowResolutionModal,
        isShowEscalateResolutionModal, triggerResolveDisputeModal} = this.state
    let index = 0;
    let tableData = [];
    const columns = [
        {
            title: 'S/N',
            dataIndex: 'sn',
        },
        {
            title: 'code',
            dataIndex: 'disputeCode',
            ...this.getColumnSearchProps('disputeCode'),
        },
        {
            title: 'Product Name',
            dataIndex: 'producName',
            ...this.getColumnSearchProps('producName'),
        },
        {
            title: 'Rejection Reason',
            dataIndex: 'rejectionReason',
            ...this.getColumnSearchProps('rejectionReason'),
        },
        {
          title: 'Order reference',
          dataIndex: 'orderReference',
          ...this.getColumnSearchProps('orderReference'),
        },
        {
          title: 'Product Reference',
          dataIndex: 'productReference',
          ...this.getColumnSearchProps('productRef'),
        },
        {
            title: 'Resolved',
            dataIndex: 'resolved',
        },
        {
          title: 'Action',
          dataIndex: 'actions',
          width: '30%',
        },
    ];

    tableData = data && data.length > 0 && data.map(item => {
        return{
            sn: index = index + 1,
            disputeCode: item.disputeCode,
            producName: item.productDto.productName,
            rejectionReason: <div style={{cursor: "pointer"}} onClick={() => this.showOrderModal(item)}>{item.rejectionReason.substring(0, 20) + (item.rejectionReason.length > 20 ? "..." : "")}</div>,
            orderReference: <div className="reference" onClick={() => this.showOrderModal(item)}>{item.customerOrderResponseDto.orderReference}</div>,
            productReference: <div className="reference" onClick={() => this.showProductModal(item)}>{item.productDto.productRef}</div>,
            resolved: item.resolved ? <div style={{fontWeight: "bold"}}>True</div> : <div style={{fontWeight: "bold"}}>False</div>,
            actions: <Row justify="space-between">
                        {item.resolved &&
                            <Col>
                                <Tag color={"#A9A9A9"} className="deliveryStatus">Resolve</Tag>
                                <Tag color={"#A9A9A9"} className="deliveryStatus">Refund</Tag>
                                <Tag color={"#A9A9A9"} className="deliveryStatus">Escalate</Tag>
                            </Col>
                        }
                        {!item.resolved &&
                            <Col>                                
                                <Tag color={"#65D6C9"} className="deliveryStatus" onClick={() => this.openResolveDisputeTrigger(item.customerOrderResponseDto.orderReference)}><Tooltip title="Use this option to close a dispute after an agreement has been reached by the merchant and customer. No fee is paid.
">Resolve</Tooltip></Tag>
                                <Tag color={"#68BAFD"} className="deliveryStatus" onClick={() => this.openResolutionModal(item.customerOrderResponseDto.orderReference)}><Tooltip title="Use this option to refund the customer. Attracts a 20% fee of product amount, capped at 10k">Refund</Tooltip></Tag>
                                <Tag color={"#FE9C96"} className="deliveryStatus" onClick={() => this.openEscalateResolutionModal(item.customerOrderResponseDto.orderReference)}><Tooltip title="Use this option to escalate situation to Paydoor, both parties will have to present their poof for accurate resolution">Escalate</Tooltip></Tag>
                            </Col>
                        }
                        
                    </Row>
        }
    });

    return(
      <div>
          <Notification notification={this.state.notification} clearNotificationType = {this.clearNotificationType} />
          <div style={{background: "#fff", padding: 30, boxShadow: "0 1px 9px -3px rgba(0, 0, 0, 0.2)"}}>
              <Row>
                <Col xs={0} sm={0} md={24}><h4>All Disputes</h4></Col>
                <Col md={0} lg={0} style={{margin: -10, fontSize: "12px"}}><h4>All Disputes</h4></Col>
              </Row>
          </div>
          <div>
            <Row>
                <Col xs={0} sm={0} md={24} style={{padding: 30, background: "#fff"}}>
                    <div>
                        <Table columns={columns} dataSource={tableData} size="middle" loading={this.props.loading} scroll={{ x: 1000 }}/>
                    </div>
                </Col>


                {/* ===========>>>>RESPONSIVE MOBILE PRODUCT VIEW<<<<================  */}
                <Col md={0} lg={0} style={{width: "100%"}}>
                    {this.state.data.map((data) => {
                      return(<>
                          <div className="TT-responsive-card">
                            <Row className="product-responsive-view-row">
                                <Col span={10}>
                                    <div style={{fontSize: 10, color: "gray"}}>Product Name: </div>
                                    {data.productDto.productName && data.productDto.productName !== null ? data.productDto.productName : "NIL" }

                                </Col>
                                <Col span={10}>
                                    <div style={{fontSize: 10, color: "gray"}}>Order Ref: </div>
                                    <div style={{cursor: "pointer"}} onClick={() => this.showOrderModal(data)}><a href="#">{data.customerOrderResponseDto.orderReference && data.customerOrderResponseDto.orderReference !== null ? data.customerOrderResponseDto.orderReference : "NIL" }</a></div>
                                </Col>
                                <Col span={4} style={{fontSize: 9}}>
                                    <div style={{fontSize: 10, color: "gray"}}>Status: </div>
                                    {data.resolved && data.resolved !== null ?
                                     "Resolved" : "Unresolved"
                                    }
                                </Col>
                            </Row>
                            <br/>
                            <Row className="product-responsive-view-row">
                                <Col span={16} style={{textAlign: "left"}}>
                                    <div style={{fontSize: 10, color: "gray"}}>Product Ref: </div>
                                    <div style={{cursor: "pointer"}} onClick={() => this.showProductModal(data)}><a href="#">{data.productDto.productRef && data.productDto.productRef !== null ? data.productDto.productRef : "NIL" }</a></div>
                                </Col>
                                <Col span={8} style={{textAlign: "right"}}>
                                    <div style={{fontSize: 10, color: "gray"}}>code: </div>
                                    <div>{data.disputeCode && data.disputeCode !== null ? data.disputeCode : "NIL" }</div>
                                </Col>
                            </Row>
                            <br/>
                            <Row className="product-responsive-view-row">
                                <Col span={24} style={{textAlign: "left"}}>
                                    <div style={{fontSize: 10, color: "gray"}}>Rejection Reason: </div>
                                    <TextArea rows={1} disabled={true} style={{fontSize: 8, border: "0.5px solid #eee"}}
                                        placeholder ={data.rejectionReason && data.rejectionReason !== null ? data.rejectionReason : "NIL"}>
                                    </TextArea>
                                </Col>
            
                            </Row>
                            <br/>
                            <Row justify="space-between">
                                <Col span={24}>
                                    <div style={{fontSize: 10, color: "gray"}}>Action: </div>
                                    <Row justify="space-between">
                                        {data.resolved &&
                                            <Col>
                                                <Row>
                                                    <Col span={8}>
                                                        <Tag color={"#A9A9A9"} style={{width: 60, height: 20, fontSize: 10}} className="deliveryStatus">Resolve</Tag>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Tag color={"#A9A9A9"} style={{width: 60, height: 20, fontSize: 10}} className="deliveryStatus">Refund</Tag>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Tag color={"#A9A9A9"} style={{width: 60, height: 20, fontSize: 10}} className="deliveryStatus">Escalate</Tag>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        }
                                        {!data.resolved &&
                                            <Col>
                                                <Row>
                                                    <Col span={8}>
                                                        <Tag color={"#65D6C9"} style={{width: 60, height: 20, fontSize: 10}} className="deliveryStatus" onClick={() => this.openResolveDisputeTrigger(data.customerOrderResponseDto.orderReference)}><Tooltip title="Use this option to close a dispute after an agreement has been reached by the merchant and customer. No fee is paid.">Resolve</Tooltip></Tag>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Tag color={"#68BAFD"} style={{width: 60, height: 20, fontSize: 10}} className="deliveryStatus" onClick={() => this.openResolutionModal(data.customerOrderResponseDto.orderReference)}><Tooltip title="Use this option to refund the customer. Attracts a 20% fee of product amount, capped at 10k">Refund</Tooltip></Tag>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Tag color={"#FE9C96"} style={{width: 60, height: 20, fontSize: 10}} className="deliveryStatus" onClick={() => this.openEscalateResolutionModal(data.customerOrderResponseDto.orderReference)}><Tooltip title="Use this option to escalate situation to Paydoor, both parties will have to present their poof for accurate resolution">Escalate</Tooltip></Tag>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <br/>
                      </>
                      )
                  })}
                </Col>
            </Row>



              {/* PRODUCT VIEW MODAL */}
              <Modal visible={isShowProductModal}
                    width={500}
                    title={"Product Details"}
                    maskClosable={true}
                    onCancel={this.showProductModal}
                    footer={[
                        <Button key="back" onClick={this.showProductModal}>
                            Close
                        </Button>,
                    ]}
                >
                    {productItem !== undefined &&
                    <div>
                        <Row style={{fontSize: 12}}>
                            <Col span={7}>
                                <span style={{color: "#72859C"}}>Product Name : </span>
                            </Col>
                            <Col span={17} >
                                <span>{productItem.productName}</span>
                                <Divider />
                            </Col>
                        </Row>
                        <Row style={{fontSize: 12}}>
                            <Col span={7}>
                                <span style={{color: "#72859C"}}>Price : </span>
                            </Col>
                            <Col span={17} >
                                <span>{Number(productItem.price).toLocaleString()}</span>
                                <Divider />
                            </Col>
                        </Row>
                        <Row style={{fontSize: 12}}>
                            <Col span={7}>
                                <span style={{color: "#72859C"}}>Discount (%) : </span>
                            </Col>
                            <Col span={17} >
                                <span>{productItem.discount}</span>
                                <Divider />
                            </Col>
                        </Row>
                        <Row style={{fontSize: 12}}>
                            <Col span={7}>
                                <span style={{color: "#72859C"}}>MediaLink : </span>
                            </Col>
                            <Col span={17} >
                                <img
                                    src={productItem.mediaLink}
                                    alt="image"
                                    width="100%"
                                />
                                <Divider />
                            </Col>
                        </Row>
                        <Row style={{fontSize: 12}}>
                            <Col span={7}>
                                <span style={{color: "#72859C"}}>Description : </span>
                            </Col>
                            <Col span={17} >
                                <TextArea rows={3} disabled value={productItem.description}/>
                            </Col>
                        </Row>
                        <Row style={{marginTop: 20, fontSize: 12}}>
                            <Col span={7}>
                                <span style={{color: "#72859C"}}>Product Status : </span>
                            </Col>
                            <Col span={17} >
                                <Tag color={productItem.status === "ACTIVE" ? "#68BAFD" : "#FE9C96"} className="deliveryStatus">{productItem.status}</Tag>
                            </Col>
                        </Row>
                    </div>}
                </Modal>

                {/* ORDER VIEW MODAL */}
              <Modal visible={isShowOrderModal}
                    width={500}
                    title={"Customer Details"}
                    maskClosable={true}
                    onCancel={this.showOrderModal}
                    footer={[
                        <Button key="back" onClick={this.showOrderModal}>
                            Close
                        </Button>,
                    ]}
                >
                    {orderItem !== undefined &&
                    <div>
                        <Row style={{fontSize: 12}}>
                            <Col span={7}>
                                <span style={{color: "#72859C"}}>Name : </span>
                            </Col>
                            <Col span={17} >
                                <span>{orderItem.name}</span>
                                <Divider />
                            </Col>
                        </Row>
                        <Row style={{fontSize: 12}}>
                            <Col span={7}>
                                <span style={{color: "#72859C"}}>phone : </span>
                            </Col>
                            <Col span={17} >
                                <span>{orderItem.phone ? orderItem.phone : "NIL"}</span>
                                <Divider />
                            </Col>
                        </Row>
                        <Row style={{fontSize: 12}}>
                            <Col span={7}>
                                <span style={{color: "#72859C"}}>Email : </span>
                            </Col>
                            <Col span={17} >
                                <span>{orderItem.email}</span>
                                <Divider />
                            </Col>
                        </Row>
                        <Row style={{fontSize: 12}}>
                            <Col span={7}>
                                <span style={{color: "#72859C"}}>Rejection Reason : </span>
                            </Col>
                            <Col span={17} >
                                <TextArea rows={3} disabled value={rejectionReason !== undefined ? rejectionReason : ""}/>
                            </Col>
                        </Row>
                        <Row style={{marginTop: 20, fontSize: 12}}>
                            <Col span={7}>
                                <span style={{color: "#72859C"}}>Status : </span>
                            </Col>
                            <Col span={17} >
                                <Tag color={"#FE9C96"} className="deliveryStatus">{orderItem.deliveryStatus}</Tag>
                            </Col>
                        </Row>
                    </div>}
                </Modal>

                <Modal visible={triggerResolveDisputeModal}
                    title="Send Resolution Code to Customer"
                    width={500}
                    onOk={true}
                    onCancel={this.closeResolveDisputeTrigger} confirmLoading={loading}
                    maskClosable={true}
                    footer={[
                        <Button key="cancel" onClick={this.closeResolveDisputeTrigger} loading={loading}>
                            Cancel
                        </Button>,
                        <Button key="triggerResolveDispute" type="primary" style={{background:"#68BAFD", border: "none"}} onClick={() => this.openDisputeResolutionModal(disputeItemReference)} loading={loading}>
                            Trigger code
                        </Button>,
                    ]}
                >
                    <p>Please note, in other to resolve this dispute, customer will need to provide you a dispute resolution code.
                    <br/><br/>Do you want to trigger a resolution code to customer's email.</p>
                    <br/>
                    <hr style={{height: "0.5px", borderWidth: 0, color: "gray", backgroundColor: "gray"}}></hr>
                    <b><i><InfoCircleOutlined /> Use this option to close a dispute after an agreement has been reached by the merchant and customer. No fee is paid.</i></b>
                </Modal>



                <Modal visible={isShowDisputeResolutionModal}
                    title="Resolve Dispute"
                    width={500}
                    onOk={this.resolveDispute}
                    onCancel={this.closeDisputeResolutionModal} confirmLoading={loading}
                    maskClosable={false}
                    footer={[
                        <Button key="cancel" onClick={this.closeDisputeResolutionModal} loading={loading}>
                            Cancel
                        </Button>,
                        <Button key="resolveDispute" type="primary" style={{background:"#65D6C9", border: "none"}} onClick={this.resolveDispute} loading={loading}>
                            Click to Resolve Dispute
                        </Button>,
                    ]}
                >
                    <Input
                        name="code"
                        onChange={this.handleInputChange}
                        type="text"
                        placeholder="Enter the resolution code that was sent to customer's email"
                        value = {formData.code}
                    />
                    <br/><br/>
                    <TextArea rows={3} name="resolutionComment"
                        value={formData.resolutionComment}
                        placeholder={"Enter a resolution comment (Optional)"}
                        onChange={this.handleTextAreaChange}/>
                    <br/><br/>
                </Modal>

                <Modal visible={isShowResolutionModal}
                    title="Refund Customer"
                    width={500}
                    onOk={this.refundCustomer}
                    onCancel={this.closeResolutionModal} confirmLoading={loading}
                    maskClosable={true}
                    footer={[
                        <Button key="cancel" onClick={this.closeResolutionModal} loading={loading}>
                            Cancel
                        </Button>,
                        <Button key="refundDispute" type="primary" style={{background:"#68BAFD", border: "none"}} onClick={this.refundCustomer} loading={loading}>
                            Click to Refund Customer
                        </Button>,
                    ]}
                >
                    
                    <TextArea rows={3} name="resolutionComment"
                        value={formData.resolutionComment}
                        placeholder={"Enter a resolution comment (Optional)"}
                        onChange={this.handleTextAreaChange}/>
                    <br/><br/>
                    <b><i><InfoCircleOutlined /> Use this option to refund the customer. This attracts a 20% fee of the product amount capped at 10k</i></b>
               
                </Modal>

                <Modal visible={isShowEscalateResolutionModal}
                    title="Escalate Dispute"
                    width={500}
                    onOk={this.escalate}
                    onCancel={this.closeEscalateResolutionModal} confirmLoading={loading}
                    maskClosable={true}
                    footer={[
                        <Button key="cancel" onClick={this.closeEscalateResolutionModal} loading={loading}>
                            Cancel
                        </Button>,
                        <Button key="escalate" type="primary" style={{background:"#FE9C96", border: "none"}} onClick={this.escalate} loading={loading}>
                            Click to Escalate Dispute
                        </Button>,
                    ]}
                >
                    <TextArea rows={3} name="resolutionComment"
                        value={formData.resolutionComment}
                        placeholder={"Enter brief description about dispute (This is required)"}
                        onChange={this.handleTextAreaChange}/>
                    <br/><br/>
                    <b><i><InfoCircleOutlined /> Use this option to escalate situation to Paydoor, both parties will have to present their poof for accurate resolution</i></b>
                </Modal>
          </div>
      </div>
    )
  }
}

export default DisputeTable