import React, { Component } from 'react';
import { Layout, Breadcrumb, Button, Typography } from 'antd';
import { Wrapper } from '../index'
import Notification from '../../shared/components/notification';
import SettingsService from '../settings/services/settings.service'
import StoreFrontService from '../storeFront/services/storefront.service'
import UtilService from '../../services/util.service'
import Loading from '../../shared/components/loading/index'
import host from '../../shared/services/host.service'
import WalletService from '../../components/wallet/services/wallet.service';


import { DashboardOutlined, UserOutlined } from '@ant-design/icons';
import { Row, Col, Modal, Input, Form} from 'antd';

const { Content } = Layout;
const { Paragraph } = Typography;

class SettingsComponent extends Component {

    componentDidMount() {
        this.fetchStoreFrontUrl();
    }

    componentDidUpdate(){
        UtilService.setComponentActive("settings");
    }

    state = {
        loading: true,
        showChangePasswordModal: false,
        setConfirmLoading: false,
        showStoreFrontSetupModal: false,
        formData: {
            password: undefined,
            newPassword: undefined,
            confirmPassword: undefined
        },
        createStoreFrontFormDataRequest: {
            name: undefined,
        },
        updateStoreFrontFormDataRequest: {
            newName: undefined,
        },
        notification: {},
        storeFrontName: undefined,
        walletId: undefined
    };

    fetchStoreFrontUrl = () =>{
        WalletService.getWalletsByUserId(UtilService.getUserId())
        .then(walletResponse => {
            if(walletResponse.id){
                this.setState({
                    walletId: walletResponse.id
                })
                StoreFrontService.fetchStoreFrontByWalletID(walletResponse.id)
                .then(response => {
                    if(response.status === "success"){
                        this.setState({
                            storeFrontName: response.message,
                            updateStoreFrontFormDataRequest: {
                                newName: response.message
                            }
                        })
                    }
                    this.setState({
                        loading: false
                    })
                })
            }else{
                this.setState({
                    loading: false
                })
            }
        })
    }

    openChangePasswordModal = () => {
        this.setState({
            showChangePasswordModal: true
        })
    }
    closeChangePasswordModal = () => {
        this.setState({
            showChangePasswordModal: false,
            formData: {
                password: undefined,
                newPassword: undefined,
                confirmPassword: undefined
            }
        })
    }
    changePassword = () => {
        const {formData} = this.state;
        if(this.validateForm(formData)){
            formData.email = UtilService.getUserId();

            this.setState({
                setConfirmLoading: true
            });

            SettingsService.changePassword(formData)
            .then(response => {
                this.setState({
                    setConfirmLoading: false
                });
                if(response.status === "success"){
                    this.closeChangePasswordModal()
                    this.notify("success", "Success", response.message)
                }else{
                    this.notify("error", "An error occurred", response.message)
                }
                
            })
        }
    }
    handleInputChange = (e) => {
        const { name, value} = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        })
    };

    handleStoreFrontInputChange = (e) => {
        const { name, value} = e.target;

        if(this.state.storeFrontName){
            this.setState({
                updateStoreFrontFormDataRequest: {
                    ...this.state.updateStoreFrontFormDataRequest,
                    [name]: value
                }
            })
        }else{
            this.setState({
                createStoreFrontFormDataRequest: {
                    ...this.state.createStoreFrontFormDataRequest,
                    [name]: value
                }
            })
        }
       
    };

    openStoreFrontModal = () => {
        this.setState({
            showStoreFrontSetupModal: true
        })
    };

    closeStoreFrontModalModal = () => {
        this.setState({
            showStoreFrontSetupModal: false,
            
        })
    }

    createStoreFront = () =>{

        const {createStoreFrontFormDataRequest, walletId} = this.state;

        if(this.validateStoreFrontName()){
            this.setState({
                setConfirmLoading: true,
            });

            createStoreFrontFormDataRequest.walletID = walletId

            StoreFrontService.createStoreFront(this.state.createStoreFrontFormDataRequest)
            .then(response => {
                this.setState({
                    setConfirmLoading: false
                });
                if(response.status === "success"){
                    this.setState({
                        storeFrontName: response.getMessage
                    })
                    this.closeStoreFrontModalModal()
                    setTimeout(function(){ window.location.reload(); }, 1000);
                    this.notify("success", "Successful", "Store front url has been setup successfully")
                }else{
                    this.notify("error", "An error occurred processing request", response.message)
                }
            })
        }
    }

    updateStoreFront = () =>{

        const {updateStoreFrontFormDataRequest, walletId, storeFrontName} = this.state;

        if(this.validateStoreFrontName()){
            this.setState({
                setConfirmLoading: true,
            });

            updateStoreFrontFormDataRequest.walletID = walletId
            updateStoreFrontFormDataRequest.oldName = storeFrontName

            StoreFrontService.updateStoreFront(this.state.updateStoreFrontFormDataRequest)
            .then(response => {
                this.setState({
                    setConfirmLoading: false
                });
                if(response.status === "success"){
                    this.setState({
                        storeFrontName: response.getMessage
                    })
                    this.closeStoreFrontModalModal()
                    setTimeout(function(){ window.location.reload(); }, 1000);
                    this.notify("success", "Successful", "Store front url has been updated successfully")
                }else{
                    this.notify("error", "An error occurred processing request", response.message)
                }
            })
        }
    }


    validateStoreFrontName = () =>{
        const {updateStoreFrontFormDataRequest, storeFrontName} = this.state;

        const regexp =  /^[0-9a-zA-Z]{3,15}$/;
        if(!regexp.test(this.state.createStoreFrontFormDataRequest.name)){
            this.notify("error", "Invalid Input Supplied", "Store front should be min 3-15 characters without spaces")
            return false;
        }

        if(updateStoreFrontFormDataRequest.newName !== undefined && storeFrontName !== undefined){
            if(updateStoreFrontFormDataRequest.newName === storeFrontName){
                this.notify("error", "Validation failed", "Old name and new name is the same, please provide a different name")
                return false;
            }
        }

        return true;
    }

    validateForm = () => {
        const { formData } = this.state;

        if(formData.password === undefined || formData.password === "") {
            this.notify("error", "Invalid request", "Current password is required!");
            return false;
        }
        if(formData.newPassword === undefined || formData.newPassword === "") {
            this.notify("error", "Invalid request", "New password is required!");
            return false;
        }
        if(formData.confirmPassword === undefined || formData.confirmPassword === "") {
            this.notify("error", "Invalid request", "Confirm password is required!");
            return false;
        }
        if(formData.newPassword !== formData.confirmPassword) {
            this.notify("error", "Password does not match", "New password and confirm password does not match");
            return false;
        }
        return true;
    }

    formatLink = link => {
        return link.replace(/,/g, '-');
    };
 
    notify = (type, title, message) => {
        let notification = {
          type: type,
          title: title,
          message: message,
        };
        this.setState({ notification });
    };

    clearNotificationType = () => {
        this.setState({notification: {}})
    };

    render() {
        const {showChangePasswordModal, formData, loading, setConfirmLoading, showStoreFrontSetupModal, storeFrontName, walletId} = this.state;
        return (
            <Wrapper>
                <Content className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <Notification notification={this.state.notification} clearNotificationType = {this.clearNotificationType} />
                    <Breadcrumb style={{ margin: '16px 0' }}>
                        <Breadcrumb.Item><DashboardOutlined /> &nbsp;Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item><UserOutlined />&nbsp;Profile</Breadcrumb.Item>
                    </Breadcrumb>

                    {loading && <Loading background={"#fff"}/>}

                    {!loading &&
                    <Row type="flex" justify="space-between">
                        <Col span={24}>
                            <div className="" style={{background: "#fff", padding:30, paddingTop: 100, height: "auto", boxShadow: "0 1px 9px -3px rgba(0, 0, 0, 0.2)"}}>
                                <Row type="flex" justify="space-between">

                                    {/* My Account */}
                                    <Col span={24}>
                                        <div style={{fontSize: 20}}><b>My Account</b></div>
                                        <hr style={{height: "0.5px", borderWidth: 0, color: "gray", backgroundColor: "gray"}}></hr>
                                    </Col>
                                    <br/><br/><br/>
                                    <Col span={24}>
                                        <div>Account Name : {UtilService.getMerchantDetails().firstName} {UtilService.getMerchantDetails().lastName}</div>
                                        <hr style={{height: "0.5px", borderWidth: 0, color: "gray", backgroundColor: "gray"}}></hr>
                                    </Col>
                                    <br/><br/><br/>
                                    <Col span={24}>
                                        <div>{<Paragraph copyable={{ text: UtilService.getUserId() }}>User ID : {UtilService.getUserId()}</Paragraph>}</div> 
                                        <hr style={{height: "0.5px", borderWidth: 0, color: "gray", backgroundColor: "gray"}}></hr>
                                    </Col>
                                    <br/><br/><br/>
                                    <Col span={24}>
                                        <Button type='primary' onClick={this.openChangePasswordModal}> Change Password </Button>
                                    </Col>
                                    <br/><br/><br/>

                                    {/* Store front */}
                                    {walletId &&
                                    <Col span={24}>
                                        <div style={{fontSize: 20}}><b>Store Front</b></div>
                                        <hr style={{height: "0.5px", borderWidth: 0, color: "gray", backgroundColor: "gray"}}></hr>
                                    </Col>}
                                    <br/><br/><br/>
                                    {walletId &&
                                    <Col span={24}>
                                        {storeFrontName &&
                                        <div>
                                            {<Paragraph copyable={{ text: `${host.host}/store/${storeFrontName}`}}>
                                                Store Front Url : <a href= {`${host.host}/store/${storeFrontName}`}>{host.host}/store/{storeFrontName}</a>
                                            </Paragraph>}
                                        </div> }
                                    </Col>}
                                    {walletId &&
                                    <Col>
                                        {!storeFrontName &&
                                        <div> 
                                            <Button type='primary' onClick={this.openStoreFrontModal}> Setup Store Front</Button>
                                        </div>}

                                        {storeFrontName &&
                                        <div> 
                                            <Button type='primary' onClick={this.openStoreFrontModal}> Update Store Front Url</Button>
                                        </div>}
                                    </Col>}
                                    <br/><br/><br/>
                                </Row>

                                <Modal visible={showChangePasswordModal}
                                title="Change Password"
                                width={500}
                                onOk={this.changePassword}
                                confirmLoading={setConfirmLoading}
                                onCancel={this.closeChangePasswordModal}
                                maskClosable={true}
                                footer={[
                                    <Button key="cancel" onClick={this.closeChangePasswordModal} loading={setConfirmLoading}>
                                        Cancel
                                    </Button>,
                                    <Button key="confirm" type="primary" onClick={this.changePassword} loading={setConfirmLoading}>
                                        Confirm
                                    </Button>,
                                ]}
                                >
                                    <Form>
                                        <Form.Item>
                                            <label>Current Password</label>
                                            <Input
                                                name="password" 
                                                onChange={this.handleInputChange}
                                                type="password"
                                                placeholder={"Enter Current Password"}
                                                autoComplete="off"
                                                value={formData.password}/>
                                        </Form.Item>

                                        <Form.Item>
                                            <label>New Password</label>
                                            <Input
                                                name="newPassword" 
                                                onChange={this.handleInputChange}
                                                type="password"
                                                placeholder={"Enter New Password"}
                                                autoComplete="off"
                                                value={formData.newPassword}/>
                                        </Form.Item>

                                        <Form.Item>
                                            <label>Comfirm Password</label>
                                            <Input
                                                name="confirmPassword" 
                                                onChange={this.handleInputChange}
                                                type="password"
                                                placeholder={"Confirm Password"}
                                                autoComplete="off"
                                                value={formData.confirmPassword}/>
                                        </Form.Item>
                                    </Form>
                                </Modal>

                                <Modal visible={showStoreFrontSetupModal}
                                title="Setup Store Front"
                                width={500}
                                onOk={storeFrontName ? () => this.updateStoreFront() : () => this.createStoreFront()}
                                confirmLoading={setConfirmLoading}
                                onCancel={this.closeStoreFrontModalModal}
                                maskClosable={true}
                                footer={[
                                    <Button key="cancel" onClick={this.closeStoreFrontModalModal} loading={setConfirmLoading}>
                                        Cancel
                                    </Button>,
                                    <Button key="confirm" type="primary" onClick={storeFrontName ? () => this.updateStoreFront() : () => this.createStoreFront()} loading={setConfirmLoading}>
                                        {storeFrontName ? "Update" : "Create"}
                                    </Button>,
                                ]}
                                >
                                    <Form>
                                        <Form.Item>
                                            <label>Kindly enter store front url</label>
                                            <br/><br/>
                                            <Input
                                                name={storeFrontName ? "newName" : "name"}
                                                onChange={this.handleStoreFrontInputChange}
                                                type="text"
                                                placeholder={"Enter Store Front Name"}
                                                autoComplete="on"
                                                value={storeFrontName ? this.state.updateStoreFrontFormDataRequest.newName : this.state.createStoreFrontFormDataRequest.name}
                                                addonBefore={host.host+"/store/"} />
                                                <span style={{color:"red"}}>(Must be 3-15 characters without spaces)</span>
                                        </Form.Item>
                                    </Form>
                                </Modal>
                            </div>
                        </Col>
                    </Row>}
                </Content>
            </Wrapper>
        );
    }
}

export default SettingsComponent