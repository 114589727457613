import request from '../../../shared/services/request.service'
import UrlService from './url.service'
import { Component } from 'react';

class ProductService extends Component{

    static generateProductLink(data, walletId){
        let options = Object();

        options.url = UrlService.generateProductLink+"?walletId="+walletId;    
        options.repo = "ESCROW_PRODUCT";
        options.authenticated = true;
        options.data = data;
        options.multipart = true;

        return request.post(options);
    }

    static updateProductLink(data, ref){
        let options = Object();

        options.url = UrlService.productReferenceUrl+"/"+ref;  
        options.repo = "ESCROW_PRODUCT";
        options.authenticated = true;
        options.data = data;
        options.multipart = true;

        return request.post(options);
    }

    static getAllProducts(walletId){
        let options = Object();

        options.url = UrlService.productReferenceUrl+"?walletId="+walletId;  
        options.repo = "ESCROW_PRODUCT";
        options.authenticated = true;

        return request.get(options);
    }

    static getProductByReference(reference){
        let options = Object();

        options.url = UrlService.productReferenceUrl+"/"+reference;  
        options.repo = "ESCROW_PRODUCT";
        options.authenticated = false;

        return request.get(options);
    }

    static deleteProductAndOrdersByOrderReference(walletId, productReference, orderReferenceList){
        let options = Object();

        options.url = UrlService.deleteProductAndOrdersByOrderReference+"/"+productReference+"?walletId="+walletId;  
        options.repo = "ESCROW_PRODUCT";
        options.authenticated = true;
        options.data = orderReferenceList;

        return request.post(options);
    }

    static verifyTransaction(reference){
        let options = Object();

        options.url = UrlService.verifyTransaction+"/"+reference;  
        options.repo = "ESCROW_THIRD_PARTY";
        options.authenticated = false;

        return request.get(options);
    }

    static doNameEnquiry(accountNumber, bankCode){
        let options = Object();

        options.url = UrlService.doNameEnquiry+"?accountNumber="+accountNumber+"&bankCode="+bankCode;  
        options.repo = "ESCROW_THIRD_PARTY";
        options.authenticated = false;

        return request.get(options);
    }

    static getAllProductsByListOfReferences(productReferences){
        let options = Object();

        options.url = UrlService.getAllProductsByReferenceList;  
        options.repo = "ESCROW_PRODUCT";
        options.authenticated = false;
        options.data = productReferences;

        return request.post(options);
    }

    static getCartSummary(data){
        let options = Object();

        options.url = UrlService.getCartSummary;  
        options.repo = "ESCROW_PRODUCT";
        options.authenticated = false;
        options.data = data;

        return request.post(options);
    }


    static getAllCategories(){
        let options = Object();

        options.url = UrlService.getAllCategories;  
        options.repo = "ESCROW_PRODUCT";
        options.authenticated = true;

        return request.get(options);
    }

}

export default ProductService