import React, { Component }  from 'react';
import { Menu, Row, Col, Dropdown, Layout} from 'antd';
import UtilService from '../../services/util.service'
import {
    BellOutlined,
    MailOutlined,
    PoweroffOutlined,
    UserOutlined
} from '@ant-design/icons';
import logo from '../../assets/Paydoor.png'


const { Header } = Layout;

class HeaderNav extends Component{
    state = {
        show: true
    };
    
    signOut = () => {
        UtilService.signOut();
    }
    
    render(){
        const menu = (
            <Menu>
              <Menu.Item>
                <a rel="noopener noreferrer" href="#">
                  No new item here
                </a>
              </Menu.Item>
            </Menu>
        );
        const logoutMenu = (
            <Menu>
                <Menu.Item icon={<UserOutlined/>} onClick={this.signOut}>
                <a rel="noopener noreferrer" href="#">
                    Sign Out
                </a>
                </Menu.Item>
            </Menu>
        )
        return(
            <Row>
                <Col xs={0} sm={0} md={24}>
                <Header className="site-layout-background" style={{ padding: 0, background: "#fff"}}>
                    <Row>
                        <Col style={{position: "absolute", right: "0"}}>
                            <Dropdown className="header-dropdown" overlay={menu} placement="bottomCenter" arrow trigger={['click']}>
                                <BellOutlined />
                                {/* <Badge dot={this.state.show} ><BellOutlined /></Badge> */}
                            </Dropdown>
                            <Dropdown className="header-dropdown" overlay={menu} placement="bottomLeft" arrow trigger={['click']}>
                                <MailOutlined />
                            </Dropdown>
                            <Dropdown className="header-dropdown" overlay={logoutMenu} placement="bottomRight" arrow trigger={['click']}>
                                <PoweroffOutlined />
                            </Dropdown>
                        </Col>
                    </Row>
                </Header>
                </Col>
                
                <Col md={0} lg={0} style={{marginBottom: "50px", zIndex: 1}}>
                    <Header className="site-layout-background" style={{ padding: 0, background: "#fff", 
                    position: "fixed", width: "100%", borderBottom: "2px solid #68BAFD"}}>
                        <Row>
                            <Col span={4}>
                                <div className="logo" style={{marginTop: -10, textAlign: "center"}}>
                                    <img src={ logo } style={{width: 55, height: 15}} alt =""/>
                                </div>
                            </Col>
                            <Col span={20} style={{position: "absolute", right: "0"}}>
                                <Dropdown className="header-dropdown" overlay={menu} placement="bottomCenter" arrow trigger={['click']}>
                                    <BellOutlined />
                                    {/* <Badge dot={this.state.show} ><BellOutlined /></Badge> */}
                                </Dropdown>
                                <Dropdown className="header-dropdown" overlay={menu} placement="bottomLeft" arrow trigger={['click']}>
                                    <MailOutlined />
                                </Dropdown>
                                <Dropdown className="header-dropdown" overlay={logoutMenu} placement="bottomRight" arrow trigger={['click']}>
                                    <PoweroffOutlined />
                                </Dropdown>
                            </Col>
                        </Row>
                    </Header>
                </Col>
            </Row>
        )
    }
}
export default HeaderNav