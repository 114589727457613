import request from '../../../shared/services/request.service'
import UrlService from './url.service'
import { Component } from 'react';

class OrderService extends Component {

    static getAllBanks() {
        let options = Object();

        options.url = UrlService.getAllBanks;
        options.repo = "ESCROW_GET_BANK";
        options.authenticated = false;

        return request.get(options);
    }

    static getBankByCode(code) {
        let options = Object();

        options.url = UrlService.getBankByCode+"/"+code;
        options.repo = "ESCROW_GET_BANK";
        options.authenticated = true;

        return request.get(options);
    }

    static getCustomerOrdersByWalletId(walletId) {
        let options = Object();

        options.url = UrlService.getCustomerOrdersByWalletId + "/" + walletId;
        options.repo = "ESCROW_ORDERS";
        options.authenticated = true;

        return request.get(options);
    }

    static getPendingOrdersByWalletIdAndProductReference(walletId, reference) {
        let options = Object();

        options.url = UrlService.getPendingOrdersByWalletIdAndProductReference + walletId + "?reference=" + reference;
        options.repo = "ESCROW_ORDERS";
        options.authenticated = true;

        return request.get(options);
    }

    static getCancellationFee(data) {
        let options = Object();

        options.url = UrlService.getCancellationFee;
        options.repo = "ESCROW_ORDERS";
        options.authenticated = true;
        options.data = data;

        return request.post(options);
    }

    static updateDeliveryStatus(orderRerence, status) {
        let options = Object();

        options.url = UrlService.updateDeliveryStatus + "/" + orderRerence + "/delivery-status/" + status;
        options.repo = "ESCROW_ORDERS";
        options.authenticated = true;

        return request.post(options);
    }

    static cancelOrder(data) {
        let options = Object();

        options.url = UrlService.cancelOrder;
        options.repo = "ESCROW_ORDERS";
        options.authenticated = true;
        options.data = data;

        return request.post(options);
    }

    static getOrderByOrderReference(reference) {
        let options = Object();

        options.url = UrlService.getOrderByReference + "?reference=" + reference;
        options.repo = "ESCROW_ORDERS";
        options.authenticated = false;

        return request.get(options);
    }

    static confirmOrder(orderRerence, data) {
        let options = Object();

        options.url = UrlService.confirmOrder+"/"+orderRerence;
        options.repo = "ESCROW_ORDERS";
        options.authenticated = false;
        options.data = data;

        return request.post(options);
    }

    static rejectOrder(orderRerence, data) {
        let options = Object();

        options.url = UrlService.rejectOrder+"/"+orderRerence;
        options.repo = "ESCROW_ORDERS";
        options.authenticated = false;
        options.data = data;

        return request.post(options);
    }

    static generateOtp(userId) {
        let options = Object();

        options.url = UrlService.generateOtp + "?request=" + userId;
        options.repo = "ESCROW_OTP";
        options.authenticated = true;

        return request.get(options);
    }
    
    static getNewOrdersCountByWalletId(walletId) {
        let options = Object();

        options.url = UrlService.getNewOrdersCount + "/" + walletId;
        options.repo = "ESCROW_ORDERS";
        options.authenticated = true;

        return request.get(options);
    }

    static getBatchOrder(walletId) {
        let options = Object();

        options.url = UrlService.getBatchOrder + "/" + walletId;
        options.repo = "ESCROW_ORDERS";
        options.authenticated = true;

        return request.get(options);
    }

    static getAllOrdersInBatch(batchId) {
        let options = Object();

        options.url = UrlService.getAllOrdersInBatch + batchId;
        options.repo = "ESCROW_ORDERS";
        options.authenticated = true;

        return request.get(options);
    }

    static getAllOrdersByWalletId(walletId) {
        let options = Object();

        options.url = UrlService.getAllOrdersByWalletId + walletId;
        options.repo = "ESCROW_ORDERS";
        options.authenticated = true;

        return request.get(options);
    }
    
    static filterByCriteria(filterCriteria) {
        let options = Object();

        options.url = UrlService.filter + "?walletId="+filterCriteria.walletId+
        "&name="+filterCriteria.filterName+
        "&amount="+filterCriteria.filterAmount+
        "&orderReference="+filterCriteria.filterOrderReference+
        "&deliveryStatus="+filterCriteria.deliveryStatus+
        "&createdDate="+filterCriteria.createdDate+
        "&timeOfDelivery="+filterCriteria.timeOfDelivery

        options.repo = "ESCROW_ORDERS";
        options.authenticated = true;

        return request.get(options);
    }
}

export default OrderService