import React from 'react'
import { Button} from 'antd'
import { Link } from 'react-router-dom';
import './index.css'

const Error = () => (

    <div id="error">
        
        <div className="container text-center pt-32">
            <h1 className='error-title'>404</h1>
            <p style={{color: "#72859C"}}>we couldn't find the page you are looking for</p>
            <Button type="primary" shape="round" size={"large"} style={{background: "#65D6C9", border: "none"}}>
                <Link to="/login">Go Home</Link>
            </Button>
        </div>

        <div className="footer pt-32">
            <p className="text-center" style={{color: "#72859C"}}>Escrow &copy; 2021 Created by Blaise Leo</p>
        </div>
    </div>
)

export default Error
