import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Input, Form, Row, Col } from 'antd'
import { ArrowLeftOutlined} from '@ant-design/icons'
import Notification from '../../../shared/components/notification';
import AuthService from '../services/auth.service'
import Loading from '../../../shared/components/loading/index'
import logo from '../../../assets/Paydoor.png'
import './index.css'

const FormItem = Form.Item;

class ForgotPasswordForm extends Component {

    state = {
        formData: {
          email: '',
        },
        notification: {},
        loading: false
    };
    
    handleInputChange = (event) => {
        this.setState({
          formData: {
            ...this.state.formData,
            [event.currentTarget.name]: event.currentTarget.value
          }
        });
    };

    submit = () => {
        const { formData } = this.state;
        if (this.validForm(formData.email)) {
            this.setState({
                loading: true
            });
            AuthService.forgotPassword(formData.email)
            .then(response => {
                if(response.status === "success"){
                    this.setState({
                        loading: false
                    });
                    this.notify("success", "Success", "A password reset link has been sent to your email.")
                }else{
                    this.setState({
                        loading: false
                    });
                    this.notify("error", "Unable to complete request", response.message)
                }
            });
        }
    };

    validForm = (email) => {
        const {formData} = this.state;
        const emailValidation = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(formData.email === undefined || formData.email === "") {
            this.notify("error", "Unable to process request", "Email is required");
            return false;
        }else {
            if(!emailValidation.test(email)){
                this.notify("error", "Unable to process request", "Invalid Email Supplied");
                return false;
            }
        }

        return true;
    };

    notify = (type, title, message) => {
        let notification = {
          type: type,
          title: title,
          message: message,
        };
        this.setState({ notification });
    };

    clearNotificationType = () => {
        this.setState({notification: {}})
    };

    render() {
        return(
            <div className="wrapper fadeInDown">
                <Notification notification={this.state.notification} clearNotificationType = {this.clearNotificationType} />

                {this.state.loading &&
                <div className="formContent" style={{zIndex: '9', background: 'rgba(255, 255, 255, 0.77)'}}>
                    <Loading />
                </div>}

                <div className="formContent">
                    {/* <Tabs Titles */}
                    <Row>
                        <Col span={4} offset={-20} className="back-to-home">
                            <h5><Link to="/login"><ArrowLeftOutlined /> Back</Link></h5>
                        </Col>
                        <Col span={24}>
                            {/* Icon */}
                            <div className="fadeIn first">
                                <img src={logo} id="icon" alt="User Icon" style={{height: 30, width: 120}}/>
                            </div>
                        </Col>
                        <Col span={24}>
                            <br/><h1 className="h2"> Forgot Passowrd </h1>
                        </Col>
                    </Row>

                    <br></br>
                    <div className="fadeIn first">
                        <span>Please enter your email to receive a password reset link.</span>
                    </div>
                    <br></br>
                    <Form className="form">
                        <FormItem
                        rules={[{ required: true }]} hasFeedback>
                            <Input
                                name="email" 
                                onChange={this.handleInputChange}
                                type="email"
                                placeholder={"Email"}
                                className="fadeIn second"
                                value = {this.state.formData.email}
                            />
                        </FormItem>
                        <input type="button" className="fadeIn third" value="Submit" onClick={this.submit}/>
                    </Form>
                </div>
            </div>
        );
    }
}

export default ForgotPasswordForm