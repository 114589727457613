import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Input, Form, Row, Col } from 'antd'
import { ArrowLeftOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import Notification from '../../../shared/components/notification';
import AuthService from '../services/auth.service'
import Loading from '../../../shared/components/loading/index'
import logo from '../../../assets/Paydoor.png'
import './index.css'

const FormItem = Form.Item

class ResetPassword extends Component {

    state = {
        formData: {
          newPassword: undefined,
          confirmPassword: undefined,
        },
        showPassword: false,
        showComfirmPassword: false,
        notification: {}
    }
    
    handleInputChange = (event) => {
        this.setState({
          formData: {
            ...this.state.formData,
            [event.currentTarget.name]: event.currentTarget.value
          }
        });
    }

    submit = () => {
        if (this.validForm()) {
            this.setState({
                loading: true
            });
            const { formData } = this.state;
            const token = this.props.match.params.token
            if(token){
                formData.token = token;
                AuthService.resetPassword(formData)
                .then(response => {
                    if(response.status === "success"){
                        this.notify("success", "Success", "Password successfully updated")
                        setTimeout(function(){ window.location.href = "/login"; }, 1000);
                    }else{
                        this.setState({
                            loading: false
                        });
                        this.notify("error", "An error occurred", response.message)
                    }
                })
            }
        }
    }

    validForm = () => {
        const {formData} = this.state;

        if(formData.newPassword === undefined || formData.newPassword === "") {
            this.notify("error", "Unable to process request", "Please enter password")
            return false;
        }

        if(formData.confirmPassword === undefined || formData.confirmPassword === "") {
            this.notify("error", "Unable to process request", "Please confirm password")
            return false;
        }

        if(formData.newPassword !== formData.confirmPassword){
            this.notify("error", "Unable to process request", "Password does not match!")
            return false;
        }

        return true;
    }

    showPassword = () => {
        this.setState({
            showPassword: this.state.showPassword ? false : true
        })
    }

    showComfirmPassword = () => {
        this.setState({
            showComfirmPassword: this.state.showComfirmPassword ? false : true
        })
    }

    notify = (type, title, message) => {
        let notification = {
          type: type,
          title: title,
          message: message,
        };
        this.setState({ notification });
    };

    clearNotificationType = () => {
        this.setState({notification: {}})
    }

    render() {
        return(
            <div className="wrapper fadeInDown">
                <Notification notification={this.state.notification} clearNotificationType = {this.clearNotificationType} />

                {this.state.loading &&
                <div className="formContent" style={{zIndex: '9', background: 'rgba(255, 255, 255, 0.77)'}}>
                    <Loading />
                </div>}
                <div className="formContent">
                    {/* <Tabs Titles */}
                    <Row>
                        <Col span={4} offset={-20} className="back-to-home">
                            <h5><Link to="/login"><ArrowLeftOutlined /> Back</Link></h5>
                        </Col>
                        <Col span={24}>
                            {/* Icon */}
                            <div className="fadeIn first">
                                <img src={logo} id="icon" alt="User Icon" style={{height: 30, width: 120}}/>
                            </div>
                        </Col>
                        
                        <Col span={24}>
                            <br/><h1 className="h2"> Reset Password </h1>
                        </Col>
                    </Row>
                    
                    <br></br>
                    <div className="fadeIn first">
                        <span>Please enter new password below.</span>
                    </div>
                    <br></br>
                    <Form className="form">
                        <FormItem
                        rules={[{ required: true }]} hasFeedback>
                            <Input
                                name="newPassword"
                                onChange={this.handleInputChange}
                                type={this.state.showPassword ? "text" : "password"}
                                placeholder="New password"
                                className="fadeIn third"
                                value = {this.state.formData.newPassword}
                                autoComplete="off"
                            />
                            <div className="fadeIn third">
                                {this.state.showPassword ? <EyeOutlined className="showPassword" onClick={this.showPassword} /> :
                                <EyeInvisibleOutlined className="showPassword" onClick={this.showPassword}/>}
                            </div>
                        </FormItem>

                        <FormItem
                        rules={[{ required: true }]} hasFeedback>
                            <Input
                                name="confirmPassword" 
                                onChange={this.handleInputChange}
                                type={this.state.showComfirmPassword ? "text" : "password"}
                                placeholder={"Confirm password"}
                                className="fadeIn second"
                                value = {this.state.formData.confirmPassword}
                            />
                            <div className="fadeIn third">
                                {this.state.showComfirmPassword ? <EyeOutlined className="showPassword" onClick={this.showComfirmPassword} /> :
                                <EyeInvisibleOutlined className="showPassword" onClick={this.showComfirmPassword}/>}
                            </div>
                        </FormItem>
                        
                        <input type="submit" className="fadeIn third" value="Submit" onClick={this.submit}/>
                    </Form>
                </div>
            </div>
        );
    }
}

export default ResetPassword