import React, { Component } from 'react'
import {Card, Input, Row, Col, Tag, Form, Button, Divider, Select, Spin, Modal, Layout, Menu} from 'antd';

import Notification from '../../../shared/components/notification';
import ProductService from '../services/product.service'
import OrderService from '../../order/services/order.service'
import host from '../../../shared/services/host.service'
import Loading from '../../../shared/components/loading/index'
import logo from '../../../assets/Paydoor.png'
import { FlutterWaveButton, closePaymentModal } from 'flutterwave-react-v3';
import '../index.css';

const { TextArea } = Input;
const FormItem = Form.Item;
const { Option } = Select;
const { Meta } = Card;
const { Header, Content, Footer } = Layout;

class ProductView extends Component{

    componentDidMount () {
        this.getProductByReference();
        this.getBanks();

        var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
        (function(){
        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src='https://embed.tawk.to/629bd9d0b0d10b6f3e75b86f/1g4ocllu4';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
        })();  
    }

    getProductByReference = () =>{
        if(this.props.match.params.reference){
            this.setState({
                productReference: this.props.match.params.reference
            },() =>{
                ProductService.getProductByReference(this.state.productReference)
                .then(response =>{
                    if(response.status === "ACTIVE"){
                        this.setState({
                            businessName: response.businessName,
                            deliveryDate: response.deliveryDate,
                            description: response.description,
                            discount: response.discount,
                            mediaLink: response.mediaLink,
                            price: response.price,
                            discountedPrice: response.discountedPrice,
                            productName: response.productName,
                            quantity: response.quantity,
                            category: response.category,
                            loading: false
                        })
                    }else{
                        window.location.href= "/404"
                    }
                }) 
            })
        }else{
            this.notify("error", "Unable to complete request", "No product reference found")
        }
    };

    getBanks = () => {
        OrderService.getAllBanks()
        .then(response => {
            this.setState({
                bankList: response
            })
        })
    };

    state = {
        notification: {},
        formData: {
            name: undefined,
            phone: undefined,
            email: undefined,
            address: undefined,
            productReference: undefined,
            amount: undefined,
            paymentReference: undefined,
            transactionReference: undefined,
            transactionStatus: undefined,
            message: undefined,
            accountNumber: undefined,
            bankCode: undefined,
        },
        bankList: [],
        productReference: undefined,
        businessName: undefined,
        deliveryDate: undefined,
        description: undefined,
        discount: undefined,
        mediaLink: undefined,
        price: undefined,
        discountedPrice: undefined,
        productName: undefined,
        category: undefined,
        quantity: undefined,
        loading: true,
        nameEnquiryResponse: undefined,
        previewCustomerDeliveryDetailsModal: false
    };

    handleInputChange = (e) => {
        const { name, value} = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        })
    };

    handleSubmit = () => {

        if(this.validateForm()) {
            this.setState({
                previewCustomerDeliveryDetailsModal: true
            });
        }
    };

    handleFlutterTransactionSuccess = (transactionResponse) => {

        if(transactionResponse.status === "successful" || transactionResponse.status === "completed"){
            window.location.href = `${host.host}/order/success/${transactionResponse.tx_ref}`
        }else{
            this.notify("error", "An error occurred", transactionResponse.message)
            this.setState({
                loading: false
            })
        }
    };

    handleSelectChange = (e) => {
        this.setState({
            nameEnquiryResponse: undefined,
            formData: {
                ...this.state.formData,
                bankCode: e,
                accountNumber: undefined,
            }
        })
    };

    validateForm = () => {
        const { formData, nameEnquiryResponse } = this.state;
        const emailValidation = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(formData.name === undefined || formData.name === "") {
            this.notify("error", "Unable to process request", "Full Name is required");
            return false;
        }
        if(formData.phone && formData.phone.length > 0 && (isNaN(formData.phone) || formData.phone.length !== 11 )) {
            this.notify("error", "Unable to process request", "Phone number should be 11 digits")
            return false;
        }
        if(formData.email === undefined || formData.email === "") {
            this.notify("error", "Unable to process request", "Email is required");
            return false;
        }else {
            if(!emailValidation.test(formData.email)){
                this.notify("error", "Unable to process request", "Invalid Email Supplied");
                return false;
            }
        }
        if(formData.address === undefined || formData.address === "") {
            this.notify("error", "Unable to process request", "Enter Delivery Address");
            return false;
        }
        if(formData.bankCode === undefined || formData.bankCode === "") {
            this.notify("error", "Unable to process request", "Kindly Select Bank");
            return false;
        }
        if(formData.accountNumber === undefined || formData.accountNumber === "") {
            this.notify("error", "Unable to process request", "Account Number is required");
            return false;
        }else if(isNaN(formData.accountNumber) || formData.accountNumber.length < 10 || formData.accountNumber.length > 10){
            this.notify("error", "Unable to process request", "Invalid Account Number Supplied");
            return false;
        }
        if(nameEnquiryResponse === undefined || nameEnquiryResponse === "" || nameEnquiryResponse === "Invalid account details") {
            this.notify("error", "Unable to process request", "Account number not resolved");
            return false;
        }
        return true;
    };

    handleValidateAccountInputChange = (e) => {
        const { name, value} = e.target;
        const regexp = /^[0-9/b]+$/;

        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        }, () =>{
            if(this.state.formData.accountNumber.length === 10){
                if(!regexp.test(value)){
                    this.notify("error", "Invalid input supplied", "Account number should be only digits")
                }else{
                    this.setState({
                        loading: true,
                        nameEnquiryResponse: undefined
                    });
                    const accountNumber = this.state.formData.accountNumber;
                    const bankCode = this.state.formData.bankCode;

                    ProductService.doNameEnquiry(accountNumber, bankCode)
                    .then(response =>{
                        if(response.status === "SUCCESS" && response.message !== undefined && response.message.length > 0){
                            this.setState({
                                    loading: false,
                                    nameEnquiryResponse: response.message === "null" ? "Invalid account details" : response.message,
                                });
                        }else {
                            this.setState({
                                loading: false,
                            });
                            this.notify("error", "Unable to resolve account", response.message);
                        }
                    })
                }
            }else {
                this.setState({
                    nameEnquiryResponse: undefined,
                });
            }
        });
    };

    notify = (type, title, message) => {
        let notification = {
          type: type,
          title: title,
          message: message,
        };
        this.setState({ notification });
    };

    clearNotificationType = () => {
        this.setState({notification: {}})
    };

    render(){
        const { productReference, businessName, deliveryDate, description, discount, mediaLink, price, discountedPrice,
            productName, formData, nameEnquiryResponse, loading, previewCustomerDeliveryDetailsModal, category, quantity} = this.state;
           
        const config = {
            public_key: process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
            tx_ref: Date.now(),
            amount: discountedPrice,
            currency: 'NGN',
            payment_options: 'card,mobilemoney,ussd',
            customer: {
              email: formData.email,
              phonenumber: formData.phone,
              name: formData.name,
            },
            meta: {
                name: formData.name,
                phone: formData.phone,
                email: formData.email,
                address: formData.address,
                accountNumber: formData.accountNumber,
                bankCode: formData.bankCode,
                productReference: productReference
            },
            customizations: {
              title: 'Paydoor',
              description: 'Payment for '+productReference,
            },
            text: 'Place Order',
            callback: (response) => {
                this.handleFlutterTransactionSuccess(response);
                closePaymentModal() // this will close the modal programmatically
            },
            onClose: () => {},
        };

        return(

            <Layout>
                <Notification notification={this.state.notification} clearNotificationType = {this.clearNotificationType} />
                <Header style={{ position: 'sticky', top: 0, zIndex: 1, width: '100%' }}>
                <div>
                    <div className="logo" style={{marginTop: -10, marginLeft: -10, textAlign: "left"}}>
                        <img src={ logo } style={{width: 100, height: 25}} alt =""/>
                    </div>
                </div>
                <Menu
                    theme="dark"
                />
                </Header>
                <Content className="site-layout">
                <Row style={{marginTop: 50}}>
                    <Col xs={0} sm={0} md={24} style={{padding: "0px 30px"}}>
                        <Card style={{width: 600, margin:"auto"}}
                            cover={<img alt="photo"
                            src="https://cdn.pixabay.com/photo/2021/08/23/14/50/online-shopping-6567977__480.png"
                            style={{margin: "auto"}}
                        />}>
                            <Meta
                                title={businessName}
                                /><br></br>
                                {loading && <Loading background={"#fff"}/>
                            }
                            <Form className="form" align="left">
                            <h4>Product Information</h4>
                            <br/>
                                <FormItem>
                                    <Row style={{marginBottom: -35, fontSize: 12}}>
                                        <Col span={7}>
                                            <span style={{color: "#72859C"}}>Product Name : </span>
                                        </Col>
                                        <Col span={17} >
                                            <span>{productName}</span>
                                            <Divider />
                                        </Col>
                                </Row>
                                </FormItem>
                                <FormItem>
                                    <Row style={{marginBottom: -35, fontSize: 12}} >
                                        <Col span={7}>
                                            <span style={{color: "#72859C"}}>Price : </span>
                                        </Col>
                                        {discountedPrice === price ?
                                            <Col span={17}>
                                                <Tag color="#1890FF">N{price ? price.toLocaleString() : undefined}</Tag>
                                                <Divider />
                                            </Col>
                                            :
                                            <Col span={17}>
                                                <Tag color="#FE9C96"><s>N{price ? price.toLocaleString() : undefined}</s></Tag>
                                                <Tag color="#1890FF">N{discountedPrice ? discountedPrice.toLocaleString() : undefined}</Tag>
                                                <Divider />
                                            </Col>
                                        }
                                    </Row>
                                </FormItem>
                                {category !== undefined && category.name !== undefined &&
                                <FormItem>
                                    <Row style={{marginBottom: -35, fontSize: 12}} >
                                        <Col span={7}>
                                            <span style={{color: "#72859C"}}>Category : </span>
                                        </Col>
                                        <Col span={17}>
                                            <span>{category.name}</span>
                                            <Divider />
                                        </Col>
                                    </Row>
                                </FormItem>}
                                <FormItem>
                                    <Row style={{marginBottom: -35, fontSize: 12}} >
                                        <Col span={7}>
                                            <span style={{color: "#72859C"}}>Quantity : </span>
                                        </Col>
                                        <Col span={17}>
                                            <span>{quantity}</span>
                                            <Divider />
                                        </Col>
                                    </Row>
                                </FormItem>
                                <FormItem>
                                    <Row style={{marginBottom: -35, fontSize: 12}} >
                                        <Col span={7}>
                                            <span style={{color: "#72859C"}}>Discount (%) : </span>
                                        </Col>
                                        <Col span={17}>
                                            <span>{discount}</span>
                                            <Divider />
                                        </Col>
                                    </Row>
                                </FormItem>
                                <FormItem>
                                    <Row style={{marginBottom: -35, fontSize: 12}}>
                                        <Col span={7}>
                                            <span style={{color: "#72859C"}}>Estimated Delivery Date : </span>
                                        </Col>
                                        <Col span={17}>
                                            <span>{deliveryDate}</span>
                                            <Divider />
                                        </Col>
                                    </Row>
                                </FormItem>
                                <FormItem>
                                    <Row style={{marginBottom: -35, fontSize: 12}}>
                                        <Col span={7}>
                                            <span style={{color: "#72859C"}}>Media link : </span>
                                        </Col>
                                        <Col span={17} style={{textAlign: "justify"}}>
                                            <img
                                                src={mediaLink}
                                                alt="image"
                                                width="100%"
                                            />
                                            {/* <a href={mediaLink !== undefined && mediaLink !== null &&
                                                mediaLink.startsWith("https", 0) ? mediaLink : "//"+mediaLink}>{mediaLink}</a> */}

                                            <Divider />
                                        </Col>
                                    </Row>
                                </FormItem>
                                <FormItem>
                                    <Row style={{marginBottom: -35, fontSize: 12}}>
                                        <Col span={7}>
                                            <span style={{color: "#72859C"}}>Reference : </span>
                                        </Col>
                                        <Col span={17} >
                                            <span>{productReference}</span>
                                            <Divider />
                                        </Col>
                                </Row>
                                </FormItem>
                                <FormItem>
                                    <Row style={{fontSize: 12}}>
                                        <Col span={7}>
                                            <span style={{color: "#72859C"}}>Description : </span>
                                        </Col>
                                        <Col span={17}>
                                            <TextArea rows={3} disabled value={description}/>
                                        </Col>
                                    </Row>
                                </FormItem>

                                <h4>Customer Delivery Details</h4>
                                <span style={{color: "red", fontSize: 10}}>* All fields are required (Note: This information would be used should you require a refund)</span>
                                <Row>
                                    <Col span={24}>
                                        <FormItem>
                                            <Input type="text" name="name" placeholder="Enter full name" style={{width: "100%"}} onChange={this.handleInputChange} required/>
                                        </FormItem>
                                    </Col>
                                    <Col span={24}>
                                        <FormItem>
                                            <Input type="text" name="phone" placeholder="Enter your 11 digits phone number" maxLength="11" style={{width: "100%"}} onChange={this.handleInputChange}/>
                                        </FormItem>
                                    </Col>
                                    <Col span={24}>
                                        <FormItem>
                                            <Input type="email" name="email" placeholder="Enter your valid email" style={{width: "100%"}} onChange={this.handleInputChange} required/>
                                        </FormItem>
                                    </Col>
                                    <Col span={24}>
                                        <FormItem>
                                            <Input type="text" name="address" placeholder="Enter delivery address" style={{width: "100%"}} onChange={this.handleInputChange} required/>
                                        </FormItem>
                                    </Col>
                                    <Col span={24}>
                                        <FormItem style={{textAlign: "center"}}>
                                            <Select onChange={this.handleSelectChange} placeholder="Select Bank">
                                                {this.state.bankList.map((option) =>
                                                <Option key={option.name} value={option.code}>
                                                    {option.name}
                                                </Option>
                                                )}
                                            </Select> 
                                        </FormItem>
                                    </Col>
                                    {formData.bankCode !== undefined && formData.bankCode.length > 0 &&
                                        <Col span={24}>
                                            {formData.accountNumber !== undefined && formData.accountNumber.length !== 10 &&
                                            <span style={{color: "red", fontSize: 10}}>10 digits required</span>}

                                            {loading && <Spin />}

                                            {nameEnquiryResponse !== undefined  && <p><Tag color="#1890FF">{nameEnquiryResponse}</Tag></p>}
                                            <FormItem>
                                                <Input type="text" name="accountNumber" value={formData.accountNumber} placeholder="Enter valid account number" style={{width: "100%"}}
                                                    onChange={this.handleValidateAccountInputChange} required
                                                />
                                            </FormItem>
                                        </Col>
                                    }
                                </Row>
                            
                                <FormItem>
                                    <Button type="primary" shape="round" size={"large"} onClick={this.handleSubmit}
                                    style={{float: "right", background: "#65D6C9", border: "none"}} loading={loading}>
                                        Place Order
                                    </Button>
                                </FormItem>
                                <hr></hr>
                                <span style={{fontSize: 12}}>Powered by paydoor.co <img src={logo} id="icon" alt="User Icon" style={{height: 18, width: 70, float: "right"}}/></span>
                            </Form>
                        </Card>
                        </Col>
                        <Col md={0} lg={0} style={{width: "100%"}}>
                            <Card style={{width: 300, margin:"auto"}}
                                cover={<img alt="photo"
                                src="https://cdn.pixabay.com/photo/2021/08/23/14/50/online-shopping-6567977__480.png"
                                style={{margin: "auto"}}
                            />}>
                                <Meta
                                    title={businessName}
                                    /><br></br>
                                    {loading && <Loading background={"#fff"}/>
                                }
                                <Form className="form" align="left">
                                <h5>Product Information</h5>
                                <br/>
                                    <FormItem>
                                        <Row style={{marginBottom: -35, fontSize: 12}}>
                                            <Col span={7}>
                                                <span style={{color: "#72859C"}}>Product Name : </span>
                                            </Col>
                                            <Col span={17} >
                                                <span>{productName}</span>
                                                <Divider />
                                            </Col>
                                    </Row>
                                    </FormItem>
                                    <FormItem>
                                        <Row style={{marginBottom: -35, fontSize: 12}} >
                                            <Col span={7}>
                                                <span style={{color: "#72859C"}}>Price : </span>
                                            </Col>
                                            {discountedPrice === price ?
                                                <Col span={17}>
                                                    <Tag color="#1890FF">N{price ? price.toLocaleString() : undefined}</Tag>
                                                    <Divider />
                                                </Col>
                                                :
                                                <Col span={17}>
                                                    <Tag color="#FE9C96"><s>N{price ? price.toLocaleString() : undefined}</s></Tag>
                                                    <Tag color="#1890FF">N{discountedPrice ? discountedPrice.toLocaleString() : undefined}</Tag>
                                                    <Divider />
                                                </Col>
                                            }
                                        </Row>
                                    </FormItem>
                                    <FormItem>
                                        <Row style={{marginBottom: -35, fontSize: 12}} >
                                            <Col span={7}>
                                                <span style={{color: "#72859C"}}>Discount (%) : </span>
                                            </Col>
                                            <Col span={17}>
                                                <span>{discount}</span>
                                                <Divider />
                                            </Col>
                                        </Row>
                                    </FormItem>
                                    <FormItem>
                                        <Row style={{marginBottom: -35, fontSize: 12}}>
                                            <Col span={7}>
                                                <span style={{color: "#72859C"}}>Estimated Delivery Date : </span>
                                            </Col>
                                            <Col span={17}>
                                                <span>{deliveryDate}</span>
                                                <Divider />
                                            </Col>
                                        </Row>
                                    </FormItem>
                                    <FormItem>
                                        <Row style={{marginBottom: -35, fontSize: 12}}>
                                            <Col span={7}>
                                                <span style={{color: "#72859C"}}>Media link : </span>
                                            </Col>
                                            <Col span={17} style={{textAlign: "justify"}}>
                                                <img
                                                    src={mediaLink}
                                                    alt="image"
                                                    width="100%"
                                                />
                                                {/* <a href={mediaLink !== undefined && mediaLink !== null &&
                                                    mediaLink.startsWith("https", 0) ? mediaLink : "//"+mediaLink}>{mediaLink}</a> */}

                                                <Divider />
                                            </Col>
                                        </Row>
                                    </FormItem>
                                    <FormItem>
                                        <Row style={{marginBottom: -35, fontSize: 12}}>
                                            <Col span={7}>
                                                <span style={{color: "#72859C"}}>Reference : </span>
                                            </Col>
                                            <Col span={17} >
                                                <span>{productReference}</span>
                                                <Divider />
                                            </Col>
                                    </Row>
                                    </FormItem>
                                    <FormItem>
                                        <Row style={{fontSize: 12}}>
                                            <Col span={7}>
                                                <span style={{color: "#72859C"}}>Description : </span>
                                            </Col>
                                            <Col span={17}>
                                                <TextArea rows={3} disabled value={description}/>
                                            </Col>
                                        </Row>
                                    </FormItem>

                                    {/* ===========>>>>RESPONSIVE MOBILE PRODUCT VIEW<<<<================  */}
                                    <h4>Customer Delivery Details</h4>
                                    <span style={{color: "red", fontSize: 10}}>* All fields are required (Note: This information would be used should you require a refund)</span>
                                    <Row>
                                        <Col span={24}>
                                            <FormItem>
                                                <Input type="text" name="name" placeholder="Enter full name" style={{width: "100%"}} onChange={this.handleInputChange} required/>
                                            </FormItem>
                                        </Col>
                                        <Col span={24}>
                                            <FormItem>
                                                <Input type="text" name="phone" placeholder="Enter your 11 digits phone number" maxLength="11" style={{width: "100%"}} onChange={this.handleInputChange}/>
                                            </FormItem>
                                        </Col>
                                        <Col span={24}>
                                            <FormItem>
                                                <Input type="email" name="email" placeholder="Enter your valid email" style={{width: "100%"}} onChange={this.handleInputChange} required/>
                                            </FormItem>
                                        </Col>
                                        <Col span={24}>
                                            <FormItem>
                                                <Input type="text" name="address" placeholder="Enter delivery address" style={{width: "100%"}} onChange={this.handleInputChange} required/>
                                            </FormItem>
                                        </Col>
                                        <Col span={24}>
                                            <FormItem style={{textAlign: "center"}}>
                                                <Select onChange={this.handleSelectChange} placeholder="Select Bank">
                                                    {this.state.bankList.map((option) =>
                                                    <Option key={option.name} value={option.code}>
                                                        {option.name}
                                                    </Option>
                                                    )}
                                                </Select> 
                                            </FormItem>
                                        </Col>
                                        {formData.bankCode !== undefined && formData.bankCode.length > 0 &&
                                            <Col span={24}>
                                                {formData.accountNumber !== undefined && formData.accountNumber.length !== 10 &&
                                                <span style={{color: "red", fontSize: 10}}>10 digits required</span>}

                                                {loading && <Spin />}

                                                {nameEnquiryResponse !== undefined  && <p><Tag color="#1890FF">{nameEnquiryResponse}</Tag></p>}
                                                <FormItem>
                                                    <Input type="text" name="accountNumber" value={formData.accountNumber} placeholder="Enter valid account number" style={{width: "100%"}}
                                                        onChange={this.handleValidateAccountInputChange} required
                                                    />
                                                </FormItem>
                                            </Col>
                                        }
                                    </Row>
                                
                                    <FormItem>
                                        <Button type="primary" shape="round" size={"large"} onClick={this.handleSubmit}
                                        style={{float: "right", background: "#65D6C9", border: "none"}} loading={loading}>
                                            Place Order
                                        </Button>
                                    </FormItem>
                                    <hr></hr>
                                    <span style={{fontSize: 12}}>Powered by paydoor.co <img src={logo} id="icon" alt="User Icon" style={{height: 18, width: 70, float: "right"}}/></span>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Content>
            
                <Modal visible={previewCustomerDeliveryDetailsModal}
                    width={500}
                    title="Preview delivery details"
                    onCancel={() => this.setState({previewCustomerDeliveryDetailsModal: !previewCustomerDeliveryDetailsModal, loading: false})}
                    maskClosable={true}
                    footer={[
                        <Button key="cancel" loading={loading} onClick={() => this.setState({previewCustomerDeliveryDetailsModal: !previewCustomerDeliveryDetailsModal, loading: false})}>
                            Cancel
                        </Button>,
                        <>&nbsp;&nbsp;</>,
                        <FlutterWaveButton {...config} />
                        // <PaystackButton {...config} className="paystack-button"/>,
                    ]}
                >
                    <Form>
                        <FormItem>
                            <Row style={{marginBottom: -35, fontSize: 12}}>
                                <Col span={7}>
                                    <span style={{color: "#72859C"}}>Full Name: </span>
                                </Col>
                                <Col span={17} >
                                    <span>{formData.name}</span>
                                    <Divider />
                                </Col>
                            </Row>
                        </FormItem>

                        <FormItem>
                            <Row style={{marginBottom: -35, fontSize: 12}}>
                                <Col span={7}>
                                    <span style={{color: "#72859C"}}>Phone: </span>
                                </Col>
                                <Col span={17} >
                                    <span>{formData.phone ? formData.phone : "NIL"}</span>
                                    <Divider />
                                </Col>
                            </Row>
                        </FormItem>

                        <FormItem>
                            <Row style={{marginBottom: -35, fontSize: 12}}>
                                <Col span={7}>
                                    <span style={{color: "#72859C"}}>Email: </span>
                                </Col>
                                <Col span={17} >
                                    <span>{formData.email}</span>
                                    <Divider />
                                </Col>
                            </Row>
                        </FormItem>

                        <FormItem>
                            <Row style={{marginBottom: -35, fontSize: 12}}>
                                <Col span={7}>
                                    <span style={{color: "#72859C"}}>Delivery Address: </span>
                                </Col>
                                <Col span={17} >
                                    <span>{formData.address}</span>
                                    <Divider />
                                </Col>
                            </Row>
                        </FormItem>

                        <FormItem>
                            <Row style={{marginBottom: -35, fontSize: 12}}>
                                <Col span={7}>
                                    <span style={{color: "#72859C"}}>Account Name: </span>
                                </Col>
                                <Col span={17} >
                                    <span>{nameEnquiryResponse}</span>
                                    <Divider />
                                </Col>
                            </Row>
                        </FormItem>

                        <FormItem>
                            <Row style={{marginBottom: -35, fontSize: 12}}>
                                <Col span={7}>
                                    <span style={{color: "#72859C"}}>Account Number: </span>
                                </Col>
                                <Col span={17} >
                                    <span>{formData.accountNumber}</span>
                                    <Divider />
                                </Col>
                            </Row>
                        </FormItem>
                    </Form>
                </Modal>
            </Layout>
        )
    }
}

export default ProductView