import React, { Component } from 'react';
import { Breadcrumb, Layout, Menu, Row, Col, Modal, Button, Badge, Empty } from 'antd';
import {ShoppingCartOutlined}from '@ant-design/icons';
import Notification from '../../shared/components/notification';
import StoreFrontService from '../storeFront/services/storefront.service'
import Loading from '../../shared/components/loading/index'
import { Link } from 'react-router-dom';
import logo from '../../assets/Paydoor.png'
import "../storeFront/index.css"


const { Header, Content, Footer } = Layout;

class StorefrontComponent extends Component {

    componentDidMount(){
        this.getAllProducts();

        var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
        (function(){
        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src='https://embed.tawk.to/629bd9d0b0d10b6f3e75b86f/1g4ocllu4';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
        })();  
    }

    getAllProducts = () =>{
        StoreFrontService.getAllProductsByStoreName(this.props.match.params.reference)
        .then(response => {
            if(response && response.length > 0){
                this.setState({
                    productList: response,
                    loading: false
                })
            }else {
                this.setState({
                    productList: [],
                    loading: false
                })
            }
        })    
    };

    state = {
        showProductModal : false,
        cart: localStorage.getItem('cart') === null || localStorage.getItem('cart') === undefined ? [] : JSON.parse(localStorage.getItem('cart')),
        notification: {},
        productList: [],
        loading: true,
        currentItem: undefined,
    }

    openProductModal = (item) => {
        this.setState ({
            showProductModal: true,
            currentItem: item
        })
    }

    closeProductModal = () => {
        this.setState ({
            showProductModal: false,
            currentItem: undefined
        })
    }

    addToCart = (item) => {  
        
        let itemToAdd = {
            productRef: item.productRef,
            id: this.state.cart.length + 1,
            quantity: 1
        }
        
        let itemIndex = this.state.cart.findIndex((c) => c.productRef === item.productRef)
    
        if(itemIndex >= 0){
            this.state.cart[itemIndex] = {
                ...this.state.cart[itemIndex],
                quantity: this.state.cart[itemIndex].quantity + 1
            }
            this.setState({
                cart: [
                    ...this.state.cart,
                ]
            }, () => {
                this.updateLocalStorage()
            })
        }else{
            this.setState({
                cart: [
                    ...this.state.cart,
                    itemToAdd
                ]
            }, () => {
                this.updateLocalStorage()
            })
        }
        this.notify("success", "Successful", "Successfully added to cart");
        this.closeProductModal();
    }

    updateLocalStorage = () =>{
        localStorage.setItem("cart", JSON.stringify(this.state.cart))
    }

    notify = (type, title, message) => {
        let notification = {
          type: type,
          title: title,
          message: message,
        };
        this.setState({ notification });
    };

    clearNotificationType = () => {
        this.setState({notification: {}})
    };

  render(){

    const {showProductModal, currentItem, productList, loading} = this.state

    const cart = localStorage.getItem('cart') === null ? [] : JSON.parse(localStorage.getItem('cart'));
    const totalCount = Object.values(cart).flat().length;


    return (
        <Layout>
          <Notification notification={this.state.notification} clearNotificationType = {this.clearNotificationType} />
          <Header style={{ position: 'sticky', top: 0, zIndex: 1, width: '100%' }}>
          <div style={{float: 'right'}}>
                <Badge size="small" offset={[7, 0]} count={totalCount}>
                    <Link to="/user/cart">  <ShoppingCartOutlined style={{color:"white", fontSize: 25}}/> </Link>
                </Badge> 
            </div>
            <div>
                <div className="logo" style={{marginTop: -10, marginLeft: -10, textAlign: "left"}}>
                    <img src={ logo } style={{width: 100, height: 25}} alt =""/>
                </div>
            </div>
            <Menu
              theme="dark"
            />
          </Header>
          <Content className="site-layout">
            <Breadcrumb style={{ margin: '16px 0', padding: "0px 30px"}}>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>store</Breadcrumb.Item>
              <Breadcrumb.Item>{this.props.match.params.reference}</Breadcrumb.Item>
            </Breadcrumb>
            {loading && <div style={{background:"white", height:500, width:"auto"}}><Loading background={"#fff"}/></div>}
            {!loading &&
            <Row>
                <Col xs={0} sm={0} md={24} style={{padding: "0px 30px"}}>
                    <div style={{ padding: 24, height: "auto", background: "white" }}>
                        {productList.length === 0 && !this.state.loading &&
                        <Col xs={24} sm={24} md={24} xl={24}>
                            <div className="" style={{background: "#fff", padding:150, paddingTop: 100, height: "500px",  boxShadow: "0 1px 9px -3px rgba(0, 0, 0, 0.2)"}}>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </div>
                        </Col>}

                        {productList.length > 0 && !this.state.loading &&
                        <Row>
                            {this.state.productList.map((item) => {
                                return(<>
                                    {item.status === "ACTIVE" &&
                                    <Col xs={24} sm={12} md={12} xl={6} onClick={() => this.openProductModal(item)} 
                                    style={{ padding:"10px", cursor: "pointer"}}>
                                        <div style={{width: "100%", height:"200px"}}>
                                            <img src={item.mediaLink === null ? "http://www.sitech.co.id/assets/img/products/default.jpg" : item.mediaLink}
                                            alt="image" className="cover"/>
                                        </div>
                                        <div style={{padding: "25px 0px 25px 0px"}}>
                                            <h3>{item.productName}</h3>
                                            <div>
                                                <div><b>N{item.discountedPrice.toLocaleString()}</b></div>
                                                {item.discount > 0 && <div>
                                                    <div style={{float: 'left', textDecoration: "line-through", color: "gray", padding:3}}>{item.price.toLocaleString()}</div>
                                                    <div style={{float: 'left', border:"0.5px solid red", padding:2}}>{item.discount > 0 ? `${-item.discount.toLocaleString()}%` : item.discount.toLocaleString()}</div>
                                                </div>}
                                            </div>
                                        </div>
                                    </Col>}
                                </>
                            )})}
                        </Row>
                        }
                    </div>
                </Col>


                {/* ===========>>>>RESPONSIVE MOBILE PRODUCT VIEW<<<<================  */}
                <Col md={0} lg={0} style={{width: "100%"}}>
                    <div style={{height: "auto", marginTop:"50px", background: "white" }}>
                        <Row>
                            {this.state.productList.map((item) => {
                                return(<>
                                    <Col style={{border:"0.1px solid grey", padding:"20px", margin:"auto auto 50px auto", cursor: "pointer"}} xs={24} sm={24} md={10} xl={5} onClick={() => this.openProductModal(item)}>
                                        <div style={{width: "100%"}}>
                                            <img src={item.mediaLink === null ? "http://www.sitech.co.id/assets/img/products/default.jpg" : item.mediaLink}
                                            alt="image" height={200} width={"auto"} className="cover"/>
                                        </div>
                                        <div style={{padding: "25px 0px 25px 0px"}}>
                                            <h3>{item.productName}</h3>
                                            <div>
                                                <div><b>N{item.discountedPrice.toLocaleString()}</b></div>
                                                {item.discount > 0 && <div>
                                                    <div style={{float: 'left', textDecoration: "line-through", color: "gray", padding:3}}>{item.price.toLocaleString()}</div>
                                                    <div style={{float: 'left', border:"0.5px solid red", padding:2}}>{item.discount > 0 ? `${-item.discount.toLocaleString()}%` : item.discount.toLocaleString()}</div>
                                                </div>}
                                            </div>
                                        </div>
                                    </Col>
                                </>
                            )})}
                        </Row>
                    </div>
                </Col>
            </Row> }   
            <Modal visible={showProductModal}
                title="Product reference"
                width={800}
                onCancel={this.closeProductModal}
                maskClosable={true}
                footer={null}
            >
                <div style={{width: "100%"}}>
                    <img src={currentItem !== undefined && currentItem.mediaLink !== null ? currentItem.mediaLink : "http://www.sitech.co.id/assets/img/products/default.jpg"} 
                    alt="image" className="cover"/>
                </div>
                <div style={{padding: "25px 0px 25px 0px"}}>
                    <h2>{currentItem !== undefined ? currentItem.productName : ""}</h2>
                    
                        <h3>Product details</h3>
                        <hr style={{height: "0.5px", borderWidth: 0, color: "gray", backgroundColor: "gray"}}></hr>
                        <p>
                            {currentItem !== undefined ? currentItem.description : ""}
                        </p>
                        <p>
                            <b>Estimated Delivery Date: </b>{currentItem !== undefined ? currentItem.deliveryDate : "NIL"}
                        </p>
                    <hr style={{height: "0.5px", borderWidth: 0, color: "gray", backgroundColor: "gray"}}></hr>
                    
                    <div>
                        <div><b>{currentItem !== undefined ? "N"+currentItem.discountedPrice.toLocaleString() : 0}</b></div>
                        {currentItem !== undefined && currentItem.discount > 0 && 
                        <div>
                            <div style={{float: 'left', textDecoration: "line-through", color: "gray", padding:3}}>{currentItem !== undefined ? currentItem.price.toLocaleString() : 0}</div>
                            <div style={{float: 'left', border:"0.5px solid red", padding:2}}>{currentItem.discount > 0 ? `${-currentItem.discount.toLocaleString()}%` : currentItem.discount}</div>
                        </div>}
                    </div>
                    <br/>
                    
                    <div style={{float:"right"}}>
                        <Button type='primary' onClick={()=>this.addToCart(currentItem)}> Add to cart</Button>
                    </div>
                </div>
            </Modal>
          </Content>
          <Footer style={{ textAlign: 'center' }}>Paydoor ©2021 Created by Blaise Leo</Footer>
        </Layout>
      );
  }
};

export default StorefrontComponent;