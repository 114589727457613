import React, { Component }  from 'react';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, EyeOutlined } from '@ant-design/icons';
import {Table, Input, Button, Space, Row, Col, Tag, Modal, Tooltip, Divider, Steps, Popconfirm, Form, DatePicker, Select } from 'antd';

import ProductService from '../../product/services/product.service'
import Notification from '../../../shared/components/notification'
import OrderService from '../services/order.service';
import UtilService from '../../../services/util.service'
import WalletService from '../../../components/wallet/services/wallet.service'


import "./index.css"

const FormItem = Form.Item;
const { TextArea } = Input;
const { Step } = Steps;
const { Option } = Select;

class OrderTable extends Component{

    state = {
        searchText: '',
        searchedColumn: '',
        data: this.props.data,
        isShowModal: false,
        isShowCustomerDetailModal: false,
        deliveryItem: {},
        temporalDeliveryStatusItem: {},
        productItem: {},
        isShowDeliveryStatusModal: false,
        deliveryStatus: undefined,
        notification: {},
        pendingOrderModal: false,
        cancellationFee: undefined,
        loading: false,
        customerDetail: {},
        showOrderDetailsModal: false,
        allOrdersInBatch: [],
        ordersByWalletId: this.props.ordersByWalletId,
        isShowProductModalResponsive: false,
        currentProductItemResponsive: undefined,
        currentBatch: undefined,
        showFilterViewModal: false,
        filterCriteria: {
            walletId: undefined,
            filterName: '',
            filterAmount: '',
            filterOrderReference: '',
            deliveryStatus: '',
            createdDate: '',
            timeOfDelivery: '',
            
        },
        allfilteredOrders: [],
    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            <Input
            ref={node => {
                this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
            <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
            >
                Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
            </Button>
            <Button
                type="link"
                size="small"
                onClick={() => {
                confirm({ closeDropdown: false });
                    this.setState({
                        searchText: selectedKeys[0],
                        searchedColumn: dataIndex,
                    });
                }}
            >
                Filter
            </Button>
            </Space>
        </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'red' : undefined }} />,
        onFilter: (value, record) =>
        record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
        if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
        }
        },
        render: text =>
        this.state.searchedColumn === dataIndex ? (
            <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    handleDeliveryStatusItem = (orderItem) =>{
        if(orderItem.deliveryStatus === 'NEW'){
            return <Tag color="#A4A4A4" className="deliveryStatus" onClick={() => this.changeDeliveryStatusState(0, orderItem)}>{orderItem.deliveryStatus}</Tag>
        }else if(orderItem.deliveryStatus === 'ENROUTE'){
            return <Tag color="#FFCC00" className="deliveryStatus" onClick={() => this.changeDeliveryStatusState(1, orderItem)}>{orderItem.deliveryStatus}</Tag>
        }else if(orderItem.deliveryStatus === 'DELIVERED'){
            return <Tag color="#68BAFD" className="deliveryStatus" onClick={() => this.changeDeliveryStatusState(2, orderItem)}>{orderItem.deliveryStatus}</Tag>
        }else if(orderItem.deliveryStatus === 'CONFIRMED'){
            return <Tag color="#65D6C9" className="deliveryStatus" onClick={() => this.changeDeliveryStatusState(3, orderItem)}>{orderItem.deliveryStatus}</Tag>
        }else{
            return <Tag color="#FE9C96" className="deliveryStatus" onClick={() => this.changeDeliveryStatusState(4, orderItem)}>{orderItem.deliveryStatus}</Tag>
        }
    };

    changeDeliveryStatusState = (status, orderItem) => {
        this.setState({
            isShowDeliveryStatusModal: !this.state.isShowDeliveryStatusModal,
            deliveryStatus: status,
            temporalDeliveryStatusItem: orderItem
        })
    };

    showModal = (item) =>{
        if(item.productReference !== undefined){
            this.getProductByReference(item.productReference);
        }
        this.setState({
            isShowModal: !this.state.isShowModal,
            deliveryItem: item
        })
    };

    showCustomerDetailModal = (data) =>{
        this.setState({
            isShowCustomerDetailModal: !this.state.isShowCustomerDetailModal,
            customerDetail: {
                name: data.name,
                phone: data.phone,
                email: data.email,
                address: data.address
            }
        })
    };

    getProductByReference = (reference) =>{
        this.setState({
            loading: true
        });
        ProductService.getProductByReference(reference)
        .then(response => {
            if(response.productRef){
                this.setState({
                    productItem: response,
                    loading: false
                })
            }
        }) 
    };

    getCancellationFee = (response) =>{
        this.setState({
            loading: true
        });
        let request = [];
        request.push(response);
        OrderService.getCancellationFee(request)
        .then(data =>{
            this.setState({
                cancellationFee: data.fee,
                pendingOrderModal: true,
                loading: false
            })
        })
    };

    handleCancelOrder = (order) => {
        if(this.state.pendingOrderModal){
            this.setState({
                pendingOrderModal: false
            })
        }
        this.setState({
            loading: true
        });
        OrderService.cancelOrder(order)
        .then(response => {
            if(response.status === "success"){
                this.notify("success", "Success", "Order cancelled successfully")
            }else{
                this.notify("error", "Unable to complete request.", "An error occurred")
            }
            setTimeout(function(){ window.location.reload(); }, 1000);
        })
    };

    updateDeliveryStatus = (orderReference, deliveryStatus) => {
        this.setState({
            loading: true
        });
        let deliveryStatusArray = ["NEW","ENROUTE","DELIVERED"];
        OrderService.updateDeliveryStatus(orderReference, deliveryStatusArray[deliveryStatus])
        .then(response => {
            if(response && response.orderReference !== null){
                this.setState({
                    isShowDeliveryStatusModal: false
                });
                this.notify("success", "Success", "Delivery status updated successfully")
                setTimeout(function(){ window.location.reload(); }, 2000);
            }else{
                this.setState({
                    loading: false
                });
                this.notify("error", "Unable to process request", "An error occurred")
            }
        })
    };

    handleInputChange = (e) => {
        const { name, value} = e.target;
        this.setState({
            [name]: value
        })
    };

    formatDate = (timeOfDelivery) => {
        let dateDelivered = new Date(timeOfDelivery);
        let hours = dateDelivered.getHours();
        let minutes = dateDelivered.getMinutes();
        let ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0'+minutes : minutes;
        let strTime = " " + hours + ':' + minutes + ' ' + ampm;

        return dateDelivered.toLocaleDateString() + strTime;
    };

    openOrderDetailsModal = (batchId) => {
        this.getAllOrdersInBatch(batchId);
        this.setState({
            showOrderDetailsModal: true,
            currentBatch: batchId,
        })
    }

    closeOrderDetailsModal = () => {
        this.setState({
            showOrderDetailsModal: false,
            currentBatch: undefined,
            allOrdersInBatch: []
        })
    }

    openFilterViewModal = () => {
        this.setState({
            showFilterViewModal: true,
        })
    }


    closeFilterViewModal = () => {
        this.setState({
            showFilterViewModal: false,
            filterCriteria: {
                walletId: undefined,
                filterName: '',
                filterAmount: '',
                filterOrderReference: '',
                deliveryStatus: '',
                createdDate: '',
                timeOfDelivery: '',
                
            },
            allfilteredOrders: [],
        })
    }
 
    createdDatePickerOnChange = (date, dateString) => {
        this.setState({
            filterCriteria: {
                ...this.state.filterCriteria,
                createdDate: dateString,
            }
        })
    };

    timeOfDeliveryDatePickerOnChange = (date, dateString) => {
        this.setState({
            filterCriteria: {
                ...this.state.filterCriteria,
                timeOfDelivery: dateString,
            }
        })
    };

    handleSelectChange = (e) => {
        this.setState({
            filterCriteria: {
                ...this.state.filterCriteria,
                deliveryStatus: e,
            }
        })
    };

    handleFilterInputChange = (e) => {
        const { name, value} = e.target;
        this.setState({
            filterCriteria: {
                ...this.state.filterCriteria,
                [name]: value
            }
        })
    };

    filter = () =>{
        const { filterCriteria } = this.state

        if(!(filterCriteria.filterName === '' && filterCriteria.filterAmount === '' && filterCriteria.filterOrderReference === ''
        && filterCriteria.deliveryStatus === '' && filterCriteria.createdDate === '' && filterCriteria.timeOfDelivery === '')){        
            this.setState({
                loading: true
            });

            let userId = UtilService.getUserId();
            WalletService.getWalletsByUserId(userId)
            .then(response => {
                this.setState({
                    filterCriteria: {
                        ...this.state.filterCriteria,
                        walletId: response.id
                    }
                }, () => {
                    OrderService.filterByCriteria(this.state.filterCriteria)
                    .then(response => {
                        if(response && response.content && response.content.length > 0){
                            this.setState({
                                allfilteredOrders: response.content,
                                loading: false
                            })
                        }else {
                            this.setState({
                                loading: false,
                                allfilteredOrders: []
                            });
                        }
                    })
                })
            })
        }
    }
    
    handlePriceChange = (e) => {
        const { name, value} = e.target
        let formattedValue = value.split(',').join('')
        this.setState({ 
            filterCriteria: {
                ...this.state.filterCriteria,
                [name]: formattedValue
            }
        });
    };

    getAllOrdersInBatch = (batchId) => {
        this.setState({
            loading: true
        });
        OrderService.getAllOrdersInBatch(batchId)
        .then(response => {
            if(response && response.length > 0){
                this.setState({
                    allOrdersInBatch: response,
                    loading: false
                })
            }else {
                this.setState({
                    loading: false
                });
            }
        })
    }

    openProductModalResponsive = (data) =>{
        this.setState({
            isShowProductModalResponsive: true,
            currentProductItemResponsive: data
        });
    }

    closeProductModalResponsive = () =>{
        this.setState({
            isShowProductModalResponsive: false,
            currentProductItemResponsive: undefined
        });
    }

    notify = (type, title, message) => {
        let notification = {
          type: type,
          title: title,
          message: message,
        };
        this.setState({ notification });
    };

    clearNotificationType = () => {
        this.setState({notification: {}})
    };

  render() {
    const { data, isShowModal, productItem, deliveryItem, isShowDeliveryStatusModal, deliveryStatus,temporalDeliveryStatusItem, pendingOrderModal,
        cancellationFee, loading, isShowCustomerDetailModal, customerDetail, showOrderDetailsModal, allOrdersInBatch, ordersByWalletId,
        isShowProductModalResponsive, currentProductItemResponsive, currentBatch, showFilterViewModal, filterCriteria,
        allfilteredOrders} = this.state;

    //Try to fix this function, it also adds (,) after decimal
    let formattedPrice = filterCriteria.filterAmount === undefined ? '' : ''+filterCriteria.filterAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    
    let tableData;
    const orderColumns = [
        {
            title: 'S/N',
            dataIndex: 'sn',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            ...this.getColumnSearchProps('name'),
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            ...this.getColumnSearchProps('phone'),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            ...this.getColumnSearchProps('email'),
        },
        {
            title: 'Address',
            dataIndex: 'address',
            width: "30%",
            ...this.getColumnSearchProps('address'),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            ...this.getColumnSearchProps('amount'),
        },
        {
            title: 'Order reference',
            dataIndex: 'orderReference',
            ...this.getColumnSearchProps('orderReference'),
        },
        {
            title: 'Delivery Status',
            dataIndex: 'deliveryStatus',
            ...this.getColumnSearchProps('deliveryStatus'),
        },
        {
            title: 'Date Ordered',
            dataIndex: 'createdDate',
            ...this.getColumnSearchProps('createdDate'),
        },
        {
            title: 'Date Delivered',
            dataIndex: 'timeOfDelivery',
            ...this.getColumnSearchProps('timeOfDelivery'),
        },
        {
            title: 'Product Reference',
            dataIndex: 'productReference',
            ...this.getColumnSearchProps('productReference'),
        },
        {
            title: 'Qty',
            dataIndex: 'quantity',
            ...this.getColumnSearchProps('quantity'),
        },
        {
            title: 'Action',
            dataIndex: 'actions',
        },
    ]

    let index = 0;
    let orderskey = 0;
    tableData = []
    tableData = allOrdersInBatch && allOrdersInBatch.length > 0 && allOrdersInBatch.map(item => {
        return{
            key: key + orderskey + 1,
            sn: index = index + 1,
            name: item.name,
            phone: item.phone ? item.phone : "NIL",
            email: item.email,
            address: <TextArea disabled value={item.address !== undefined && item.address}/>,
            amount: "N"+ Number(item.amount).toLocaleString(),
            orderReference: item.orderReference,
            deliveryStatus: <Tooltip title="Click to update status">{this.handleDeliveryStatusItem(item)}</Tooltip>,
            timeOfDelivery: item.timeOfDelivery === null ? " -- -- -- " : this.formatDate(item.timeOfDelivery),
            createdDate: item.createdDate === null ? " -- -- -- " : this.formatDate(item.createdDate),
            productReference: item.productReference,
            quantity: item.quantity,
            actions: <Row justify="space-between">
                        <Col>
                            <Tooltip title="View Product Details"><Button type="text" onClick={() => this.showModal(item)}><EyeOutlined /> </Button></Tooltip>
                        </Col>
                    </Row>
        }
    });

    let batchData;
    let key = 0;
    let i = 0;
    batchData = data && data.map(item => {
        return{
            key: key = key+1,
            sn: i = i+1,
            batchId: item.batchId,
            totalItemsInBatch: item.totalItemsInBatch,
            createdDate: item.createdDate,
            newOrders:  <Tag color="#A4A4A4">{item.newOrders}</Tag>,
            enrouteOrders: <Tag color="#FFCC00">{item.enrouteOrders}</Tag>,
            deliveredOrders: <Tag color="#68BAFD">{item.deliveredOrders}</Tag>,
            confirmedOrders: <Tag color="#65D6C9">{item.confirmedOrders}</Tag>,
            rejectedOrders: <Tag color="#FE9C96">{item.rejectedOrders}</Tag>,
            cancelledOrders: <Tag color="#FE9C96">{item.cancelledOrders}</Tag>,
            actions: <Tooltip title="View Details"><Button type="text" onClick={() => this.openOrderDetailsModal(item.batchId)}><EyeOutlined /> </Button></Tooltip>
        }
    })

    const columns = [
        {
            title: 'S/N',
            dataIndex: 'sn',
        },
        {
            title: 'Batch ID',
            dataIndex: 'batchId',
            key: 'namebatchId',
        },
        {
            title: 'All Orders',
            dataIndex: 'totalItemsInBatch',
            key: 'totalItemsInBatch',
        },
        {
            title: 'New',
            dataIndex: 'newOrders',
            key: 'newOrders',
        },
        {
            title: 'Enroute',
            dataIndex: 'enrouteOrders',
            key: 'enrouteOrders',
        },
        {
            title: 'Delivered',
            dataIndex: 'deliveredOrders',
            key: 'deliveredOrders',
        },
        {
            title: 'Confirmed',
            dataIndex: 'confirmedOrders',
            key: 'confirmedOrders',
        },
        {
            title: 'Rejected',
            dataIndex: 'rejectedOrders',
            key: 'rejectedOrders',
        },
        {
            title: 'Cancelled',
            dataIndex: 'cancelledOrders',
            key: 'cancelledOrders',
        },
        {
            title: 'Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
        },
        {
            title: "Action",
            dataIndex: 'actions',
            key: "operation"
          }
    ];


    let filterOrdersData = [];
    let filterIndex = 0;
    let filterOrderkey = 0;

    filterOrdersData = allfilteredOrders && allfilteredOrders.length > 0 && allfilteredOrders.map(item => {
        return{
            key: key + filterOrderkey + 1,
            sn: filterIndex = filterIndex + 1,
            name: item.name,
            phone: item.phone ? item.phone : "NIL",
            email: item.email,
            address: <TextArea disabled value={item.address !== undefined && item.address}/>,
            amount: "N"+ Number(item.amount).toLocaleString(),
            orderReference: item.orderReference,
            deliveryStatus: <Tooltip title="Click to update status">{this.handleDeliveryStatusItem(item)}</Tooltip>,
            timeOfDelivery: item.timeOfDelivery === null ? " -- -- -- " : this.formatDate(item.timeOfDelivery),
            createdDate: item.createdDate === null ? " -- -- -- " : this.formatDate(item.createdDate),
            productReference: item.productReference,
            quantity: item.quantity,
            actions: <Row justify="space-between">
                        <Col>
                            <Tooltip title="View Product Details"><Button type="text" onClick={() => this.showModal(item)}><EyeOutlined /> </Button></Tooltip>
                        </Col>
                    </Row>
        }
    });

    return(
       <div>
            <Notification notification={this.state.notification} clearNotificationType = {this.clearNotificationType} />
            <div style={{background: "#fff", padding: 30, boxShadow: "0 1px 9px -3px rgba(0, 0, 0, 0.2)"}}>
                
                <Row>
                    <Col xs={0} sm={0} md={24}>
                        <h4>All Orders</h4>
                        <Row>
                            <Col xs={24} sm={24} md={24}>
                                <div style={{float:"right"}}>
                                    <Button key="filter" type='primary' size="large" style={{borderRadius:"10px"}} onClick={() => this.openFilterViewModal()} >
                                        + Add Filter
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>

                    {/* ===========>>>>RESPONSIVE MOBILE<<<<================  */}
                    <Col md={0} lg={0} style={{margin: -10, fontSize: "12px", width: "100%"}}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <h4>All Orders</h4>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <Row>
                {data.length > 0 && 
                <Col xs={0} sm={0} md={24} style={{padding: 30, background: "#fff"}}>
                    <Table columns={columns}
                        dataSource={batchData}
                        size="middle"
                        loading={this.state.loading}
                        scroll={{ x: 1000 }}/>
                    </Col>}



                {/* ===========>>>>RESPONSIVE MOBILE PRODUCT VIEW<<<<================  */}
                {ordersByWalletId.length > 0 &&
                <Col md={0} lg={0} style={{width: "100%"}}>
                    {ordersByWalletId.map((data) => {
                      return(
                            <>
                                <div className="TT-responsive-card">
                                    <Row className="responsive-view-row">
                                        <Col span={14}>
                                            <span style={{fontSize: 10, color: "gray"}}>Click to view customer details: </span><br/>
                                            <div style={{cursor: "pointer"}} onClick={() => this.showCustomerDetailModal(data)}><a href="#">{data.name && data.name !== null ? data.name : "NIL" }</a></div>
                                        </Col>
                                        
                                        <Col span={10} style={{fontSize:11}}>
                                            {data.amount && data.amount !== null ? <b>{'N' + Number(data.amount).toLocaleString()}</b> : "NIL"}
                                        </Col>
                                    </Row>
                                    <br></br>
                                    <Row className="responsive-view-row">
                                        <Col span={12} style={{fontSize: "8px"}}>
                                            <span style={{fontSize: 10, color: "gray"}}>Order Ref: </span><br/>
                                            <span style={{fontSize: "10px"}}>{data.orderReference && data.orderReference != null ? data.orderReference : "NIL" }</span>
                                        </Col>

                                        <Col span={12}>
                                            <span style={{fontSize: 10, color: "gray"}}>Click to view order details: </span><br/>
                                            <div style={{fontSize: "10px", cursor: "pointer"}} onClick={() => this.openProductModalResponsive(data)} ><a href="#">{"view more"}</a></div>
                                        </Col>
                                    </Row>
                                    <br></br>
                                    <Row className="responsive-view-row">
                                        <Col span={13}>
                                            <span style={{fontSize: 10, color: "gray"}}>Status: (Click to update status) </span><br/>
                                            <div style={{fontSize: "8px"}}>{data.deliveryStatus && data.deliveryStatus !== null ? this.handleDeliveryStatusItem(data) : "NIL" }</div>
                                        </Col>
                                        <Col span={11}>
                                            <span style={{fontSize: 10, color: "gray"}}>Date Ordered: </span><br/>
                                            <div style={{fontSize: "8px"}}>{data.createdDate && data.createdDate !== null ? this.formatDate(data.createdDate) : "-- -- --" }</div>
                                            <span style={{fontSize: 10, color: "gray"}}>Date Delivered: </span><br/>
                                            <div style={{fontSize: "8px"}}>{data.timeOfDelivery && data.timeOfDelivery !== null ? this.formatDate(data.timeOfDelivery) : "-- -- --" }</div>
                                        </Col>
                                    </Row>
                                </div>
                                <br/>
                            </>
                        )
                    })}
                </Col>}
                {/* ===========>>>>RESPONSIVE MOBILE PRODUCT VIEW<<<<================  */}
            </Row>



            {/* HANDLE DELIVERY STATUS MODAL */}
            <Modal visible={isShowDeliveryStatusModal}
                width={1000}
                title={"Update Delivery Status"}
                maskClosable={true} confirmLoading={loading}
                onCancel={() => this.changeDeliveryStatusState(0, temporalDeliveryStatusItem)}
                footer={[
                    <Button key="back" onClick={() => this.changeDeliveryStatusState(0, temporalDeliveryStatusItem)} loading={loading}>
                        Close
                    </Button>,
                    <Popconfirm title="Are you sure?" okText="YES" disabled={deliveryStatus > 1}
                        onConfirm={() => this.getCancellationFee(temporalDeliveryStatusItem)}>
                        <Button key="cancle" disabled ={deliveryStatus > 1} loading={loading}>
                            Cancel Order
                        </Button>
                    </Popconfirm>,
                    <Popconfirm title="Update delivery status" okText="YES" disabled ={deliveryStatus > 1}
                        onConfirm={() => this.updateDeliveryStatus(temporalDeliveryStatusItem.orderReference, deliveryStatus)}>
                        <Button key="next" disabled={deliveryStatus > 1} loading={loading}>
                            Next
                        </Button>
                    </Popconfirm>,
                ]}
            >
                <Steps size="small" direction="vertical" current={deliveryStatus} status={deliveryStatus === 4 ? "error": "process"}  percent={deliveryStatus === 3 ? 100 : deliveryStatus * 25}>
                    <Step title="NEW" description="Order is yet to be processed"/>
                    <Step title="ENROUTE" description="Order is on the way!"/>
                    <Step title="DELIVERED" description="Delivered, waiting confirmation"/>
                    <Step title="CONFIRMED" description="Order has been confirmed"/>
                    <Step title={temporalDeliveryStatusItem.deliveryStatus === "CANCELLED" ? "CANCELLED" : "REJECTED"}
                        description={temporalDeliveryStatusItem.deliveryStatus === "CANCELLED" ? "Order was cancelled" : "Order was rejected"}/>
                </Steps>
            </Modal>

            {/* HANDLE CANCEL ORDER MODAL */}
            <Modal visible={pendingOrderModal}
                width={500}
                onOk={() => {this.handleCancelOrder(temporalDeliveryStatusItem)}}
                onCancel={() => this.setState({pendingOrderModal: !pendingOrderModal})}
                maskClosable={true} confirmLoading={loading}
                footer={[
                    <Button key="cancel" onClick={() => this.setState({pendingOrderModal: !pendingOrderModal})} loading={loading}>
                        Cancel
                    </Button>,
                    <Button key="proceed" type="primary" onClick={() => {this.handleCancelOrder(temporalDeliveryStatusItem)}} loading={loading}>
                        Proceed
                    </Button>,
                ]}
            >
                <h2 style={{color: "red"}}>WARNING!</h2>
                <h3 style={{color: "red"}}>This will attract a fee of N{cancellationFee}</h3>
                <br></br>
                <span  style={{color: "red"}}>Do you wish to proceed?</span>
            </Modal>

            {/* CUSTOMER DETAIL MODAL */}
            <Modal visible={isShowCustomerDetailModal}
                width={500}
                title={`Customer Order Details`}
                maskClosable={true}
                onCancel={this.showCustomerDetailModal} confirmLoading={loading}
                footer={[
                    <Button key="back" onClick={this.showCustomerDetailModal} >
                        Close
                    </Button>,
                ]}
            >
                <div>
                    <Row style={{fontSize: 12}}>
                        <Col span={7}>
                            <span style={{color: "#72859C"}}>Name : </span>
                        </Col>
                        <Col span={17} >
                            <span>{customerDetail.name}</span>
                            <Divider />
                        </Col>
                    </Row>
                    <Row style={{fontSize: 12}}>
                        <Col span={7}>
                            <span style={{color: "#72859C"}}>Phone : </span>
                        </Col>
                        <Col span={17} >
                            <span>{customerDetail.phone ? customerDetail.phone : "NIL"}</span>
                            <Divider />
                        </Col>
                    </Row>
                    <Row style={{fontSize: 12}}>
                        <Col span={7}>
                            <span style={{color: "#72859C"}}>Email : </span>
                        </Col>
                        <Col span={17} >
                            <span>{customerDetail.email}</span>
                            <Divider />
                        </Col>
                    </Row>
                    <Row style={{marginTop: 20, fontSize: 12}}>
                        <Col span={7}>
                            <span style={{color: "#72859C"}}>Address : </span>
                        </Col>
                        <Col span={17} >
                            <span>{customerDetail.address}</span>
                            <Divider />
                        </Col> 
                    </Row>
                </div>

            </Modal>

            {/* PRODUCT VIEW MODAL RESPONSIVE*/}
            <Modal visible={isShowProductModalResponsive}
                width={500}
                title={`Order Details - ${currentProductItemResponsive !== undefined && currentProductItemResponsive.orderReference}`}
                maskClosable={true}
                onCancel={this.closeProductModalResponsive} confirmLoading={loading}
                footer={[
                    <Button key="back" onClick={this.closeProductModalResponsive} >
                        Close
                    </Button>,
                ]}
            >
                <div>
                    <Row style={{fontSize: 12}}>
                        <Col span={7}>
                            <span style={{color: "#72859C"}}>Product Name : </span>
                        </Col>
                        <Col span={17} >
                            <span>{currentProductItemResponsive !== undefined &&currentProductItemResponsive.productName}</span>
                            <Divider />
                        </Col>
                    </Row>
                    <Row style={{fontSize: 12}}>
                        <Col span={7}>
                            <span style={{color: "#72859C"}}>Price : </span>
                        </Col>
                        <Col span={17} >
                            <span>{'N' + Number(currentProductItemResponsive !== undefined && currentProductItemResponsive.productPrice).toLocaleString()}</span>
                            <Divider />
                        </Col>
                    </Row>
                    <Row style={{fontSize: 12}}>
                        <Col span={7}>
                            <span style={{color: "#72859C"}}>Discount (%) : </span>
                        </Col>
                        <Col span={17} >
                            <span>{currentProductItemResponsive !== undefined && currentProductItemResponsive.productDiscount}</span>
                            <Divider />
                        </Col>
                    </Row>
                    <Row style={{fontSize: 12}}>
                        <Col span={7}>
                            <span style={{color: "#72859C"}}>MediaLink : </span>
                        </Col>
                        <Col span={17} >
                            <img
                                src={productItem.mediaLink}
                                alt="image"
                                width="100%"
                            />
                            <Divider />
                        </Col>
                    </Row>
                    <Row style={{fontSize: 12}}>
                        <Col span={7}>
                            <span style={{color: "#72859C"}}>Description : </span>
                        </Col>
                        <Col span={17} >
                            <TextArea rows={3} disabled value={currentProductItemResponsive !== undefined && currentProductItemResponsive.productDescription}/>
                        </Col>
                    </Row>
                </div>
            </Modal>

            
            {/* PRODUCT VIEW MODAL */}
            <Modal visible={isShowModal}
                width={500}
                title={`Order Details - ${deliveryItem.orderReference}`}
                maskClosable={true}
                onCancel={this.showModal} confirmLoading={loading}
                footer={[
                    <Button key="back" onClick={this.showModal} >
                        Close
                    </Button>,
                ]}
            >
                <div>
                    <Row style={{fontSize: 12}}>
                        <Col span={7}>
                            <span style={{color: "#72859C"}}>Product Name : </span>
                        </Col>
                        <Col span={17} >
                            <span>{productItem.productName}</span>
                            <Divider />
                        </Col>
                    </Row>
                    <Row style={{fontSize: 12}}>
                        <Col span={7}>
                            <span style={{color: "#72859C"}}>Price : </span>
                        </Col>
                        <Col span={17} >
                            <span>{'N' + Number(productItem.price).toLocaleString()}</span>
                            <Divider />
                        </Col>
                    </Row>
                    <Row style={{fontSize: 12}}>
                        <Col span={7}>
                            <span style={{color: "#72859C"}}>Discount (%) : </span>
                        </Col>
                        <Col span={17} >
                            <span>{productItem.discount}</span>
                            <Divider />
                        </Col>
                    </Row>
                    <Row style={{fontSize: 12}}>
                        <Col span={7}>
                            <span style={{color: "#72859C"}}>MediaLink : </span>
                        </Col>
                        <Col span={17} >
                            <img
                                src={productItem.mediaLink}
                                alt="image"
                                width="100%"
                            />
                            <Divider />
                        </Col>
                    </Row>
                    <Row style={{fontSize: 12}}>
                        <Col span={7}>
                            <span style={{color: "#72859C"}}>Description : </span>
                        </Col>
                        <Col span={17} >
                            <TextArea rows={3} disabled value={productItem.description}/>
                        </Col>
                    </Row>
                    <Row style={{marginTop: 20, fontSize: 12}}>
                        <Col span={7}>
                            <span style={{color: "#72859C"}}>Product Status: </span>
                        </Col>
                        <Col span={17} >
                            <Tag color={productItem.status === "ACTIVE" ? "#68BAFD" : "#FE9C96"} className="deliveryStatus">{productItem.status}</Tag>
                        </Col>
                    </Row>
                </div>
            </Modal>


            {/* ORDER DETAILS VIEW MODAL */}
            <Modal visible={showOrderDetailsModal}
                width={1200}
                title={`Order batch - ${currentBatch !== undefined && currentBatch}`}
                maskClosable={true}
                onCancel={this.closeOrderDetailsModal} confirmLoading={loading}
                footer={[
                    <Button key="back" onClick={this.closeOrderDetailsModal} >
                        Close
                    </Button>,
                ]}
            >
               <Table columns={orderColumns}
                    dataSource={tableData}
                    size="middle"
                    loading={this.state.loading}
                    scroll={{ x: 1000 }}/>
            </Modal>

            {/* FILTER VIEW MODAL */}
            <Modal visible={showFilterViewModal}
                width={1200}
                title={'Search by optional criteria (All fields are optional)'}
                maskClosable={true}
                onCancel={this.closeFilterViewModal}
                footer={[
                    <Button key="back" onClick={this.closeFilterViewModal}>
                        Close
                    </Button>,
                ]}
            >
               <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <Form style={{border:"0.1px solid grey", padding:20}}>
                        <Row gutter={100}>
                            <Col xs={8} sm={8} md={8} lg={8}>
                                <h4>Customer Name</h4>
                                <Input
                                    name="filterName" 
                                    onChange={this.handleFilterInputChange}
                                    type="text"
                                    placeholder={"e.g John Doe"}
                                    value = {filterCriteria.filterName !== undefined ? filterCriteria.filterName : ''}
                                    autoComplete="off"
                                    style={{width:"100%"}}
                                />
                            </Col>
                            <Col xs={8} sm={8} md={8} lg={8}>   
                                <h4>Amount</h4>
                                <Input
                                    name="filterAmount" 
                                    onChange={this.handlePriceChange}
                                    type="text"
                                    placeholder={"e.g 200.00"}
                                    value = {formattedPrice}
                                    autoComplete="off"
                                    style={{width:"100%"}}
                                />
                            </Col>
                            <Col xs={8} sm={8} md={8} lg={8}>
                                <h4>Order Reference</h4>
                                <Input
                                    name="filterOrderReference" 
                                    onChange={this.handleFilterInputChange}
                                    type="text"
                                    placeholder={"e.g 1234567890"}
                                    value = {filterCriteria.filterOrderReference !== undefined ? filterCriteria.filterOrderReference : ''}
                                    autoComplete="off"
                                    style={{width:"100%"}}
                                />
                            </Col>
                        </Row>
                        <br/>
                        <Row gutter={100}>
                            <Col xs={8} sm={8} md={8} lg={8}>
                                <h4>Delivery Status</h4>
                                <Select onChange={this.handleSelectChange} style={{width:"100%"}} value={this.state.filterCriteria.deliveryStatus === '' ? 'Select Status' : this.state.filterCriteria.deliveryStatus} placeholder="Select Status">
                                    <Option key={"new"} value={"NEW"}>
                                        {"New"}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </Option>
                                    <Option key={"enroute"} value={"ENROUTE"}>
                                        {"Enroute"}
                                    </Option>
                                    <Option key={"delivered"} value={"DELIVERED"}>
                                        {"Delivered"}
                                    </Option>
                                    <Option key={"confirmed"} value={"CONFIRMED"}>
                                        {"Confirmed"}
                                    </Option>
                                    <Option key={"rejected"} value={"REJECTED"}>
                                        {"Rejected"}
                                    </Option>
                                    <Option key={"cancelled"} value={"CANCELLED"}>
                                        {"Cancelled"}
                                    </Option>
                                </Select> 
                            </Col>
                            <Col xs={8} sm={8} md={8} lg={8}>
                                <h4>Created Date</h4>
                                <DatePicker onChange={this.createdDatePickerOnChange} picker="createdDate" style={{width:"100%"}}/>
                            </Col>
                            <Col xs={8} sm={8} md={8} lg={8}>
                                <h4>Delivery Date</h4>
                                <DatePicker onChange={this.timeOfDeliveryDatePickerOnChange} picker="timeOfDelivery" style={{width:"100%"}}/>
                            </Col>
                        </Row>
                        <br/>
                        <Button key="filter" type='primary' size="large" style={{borderRadius:"10px", float:"right"}} onClick={() => this.filter()}>
                            Filter
                        </Button>
                    </Form>
                </div> 
                <br/><br/><br/>

               <Table columns={orderColumns}
                    dataSource={filterOrdersData}
                    size="middle"
                    loading={this.state.loading}
                    scroll={{ x: 1000 }}/>
            </Modal>
        </div>
    )
  }
}

export default OrderTable