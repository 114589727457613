import request from '../../../shared/services/request.service'
import UrlService from './url.service'
import { Component } from 'react';

class AuthService extends Component{

    static loginMerchant(data){
        let options = Object();

        options.url = UrlService.LOGIN;
        options.repo = "USER-MANAGEMENT";
        options.authenticated = false;
        options.data = data;
      
        return request.post(options);
    }

    static signUpMerchant(data){
        let options = Object();

        options.url = UrlService.SIGNUP;
        options.repo = "USER-MANAGEMENT";
        options.authenticated = false;
        options.data = data;
      
        return request.post(options);
    }

    static forgotPassword(userId){
        let options = Object();

        options.url = UrlService.FORGOT_PASSWORD +"/"+userId;
        options.repo = "USER-MANAGEMENT";
        options.authenticated = false;
      
        return request.get(options);
    }

    static resetPassword(data){
        let options = Object();

        options.url = UrlService.RESET_PASSWORD;
        options.repo = "USER-MANAGEMENT";
        options.authenticated = false;
        options.data = data;
      
        return request.post(options);
    }
}

export default AuthService