import React, { Component } from 'react';
import {Layout, Breadcrumb, Row, Col, Spin, Empty, Button} from 'antd';
import { Wrapper } from '../index'
import Notification from '../../shared/components/notification';

import { DashboardOutlined, CloseCircleOutlined } from '@ant-design/icons';
import CardComponent from "../../shared/components/cardComponent/index";
import RecentOrdersTable from "./recentOrdersComponent/index"
import Chart from "./chartComponent/index"
import UtilService from '../../services/util.service'
import DashboardService from './services/dashboard.service'
import WalletService from '../wallet/services/wallet.service'
import { Link } from 'react-router-dom';
import ResponsiveView from './responsive-view'

import './index.css'
import Loading from "../../shared/components/loading";

const { Content } = Layout;

class DashboardComponent extends Component {

    state = {
        cardDetails: {
            pendingOrders: 0,
            transactionValueThisMonth: 0,
            allLinks: 0
        },
        recentOrderList: [],
        chartDetails: [],
        notification: {},
        merchant: {},
        isNewMerchant: undefined,
        isShowNewMerchantNotification: true,
        loading: true,
        showSpiner: false
    };

    componentDidMount() {
        this.getCardDetails();
        this.getRecentOrders();
        this.getChartDetails();
        this.getMerchantDetails();
    }

    componentDidUpdate(){
        this.setComponentActive("dashboard");
    }

    getCardDetails = () => {
        let userId = UtilService.getUserId();
        WalletService.getWalletsByUserId(userId)
        .then(response =>{
            if(response.id){
                DashboardService.getCardDetails(response.id)
                .then(data => {
                    this.setState({
                        cardDetails: data,
                    })
                })
            }
        })
    };

    getRecentOrders = () => {
        let userId = UtilService.getUserId();
        WalletService.getWalletsByUserId(userId)
        .then(response =>{
            if(response.id){
                DashboardService.getRecentOrders(response.id)
                .then(data => {
                    this.setState({
                        recentOrderList: data,
                    })
                })
            }
        })
    };

    getChartDetails = () => {
        let userId = UtilService.getUserId();
        WalletService.getWalletsByUserId(userId)
            .then(response =>{
                if(response.id){
                    DashboardService.getChartDetails(response.id)
                        .then(data => {
                            this.setState({
                                chartDetails: data,
                            })
                        })
                }
            })
    };

    getMerchantDetails = () =>{
        let userId = UtilService.getUserId();
        DashboardService.getMerchantByUserId(userId)
            .then(response => {
                if(response.id){
                    UtilService.setMerchantDetails(response);
                    if(response.status === "ACTIVE"){
                        this.setState({
                            isNewMerchant: false
                        })
                    }else if(response.status === "PENDING"){
                        this.setState({
                            isNewMerchant: true
                        })
                    }
                    this.setState({
                        merchant: {
                            firstName: response.firstName,
                            lastName: response.lastName,
                        },
                        loading: false,
                    })
                }
            })
    };

    setComponentActive = (component) =>{
        UtilService.setComponentActive(component)
    }

    notify = (type, title, message) => {
        let notification = {
          type: type,
          title: title,
          message: message,
        };
        this.setState({ notification });
    };

    clearNotificationType = () => {
        this.setState({notification: {}})
    }

    closeCompleteRegistrationNotification = () => {
        this.setState({
            isShowNewMerchantNotification: false
        })
    }

    resendConfirmationLink = () =>{
        this.setState({showSpiner: true});
        let userId = UtilService.getUserId();
        DashboardService.resendConfirmationToken(userId)
        .then(response => {
            if(response.status){
                this.setState({
                    isShowNewMerchantNotification: false,
                    showSpiner: false
                })
                this.notify("success", "Success", "Confirmation token sent!")
            }else {
                this.setState({showSpiner: false});
            }
        })
    }

    render() {
        const { firstName, lastName, cardDetails, recentOrderList, chartDetails } = this.state

        return (
            <Wrapper
                firstName= {firstName}
                lastName= {lastName}
            >
                <Content style={{padding: 24, minHeight: 360 }} className="site-layout-background">
                    <Notification notification={this.state.notification} clearNotificationType = {this.clearNotificationType} />

                    {this.state.isNewMerchant && this.state.isShowNewMerchantNotification &&
                    <Row>
                        <Col xs={0} sm={0} md={24}>
                            <div className="complete-registration-notification">
                                <h4 style={{color: "#7E7E7E", fontSize: "1vw"}}><span style={{color: "red"}}>Account not active! </span>Click the link sent to your email to complete your registration. Did not get a link?
                                    <a href="#" onClick={this.resendConfirmationLink}>&nbsp;&nbsp;resend link &nbsp;&nbsp;</a>
                                    {this.state.showSpiner && <Spin/>}
                                    <CloseCircleOutlined style={{float: "right"}} onClick={this.closeCompleteRegistrationNotification}/>
                                </h4>
                            </div>
                        </Col>
                        <Col md={0} lg={0} style={{width: "100%"}}>
                            <div className="complete-registration-notification">
                                <h4 style={{color: "#7E7E7E", fontSize: 10}}><span style={{color: "red"}}>Account not active! </span>Click the link sent to your email to complete your registration. Did not get a link?
                                    <a href="#" onClick={this.resendConfirmationLink}>&nbsp;&nbsp;resend link &nbsp;&nbsp;</a>
                                    {this.state.showSpiner && <Spin/>}
                                    <CloseCircleOutlined style={{float: "right"}} onClick={this.closeCompleteRegistrationNotification}/>
                                </h4>
                            </div>
                        </Col>
                    </Row>}

                    <Breadcrumb style={{ margin: '16px 0' }}>
                        <Breadcrumb.Item /><DashboardOutlined /> &nbsp;Dashboard
                        {/* <div style={{ float: "right"}} >
                            <h5>Overview <InfoCircleOutlined /> </h5>
                        </div> */}
                    </Breadcrumb>

                    {this.state.loading && <Loading background={"#fff"}/>}

                    {!this.state.loading &&
                    cardDetails.pendingOrders !== undefined &&
                    cardDetails.transactionValueThisMonth !== undefined &&
                    cardDetails.allLinks !== undefined &&
                    <div>
                        <div>
                            <Row gutter={24}>

                                <Col span={8} xs={24} sm={24} md={8} xl={8}>
                                    <Link to="/orders" onClick={()=> this.setComponentActive("orders")}>
                                        <CardComponent 
                                            name = "Pending Orders"
                                            content= {cardDetails.pendingOrders.toLocaleString()}
                                            icon = "WalletOutlined"
                                            gradient = "bg-gradient-danger"
                                        />
                                    </Link>
                                </Col>

                                <Col span={8} xs={24} sm={24} md={8} xl={8}>
                                    <Link to="/wallet" onClick={()=> this.setComponentActive("wallet")}>
                                        <CardComponent 
                                            name = "Transaction Value This Month"
                                            content= {"₦ "+cardDetails.transactionValueThisMonth.toLocaleString()}
                                            icon = "WalletOutlined"
                                            gradient = "bg-gradient-info"
                                        />
                                    </Link>
                                </Col>

                                <Col span={8} xs={24} sm={24} md={8} xl={8}>
                                    <Link to="/products" onClick={()=> this.setComponentActive("products")}>
                                        <CardComponent 
                                            name = "All Products"
                                            content= {cardDetails.allLinks.toLocaleString()}
                                            icon = "WalletOutlined"
                                            gradient = "bg-gradient-success"
                                        />
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                        <br/><br/>
                        <div>
                            <Chart 
                                data = {chartDetails}
                            />
                        </div>
                        <br/><br/>
                        <Row>
                            <Col xs={0} sm={0} md={24}>
                                <div>
                                    <RecentOrdersTable
                                        data = {recentOrderList}
                                    />
                                </div>
                            </Col>

                            <Col md={0} lg={0} style={{width: "100%"}}>
                                <div style={{background: "#fff", padding: 20, paddingBottom: 5, boxShadow: "0 1px 9px -3px rgba(0, 0, 0, 0.2)"}}>
                                    <div>
                                        <h5>Recent Orders</h5>
                                    </div>
                                </div>
                                
                                {this.state.recentOrderList.length > 0 ? 
                                <>
                                    {this.state.recentOrderList.map((item) => {
                                        return(<>
                                            <ResponsiveView data={item} />
                                            <br />
                                        </>
                                        )
                                    })}
                                    <Button type="link" size="small" style={{float: "right"}}><Link to="/orders">See all</Link></Button>

                                </> 
                                :
                                <div style={{background: "#fff", padding: 20}}>
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                                </div>}
                            </Col>
                        </Row>
                    </div>}
                </Content>
            </Wrapper>
        );
    }
}

export default DashboardComponent