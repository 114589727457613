import React, { Component }  from 'react';
import { Layout, Row, Col, Badge } from 'antd';
import {
    DashboardOutlined,
    WalletOutlined,
    ShoppingOutlined,
    ShoppingCartOutlined,
    FlagOutlined,
    UserOutlined}from '@ant-design/icons';
import { Link } from 'react-router-dom';
import WalletWidget from '../components/walletWidget';
import UtilService from '../../services/util.service';
import WalletService from '../../components/wallet/services/wallet.service';
import OrderService from '../../components/order/services/order.service';
import DisputeService from '../../components/dispute/services/dispute.service';


const { Footer } = Layout;

class FooterComponent extends Component{

    componentDidMount(){
        this.getNewOrdersCount();
        this.getOpenDisputesCount();
    }

    getNewOrdersCount = () =>{
        let userId = UtilService.getUserId();
        WalletService.getWalletsByUserId(userId)
        .then(response => {
            if(response.id){
                OrderService.getNewOrdersCountByWalletId(response.id)
                .then(count => {
                    this.setState({
                        totalNewOrders: count
                    })
                })
            }
        })
    }

    getOpenDisputesCount = () =>{
        let userId = UtilService.getUserId();
        WalletService.getWalletsByUserId(userId)
        .then(response => {
            if(response.id){
                DisputeService.getOpenDisputeCountByWalletId(response.id)
                .then(count => {
                    this.setState({
                        totalOpenDisputes: count
                    })
                })
            }
        })
    }

    state = {
        showWalletWidget: false,
        walletWidgetAction: undefined,
        visible: false,
        componentActive: UtilService.getComponentActive() === null ? "dashboard" : UtilService.getComponentActive(),
        totalNewOrders: undefined,
        totalOpenDisputes: undefined,
    }

    isShowTransfer = () =>{
        this.setState({
            showWalletWidget: !this.state.showWalletWidget,
            walletWidgetAction: "Transfer",
            visible: true,
        })
    };
    
    isShowWithdraw = () =>{
        this.setState({
            showWalletWidget: !this.state.showWalletWidget,
            walletWidgetAction: "Withdraw",
            visible: true,
        })
    };

    hideDrawer = () => {
        this.setState({
            showWalletWidget: !this.state.showWalletWidget,
            visible: false,
        });
    };

    componentActive = (component) =>{
        return component === this.state.componentActive ? "block" : "none";
    }

    setComponentActive = (component) =>{
        UtilService.setComponentActive(component)
        this.setState({
            componentActive: UtilService.getComponentActive()
        })
    }
    
    render(){
        const {walletWidgetAction, showWalletWidget, totalNewOrders, totalOpenDisputes} = this.state;

        return (
            <div style={{marginTop: 60}}>
                <Footer style={{ background: "#fff", textAlign: 'center', position: 'fixed', bottom: 0, width:"100%", borderTop: "2px solid #68BAFD", padding: 25}}>
                    <Row>
                        <Col xs={0} sm={0} md={24}><h4>Paydoor ©2021 Created by Blaise Leo</h4></Col>
                        
                        {/* RESPONSIVE VIEW */}
                        <Col md={0} lg={0} style={{width:"100%", height: 25}}>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col span={4}>
                                    <Row className='footerLink'>
                                        <Col span={24}><Link to="/dashboard" onClick={()=> this.setComponentActive("dashboard")}><DashboardOutlined style={{fontSize: '20px', color: "#A9A9A9"}}/></Link></Col>
                                        <Col span={24} style={{fontSize: 8}}>Dashboard</Col>
                                        <span style={{border: "2px solid #68BAFD", width:"100%", display:this.componentActive("dashboard")}}></span>
                                    </Row>
                                </Col>
                                <Col span={4}>
                                    <Row className='footerLink'>
                                        <Col span={24}><Link to="/wallet" onClick={()=> this.setComponentActive("wallet")}><WalletOutlined style={{fontSize: '20px', color: "#A9A9A9"}}/></Link></Col>
                                        <Col span={24} style={{fontSize: 8}}>Wallet</Col>
                                        <span style={{border: "2px solid #68BAFD", width:"100%", display:this.componentActive("wallet") }}></span>
                                    </Row>
                                </Col>
                                <Col span={4}>
                                    <Row className='footerLink'>
                                        <Col span={24}><Link to="/products" onClick={()=> this.setComponentActive("products")}><ShoppingOutlined style={{fontSize: '20px', color: "#A9A9A9"}}/></Link></Col>
                                        <Col span={24} style={{fontSize: 8}}>Products</Col>
                                        <span style={{border: "2px solid #68BAFD", width:"100%", display:this.componentActive("products") }}></span>
                                    </Row>
                                </Col>
                                <Col span={4}>
                                    <Row className='footerLink'>
                                        <Col span={24}>
                                            <Link to="/orders" onClick={()=> this.setComponentActive("orders")}>
                                                <Badge size="small" offset={[10, -3]} count={totalNewOrders}>
                                                    <ShoppingCartOutlined style={{fontSize: '20px', color: "#A9A9A9"}}/>
                                                </Badge>
                                            </Link></Col>
                                        <Col span={24} style={{fontSize: 8}}>Orders</Col>
                                        <span style={{border: "2px solid #68BAFD", width:"100%", display:this.componentActive("orders") }}></span>
                                    </Row>
                                </Col>
                                <Col span={4}>
                                    <Row className='footerLink'>
                                        <Col span={24}>
                                            <Link to="/dispute" onClick={()=> this.setComponentActive("disputes")}>
                                                <Badge size="small" offset={[10, -3]} count={totalOpenDisputes}>
                                                    <FlagOutlined style={{fontSize: '20px', color: "#A9A9A9"}}/>
                                                </Badge>
                                            </Link>
                                        </Col>
                                        <Col span={24} style={{fontSize: 8}}>Disputes</Col>
                                        <span style={{border: "2px solid #68BAFD", width:"100%", display:this.componentActive("disputes") }}></span>
                                    </Row>
                                </Col>
                                <Col span={4} className='footerLink'>
                                    <Row className='footerLink'>
                                        <Col span={24}><Link to="/settings" onClick={()=> this.setComponentActive("settings")}><UserOutlined style={{fontSize: '20px', color: "#A9A9A9"}}/></Link></Col>
                                        <Col span={24} style={{fontSize: 8}}>Profile</Col>
                                        <span style={{border: "2px solid #68BAFD", width:"100%", display:this.componentActive("settings") }}></span>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    
                </Footer>
                {showWalletWidget &&
                    <>
                        <WalletWidget action={walletWidgetAction} isShowDrawer={this.showDrawer} isHideDrawer={this.hideDrawer} isVisible={this.state.visible}/>
                    </>
                }
            </div>
        );
    }
    
}
export default FooterComponent