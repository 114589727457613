import React, { Component }  from 'react';
import { Table, Empty, Tag, Typography, Button } from 'antd';
import { Link } from 'react-router-dom';

const { Paragraph } = Typography;

class RecentOrders extends Component {

  footer = () =>{
    return <div style={{marginBottom: 20}}>
            <Button type="link" size="small" style={{float: "right"}}><Link to="/orders">See all</Link></Button>
          </div>;
  }

  handleDeliveryStatus = (deliveryStatus) =>{
    if(deliveryStatus === 'NEW'){
        return <Tag color="#A4A4A4" className="deliveryStatus" style={{cursor: "default"}}>{deliveryStatus}</Tag>
    }else if(deliveryStatus === 'ENROUTE'){
        return <Tag color="#FFCC00" className="deliveryStatus" style={{cursor: "default"}}>{deliveryStatus}</Tag>
    }else if(deliveryStatus === 'DELIVERED'){
        return <Tag color="#68BAFD" className="deliveryStatus" style={{cursor: "default"}}>{deliveryStatus}</Tag>
    }else if(deliveryStatus === 'CONFIRMED'){
        return <Tag color="#65D6C9" className="deliveryStatus" style={{cursor: "default"}}>{deliveryStatus}</Tag>
    }else{
        return <Tag color="#FE9C96" className="deliveryStatus" style={{cursor: "default"}}>{deliveryStatus}</Tag>
    }
  }

  formatDate = (timeOfDelivery) => {
    let dateDelivered = new Date(timeOfDelivery);
    let hours = dateDelivered.getHours();
    let minutes = dateDelivered.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0'+minutes : minutes;
    let strTime = " " + hours + ':' + minutes + ' ' + ampm;

    return dateDelivered.toLocaleDateString() + strTime;
  };

  render() {
    const { data } = this.props

    let index = 0;
    let tableData = [];

    const columns = [
      {
        title: 'S/N',
        dataIndex: 'sn',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Product ref',
        dataIndex: 'product',
        key: 'product',
        width: '30%',
      },
      {
        title: 'Order ref',
        dataIndex: 'order',
        key: 'product',
      },
      {
        title: 'Date Ordered',
        dataIndex: 'createdDate',
        key: 'createdDate',
      },
      {
        title: 'Delivery Status',
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
      },
    ];
    
    tableData = data && data.length > 0 && data.map(item => {
      return{
          sn: index = index + 1,
          name: item.name,
          product: <Paragraph copyable type="danger">{item.productReference}</Paragraph>,
          order: <Paragraph copyable type="danger">{item.orderReference}</Paragraph>,
          createdDate: item.createdDate === null ? " -- -- -- " : this.formatDate(item.createdDate),
          status: this.handleDeliveryStatus(item.deliveryStatus),
          address: item.address,
      }
    });

    return (
        <div style={{background: "#fff", padding: 30, boxShadow: "0 1px 9px -3px rgba(0, 0, 0, 0.2)"}}>
            <div>
                <h4>Recent Orders</h4>
            </div>
            <br/>
            <div>
                {data.length > 0 ? 
                <Table columns={columns} dataSource={tableData} footer={() => this.footer()} size="middle" scroll={{ x: 1000 }} />
                :
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            </div>
        </div>
    )
  }
}
export default RecentOrders