import React, { Component } from 'react';
import { Layout, Breadcrumb } from 'antd';
import { Wrapper } from '../index'
import Notification from '../../shared/components/notification';
import OrderService from '../order/services/order.service'
import WalletService from '../wallet/services/wallet.service'
import UtilService from '../../services/util.service'
import Loading from '../../shared/components/loading/index'

import { DashboardOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import OrderTable from './orderTable/index'
import { Row, Col, Empty } from 'antd';

import './index.css'

const { Content } = Layout;

class OrderComponent extends Component {

    componentDidMount(){
        this.getOrders();
    }

    componentDidUpdate(){
        UtilService.setComponentActive("orders")

    }

    getOrders = () =>{
        let userId = UtilService.getUserId();
        WalletService.getWalletsByUserId(userId)
        .then(walletResponse => {
            if(walletResponse.id){
                OrderService.getBatchOrder(walletResponse.id)
                .then(ordeReresponse => {
                    if(ordeReresponse && ordeReresponse.length > 0){
                        this.setState({
                            data: ordeReresponse,
                        })
                    }else {
                        this.setState({
                            loading: false
                        });
                    }
                })

                OrderService.getAllOrdersByWalletId(walletResponse.id)
                .then(response => {
                    if(response && response.empty === false){
                        this.setState({
                            ordersByWalletId: response.content,
                            loading: false
                        })
                    }else {
                        this.setState({
                            loading: false
                        });
                    }
                })
            }else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    state = {
        notification: {},
        data: [],
        loading: true,
        ordersByWalletId: []
    };

    notify = (type, title, message) => {
        let notification = {
          type: type,
          title: title,
          message: message,
        };
        this.setState({ notification });
    };

    clearNotificationType = () => {
        this.setState({notification: {}})
    };

    render() {
        return (
            <Wrapper>
                <Content className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <Notification notification={this.state.notification} clearNotificationType = {this.clearNotificationType} />
                    <Breadcrumb style={{ margin: '16px 0' }}>
                        <Breadcrumb.Item><DashboardOutlined /> &nbsp;Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item><ShoppingCartOutlined />&nbsp;Orders</Breadcrumb.Item>
                    </Breadcrumb>

                    {this.state.loading && <Loading background={"#fff"}/>}

                    {!this.state.loading &&
                    <Row type="flex" justify="space-between">
                        {(this.state.data.length > 0 && this.state.ordersByWalletId.length > 0) &&
                        <Col span={24}>
                            <OrderTable 
                                data = {this.state.data}
                                loading = {this.state.loading}
                                ordersByWalletId = {this.state.ordersByWalletId}
                            />
                        </Col>}
                        
                        {this.state.data.length === 0 && this.state.ordersByWalletId.length === 0 &&
                        <Col span={24}>
                            <div className="" style={{background: "#fff", marginTop: 50, paddingTop: 100, height: "500px", boxShadow: "0 1px 9px -3px rgba(0, 0, 0, 0.2)"}}>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </div>
                        </Col>}
                    </Row>}
                </Content>
            </Wrapper>
        );
    }
}

export default OrderComponent