import React, { Component } from 'react'
import { Input, Form, Row, Col, Button, Modal, Steps, Popconfirm } from 'antd'
import Notification from '../../shared/components/notification'
import OrderService from '../order/services/order.service'
import Loading from '../../shared/components/loading/index'
import logo from '../../assets/Paydoor.png'


const FormItem = Form.Item;
const { Step } = Steps;
const { TextArea } = Input;

class CustomerView extends Component {

    componentDidMount(){
        var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
        (function(){
        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src='https://embed.tawk.to/629bd9d0b0d10b6f3e75b86f/1g4ocllu4';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
        })();  
    }

    state = {
        orderReference: undefined,
        isShowOrderTrackerModal: false,
        orderItem: {},
        deliveryStatus: undefined,
        isShowConfirmOrder: false,
        isShowRejectOrder: false,
        requestOrderReference: undefined,
        confirmationCode: undefined,
        rejectionReason: undefined,
        notification: {},
        loading: false
    };
    
    handleInputChange = (event) => {
        this.setState({
            [event.currentTarget.name]: event.currentTarget.value
        });
    };

    handleTextAreaChange = (event) => {
        this.setState({
            rejectionReason: event.target.value
        })
    };

    submit = () => {
        if (this.validForm()) {
            this.setState({
                loading: true
            });
            OrderService.getOrderByOrderReference(this.state.orderReference)
            .then(response =>{
                if(response.orderReference !== null && response.orderReference !== undefined){
                    this.setState({
                        orderItem: response,
                        isShowOrderTrackerModal: true,
                        deliveryStatus: this.getDeliveryStatusInt(response.deliveryStatus),
                        loading: false
                    })
                }else{
                    this.setState({
                        loading: false
                    });
                    this.notify("error", "Order Not Found", response.message)
                }
            }) 
        }
    }

    validForm = () => {
        const { orderReference } = this.state;

        if(orderReference === undefined || orderReference === "") {
            this.notify("error", "Unable to process request", "Kindly input your product reference")
            return false;
        }

        return true;
    }

    validRejectionReason = () => {
        const { rejectionReason } = this.state;

        if(rejectionReason === undefined || rejectionReason === "") {
            this.notify("error", "Unable to process request", "Kindly input your rejection reason")
            return false;
        }

        return true;
    }

    validateConfirmationCode = () => {
        const { confirmationCode } = this.state;

        if(confirmationCode === undefined || confirmationCode === "") {
            this.notify("error", "Unable to process request", "Enter your valid confirmation code")
            return false;

        }else if(isNaN(confirmationCode) || confirmationCode.length !== 6){
            this.notify("error", "Unable to process request", "Enter your valid 6 digits confirmation code")
            return false;
        }

        return true;
    };

    isShowOrderTrackerModal = () => {
        this.setState({
            isShowOrderTrackerModal: !this.state.isShowOrderTrackerModal,
        })
    };

    confirmOrder = (order) => {
        this.setState({
            requestOrderReference: order.orderReference,
            isShowConfirmOrder: !this.state.isShowConfirmOrder
        })
    };

    closeConfirmModal = () =>{
        this.setState({
            confirmationCode: '',
            isShowConfirmOrder: !this.state.isShowConfirmOrder
        })
    };

    confirm = () =>{
        const { requestOrderReference, confirmationCode } = this.state;
        if (this.validateConfirmationCode()) {
            this.setState({
                loading: true
            });
            OrderService.confirmOrder(requestOrderReference, confirmationCode)
            .then(response =>{
                if(response.status === "success"){
                    this.notify("success", "Success", "Your order has been confirmed successfully")
                    setTimeout(function(){ window.location.reload(); }, 1000);
                }else{
                    this.setState({
                        loading: false
                    });
                    this.notify("error", "An error occurred", response.message)
                }
            })
        }
    };

    rejectOrder = (order) => {
        this.setState({
            requestOrderReference: order.orderReference,
            isShowRejectOrder: !this.state.isShowRejectOrder
        })
    };

    closeRejectionModal = () =>{
        this.setState({
            rejectionReason: '',
            isShowRejectOrder: !this.state.isShowRejectOrder
        })
    }

    reject = () =>{
        const { requestOrderReference, rejectionReason } = this.state;

        if (this.validRejectionReason()) {
            this.setState({
                loading: true
            });
            OrderService.rejectOrder(requestOrderReference, rejectionReason)
            .then(response =>{
                if(response.status === "success"){
                    this.notify("success", "Success", "Order has been rejected successfully")
                    setTimeout(function(){ window.location.reload(); }, 1000);
                }else{
                    this.setState({
                        loading: false
                    });
                    this.notify("error", "An error occurred", response.message)
                }
            })
        }
    }

    getDeliveryStatusInt = (deliveryStatus) => {
        if(deliveryStatus === "NEW"){
            return 0
        }else if(deliveryStatus === "ENROUTE"){
            return 1
        }else if(deliveryStatus === "DELIVERED"){
            return 2
        }else if(deliveryStatus === "CONFIRMED"){
            return 3
        }else {
            return 4
        }
    };

    notify = (type, title, message) => {
        let notification = {
          type: type,
          title: title,
          message: message,
        };
        this.setState({ notification });
    };

    clearNotificationType = () => {
        this.setState({notification: {}})
    };

    render() {
        const { isShowOrderTrackerModal, orderItem, deliveryStatus, isShowConfirmOrder, isShowRejectOrder, loading } = this.state
        return(
            <div className="wrapper fadeInDown">
                <Notification notification={this.state.notification} clearNotificationType = {this.clearNotificationType} />

                {this.state.loading &&
                <div className="formContent" style={{zIndex: '9', background: 'rgba(255, 255, 255, 0.77)'}}>
                    <Loading />
                </div>}

                <div className="formContent">
                    {/* <Tabs Titles */}
                    <Row>
                        <Col span={24}>
                            {/* Icon */}
                            <div className="fadeIn first" style={{marginTop: 25}}>
                                <img src={logo} id="icon" alt="User Icon" style={{height: 30, width: 120}}/>
                            </div>
                        </Col>
                        <Col span={24} style={{marginTop:"20px"}}>
                            <h1 className="h2"> Order Tracker </h1>
                        </Col>
                    </Row>
                    <br></br>
                    <div className="fadeIn first">
                        <span>Enter your order reference below.</span>
                    </div>
                    <br></br>
                    <Form className="form">
                        <FormItem
                        rules={[{ required: true }]} hasFeedback>
                            <Input
                                name="orderReference" 
                                onChange={this.handleInputChange}
                                type="text"
                                placeholder={"Order reference"}
                                className="fadeIn second"
                                value = {this.state.orderReference}
                                autoComplete="off"
                            />
                        </FormItem>
                        
                        <input type="submit" className="fadeIn third" value="Search" onClick={this.submit}/>
                    </Form>
                </div>

                <Modal visible={isShowOrderTrackerModal}
                    width={1000}
                    title={`Order status for reference - ${orderItem.orderReference}`}
                    maskClosable={true}
                    onCancel={() => this.isShowOrderTrackerModal()}
                    confirmLoading={loading}
                    footer={[
                        <Button key="back" onClick={() => this.isShowOrderTrackerModal()} loading={loading}>
                            Close
                        </Button>,
                        <Popconfirm title="Are you sure you want to confirm this order?" okText="CONFIRM" disabled={deliveryStatus < 2 || deliveryStatus > 2}
                         onConfirm={() => this.confirmOrder(orderItem)}>
                            <Button key="confirm" loading={loading} disabled ={deliveryStatus < 2 || deliveryStatus > 2}> {/** enable only if order is ready to be confirmed / rejected */}
                                Confirm Order
                            </Button>
                        </Popconfirm>,
                        <Popconfirm title="Are you sure you want to reject this order?" okText="REJECT" disabled ={deliveryStatus < 2 || deliveryStatus > 2}
                         onConfirm={() => this.rejectOrder(orderItem)}>
                            <Button key="reject" loading={loading} disabled={deliveryStatus < 2 || deliveryStatus > 2}>  {/** enable only if order is ready to be confirmed / rejected */}
                                Reject Order
                            </Button>
                        </Popconfirm>,
                    ]}
                >
                    <Steps size="small" direction="vertical" current={deliveryStatus} status={deliveryStatus === 4 ? "error": "process"}
                      percent={deliveryStatus === 3 ? 100 : deliveryStatus * 25}
                     style={{marginTop: 100, marginBottom: 100}}>
                        <Step title="NEW" description="Order is yet to be processed"/>
                        <Step title="ENROUTE" description="Order is on the way!"/>
                        <Step title="DELIVERED" description="Delivered, waiting confirmation"/>
                        <Step title="CONFIRMED" description="Order has been confirmed"/>
                        <Step title={deliveryStatus === 4 ? "REJECTED" : "CANCELLED"}
                         description={deliveryStatus === 4 ? "Order was rejected" : "Order was cancelled"}/>
                    </Steps>
                </Modal>

                {/* confirm order modal */}
                <Modal visible={isShowConfirmOrder}
                    title="Confirm Order"
                    width={500}
                    onOk={this.confirm}
                    onCancel={this.closeConfirmModal} confirmLoading={loading}
                    maskClosable={true}
                    footer={[
                        <Button key="cancel" onClick={this.closeConfirmModal} loading={loading}>
                            Cancel
                        </Button>,
                        <Button key="confirm" type="primary" onClick={this.confirm} loading={loading}>
                            Confirm
                        </Button>,
                    ]}
                >
                    
                    <Input
                            name="confirmationCode" 
                            onChange={this.handleInputChange}
                            type="text"
                            placeholder={"Enter Your Confirmation Code"}
                            autoComplete="off"
                            value={this.state.confirmationCode}
                        />
                </Modal>

                {/* confirm order modal */}
                <Modal visible={isShowRejectOrder}
                    title="Rejection Reason"
                    width={500}
                    onOk={this.reject}
                    onCancel={this.closeRejectionModal} confirmLoading={loading}
                    maskClosable={true}
                    footer={[
                        <Button key="cancel" onClick={this.closeRejectionModal} loading={loading}>
                            Cancel
                        </Button>,
                        <Button key="reject" type="primary" onClick={this.reject} loading={loading}>
                            Reject
                        </Button>,
                    ]}
                >
                    <TextArea rows={3} name="rejectionReason"
                        value={this.state.rejectionReason}
                        placeholder={"Enter Rejection Reason"}
                        onChange={this.handleTextAreaChange}/>
                </Modal>

            </div>
        );
    }
}

export default CustomerView