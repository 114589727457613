import React, { Component }  from 'react';
import {Layout, Menu, Row, Col, Badge} from 'antd';
import {
    DashboardOutlined,
    WalletOutlined,
    ShoppingOutlined,
    ShoppingCartOutlined,
    FlagOutlined,
    UserOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import logo from '../../assets/Paydoor.png'
import Notification from '../../shared/components/notification';
import UtilService from '../../services/util.service'
import WalletService from '../../components/wallet/services/wallet.service'
import OrderService from "../../components/order/services/order.service";
import DisputeService from '../../components/dispute/services/dispute.service';

const { Sider } = Layout;
const { SubMenu } = Menu;

class Sidebar extends Component{

    componentDidMount() {
        this.getWalletsByUserId();
        this.getNewOrdersCount();
        this.getOpenDisputesCount();
    }

    getNewOrdersCount = () =>{
        let userId = UtilService.getUserId();
        WalletService.getWalletsByUserId(userId)
        .then(response => {
            if(response.id){
                OrderService.getNewOrdersCountByWalletId(response.id)
                .then(count => {
                    this.setState({
                        totalNewOrders: count
                    })
                })
            }
        })
    }

    getOpenDisputesCount = () =>{
        let userId = UtilService.getUserId();
        WalletService.getWalletsByUserId(userId)
        .then(response => {
            if(response.id){
                DisputeService.getOpenDisputeCountByWalletId(response.id)
                .then(count => {
                    this.setState({
                        totalOpenDisputes: count
                    })
                })
            }
        })
    }

    getWalletsByUserId = () => {
        let userId = UtilService.getUserId();
        WalletService.getWalletsByUserId(userId)
        .then(response => {
            if(response.id){
                this.setState({
                    userId: userId,
                    businessName: response.businessName,
                });
            }
        })
    };

    state = {
        collapsed: false,
        selectedMenuItem: "1",
        notification: {},
        userId: undefined,
        loading: false,
        totalNewOrders: undefined,
        totalOpenDisputes: undefined,
        businessName: undefined,
    };

    handleMenuClick = event => {
        this.setState({
            selectedMenuItem: event.key
        })
    };

    onCollapse = collapsed => {
        this.setState({ collapsed });
    };

    notify = (type, title, message) => {
        let notification = {
          type: type,
          title: title,
          message: message,
        };
        this.setState({ notification });
    };

    clearNotificationType = () => {
        this.setState({notification: {}})
    };

    render(){
        const {firstName, lastName} = this.props;
        const { collapsed, totalNewOrders, totalOpenDisputes, businessName } = this.state;

        return(
            <>
                <Notification notification={this.state.notification} clearNotificationType = {this.clearNotificationType} />
                <Row>
                    <Col xs={0} sm={0} md={24} style={{background: "#fff"}}>
                        <Sider collapsible collapsed={collapsed} onCollapse={this.onCollapse} theme="light" width="250" breakpoint={"lg"} className="side-bar-shadow">
                            
                            <div className="logo">
                                <img src={logo} style={{width: 95, height: 25}} alt=""/>
                            </div>
                            <br></br>
                            <Row>
                                <Col xs={0} sm={0} md={24} span={collapsed ? 24 : 24} style={{paddingLeft: 15, textAlign: "left"}}>
                                    <div style={{padding: 6}}>
                                        <span><b>{businessName}</b></span><br></br>
                                        <span style={{fontSize: 12}}>{firstName+" "+lastName}</span>
                                    </div>
                                </Col>

                                <Col md={0} lg={0} span={collapsed ? 24 : 24} style={{paddingLeft: 15, textAlign: "left"}}>
                                    <div>
                                        <div style={{fontSize: 10, fontWeight: "bold"}}>{businessName}</div>
                                        <div style={{fontSize: 8}}>{firstName+" "+lastName}</div>
                                    </div>
                                </Col>

                            </Row>
                            <br></br>
                            <Menu theme="light" mode="inline" >
                                <Menu.Item key="1" icon={<DashboardOutlined />} onClick={() => this.handleMenuClick}>
                                    <Link to="/dashboard"> Dashboard </Link>
                                </Menu.Item>

                                <Menu.Item key="2" icon={<WalletOutlined />} onClick={() => this.handleMenuClick}>
                                    <Link to="/wallet"> Wallet </Link>
                                </Menu.Item>

                                <Menu.Item key="5" icon={<ShoppingOutlined />} onClick={() => this.handleMenuClick}>
                                    <Link to="/products"> Products </Link>
                                </Menu.Item>

                                <Menu.Item key="6" icon={<ShoppingCartOutlined />} onClick={() => this.handleMenuClick}>
                                    <Link to="/orders"><Badge size="small" offset={[10, -3]} count={totalNewOrders}>Orders</Badge> </Link>
                                </Menu.Item>

                                <Menu.Item key="7" icon={<FlagOutlined />} onClick={() => this.handleMenuClick}>
                                    <Link to="/dispute"> <Badge size="small" offset={[10, -3]} count={totalOpenDisputes}>Disputes</Badge> </Link>
                                </Menu.Item>

                                <Menu.Item key="8" icon={<UserOutlined />} onClick={() => this.handleMenuClick}>
                                    <Link to="/settings"> Profile </Link>
                                </Menu.Item>
                            </Menu>
                        </Sider>
                    </Col>
                </Row>
            </>
        )
    }
}
export default Sidebar 