import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Input, Form } from 'antd'
import Notification from '../../shared/components/notification';
import { EyeOutlined, EyeInvisibleOutlined, CaretRightOutlined } from '@ant-design/icons'
import AuthService from './services/auth.service'
import UtilService from '../../services/util.service'
import Loading from '../../shared/components/loading/index'
import logo from '../../assets/Paydoor.png'
import './index.css'

const FormItem = Form.Item

class RegisterForm extends Component {

    state = {
        formData: {
            firstName: '',
            lastName: '',
            telNo: '',
            email: '',
            password: ''
        },
        showPassword: false,
        notification: {},
        loading: false,
        passwordIsStrong: false,
    };

    handleInputChange = (event) => {
        this.setState({
          formData: {
            ...this.state.formData,
            [event.currentTarget.name]: event.currentTarget.value
          }
        });
    };

    handlePasswordInputChange = (event) => {
        const passwordValidation = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^()=_+&*-]).{8,}$/
        if(passwordValidation.test(event.currentTarget.value)){
            this.setState({
                passwordIsStrong: true
            });
        }else{
            this.setState({
                passwordIsStrong: false
            });
        }
        this.handleInputChange(event);
    };

    submit = () => {
        if (this.validForm()) {
            this.setState({
                loading: true
            });
            const { formData } = this.state;
            
            AuthService.signUpMerchant(formData)
            .then(response => {
                if(response.access_token){
                    UtilService.setSession(response.access_token, response.email, response.role[0].authority)
                    window.location.href = "/dashboard"
                }else{
                    this.setState({
                        loading: false
                    });
                    this.notify("error", response.error, response.message)
                }
            })
        }
    };

    validForm = () => {
        const {formData} = this.state;
        const emailValidation = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const passwordValidation = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^()=_+&*-]).{8,}$/

        if(formData.firstName === undefined || formData.firstName === "") {
            this.notify("error", "Unable to process request", "First Name is required")
            return false;
        }
        if(formData.lastName === undefined || formData.lastName === "") {
            this.notify("error", "Unable to process request", "Last Name is required")
            return false;
        }
        if(formData.telNo === undefined || formData.telNo === "") {
            this.notify("error", "Unable to process request", "Phone is required")
            return false;
        } else {
            if(isNaN(formData.telNo) || formData.telNo.length !== 11 ) {
                this.notify("error", "Unable to process request", "Phone number should be 11 digits")
                return false;
            }
        }
        if(formData.email === undefined || formData.email === "") {
            this.notify("error", "Unable to process request", "Email is required");
            return false;
        }else {
            if(!emailValidation.test(formData.email)){
                this.notify("error", "Unable to process request", "Invalid Email Supplied");
                return false;
            }
        }
        if(formData.password === undefined || formData.password === "") {
            this.notify("error", "Unable to process request", "Password is required")
            return false;
        }else{
            if(!passwordValidation.test(formData.password)){
                this.notify("error", "Unable to process request", "Password does not meet the required citeria");
                return false;
            }
        }

        return true;
    };

    notify = (type, title, message) => {
        let notification = {
          type: type,
          title: title,
          message: message,
        };
        this.setState({ notification });
    };

    clearNotificationType = () => {
        this.setState({notification: {}})
    };

    showPassword = () => {
        this.setState({
            showPassword: !this.state.showPassword
        })
    };

    render() {

        const {passwordIsStrong, formData} = this.state;

        return(
            <div className="wrapper fadeInDown">
                <Notification notification={this.state.notification} clearNotificationType = {this.clearNotificationType} />

                {this.state.loading &&
                <div id="formContent" style={{zIndex: '9', background: 'rgba(255, 255, 255, 0.77)'}}>
                     <Loading />
                </div>}
                <div id="formContent">
                    {/* Icon */}
                    <div className="fadeIn first" style={{marginTop: 25}}>
                        <img src={logo} id="icon" alt="User Icon" style={{height: 30, width: 120}}/>
                    </div>

                    {/* <Tabs Titles */}
                    <Link to="/login"><h2 className="inactive underlineHover h2"> Sign In </h2></Link>
                    <h2 className="active h2">Sign Up</h2>

                    <div>
                        <Form className="form">
                            <FormItem validateStatus={this.state.showFormError ? "error" : ""}
                            rules={[{ required: true }]} hasFeedback>
                                <Input
                                    name="firstName"
                                    onChange={this.handleInputChange}
                                    type="text"
                                    placeholder="First Name"
                                    className="fadeIn second"
                                    value = {this.state.formData.firstName}
                                />
                            </FormItem>
                            <FormItem
                            rules={[{ required: true }]} hasFeedback>
                                <Input
                                    name="lastName"
                                    onChange={this.handleInputChange}
                                    type="text"
                                    placeholder="Last Name"
                                    className="fadeIn second"
                                    value = {this.state.formData.lastName}
                                />
                            </FormItem>
                            <FormItem
                            rules={[{ required: true }]} hasFeedback>
                                <Input
                                    name="telNo"
                                    onChange={this.handleInputChange}
                                    type="text"
                                    pattern="\d*"
                                    placeholder="Enter your 11 digits phone number"
                                    className="fadeIn third"
                                    value = {this.state.formData.telNo}
                                    maxLength="11"
                                />
                            </FormItem>
                            <FormItem
                            rules={[{ required: true }]} hasFeedback>
                                <Input
                                    name="email"
                                    onChange={this.handleInputChange}
                                    type="email"
                                    placeholder="Email"
                                    className="fadeIn third"
                                    value = {this.state.formData.email}
                                />
                            </FormItem>
                            {formData.password !== '' && passwordIsStrong && <span style={{color:"green"}}>{"Password is strong"}</span>}
                            {formData.password !== '' && !passwordIsStrong &&<span style={{color:"red"}}>{"Password is weak"}</span>}
                            <FormItem
                            rules={[{ required: true }]} hasFeedback>
                                <Input
                                    name="password"
                                    onChange={this.handlePasswordInputChange}
                                    type={this.state.showPassword ? "text" : "password"}
                                    placeholder="Password"
                                    className="fadeIn fourth"
                                    value = {this.state.formData.password}
                                    autoComplete="off"
                                />
                                <div className="fadeIn fourth">
                                    {this.state.showPassword ? <EyeOutlined className="showPassword" onClick={this.showPassword} /> :
                                    <EyeInvisibleOutlined className="showPassword" onClick={this.showPassword}/>}
                                </div>

                                
                                <div style={{marginLeft:"20%", textAlign:"left"}}>
                                <br></br>
                                {formData.password !== '' && !passwordIsStrong &&
                                    <div>
                                        <CaretRightOutlined/> Password must contain minimum 8 characters<br></br>
                                        <CaretRightOutlined/> At least 1 uppercase letter<br></br>
                                        <CaretRightOutlined/> At least 1 lowercase letter<br></br>
                                        <CaretRightOutlined/> At least 1 number<br></br>
                                        <CaretRightOutlined/> And 1 special character
                                    </div>
                                }
                                </div>

                            </FormItem>
                            <input type="submit" className="fadeIn fourth" value="Sign up" onClick={this.submit}/>
                        </Form>
                    </div>
                    <div id="formFooter">
                        <font className="terms" style={{fontSize: 12}}>
                            By signing up, you agree to Paydoor <a href="https://www.paydoor.co/terms.html" style={{fontSize: 12}}>
                            Terms of Service</a> and <a href="https://www.paydoor.co/privacy.html" style={{fontSize: 12}}>
                            Privacy Policy.</a>
                        </font>
                    </div>

                </div>
            </div>
        );
    }
}

export default RegisterForm