import React, { Component }  from 'react';
import { Line } from '@ant-design/charts';
import { Col, Empty } from 'antd';


class Chart extends Component {
    render(){
        const { data } = this.props

        // const data = [
        //     {
        //         day: "March 1",
        //         order: 1
        //     },
        //     {
        //         day: "March 2",
        //         order: 6
        //     },
        //     {
        //         day: "March 3",
        //         order: 8
        //     },
        //     {
        //         day: "March 4",
        //         order: 12
        //     }
        // ]

        

    
        const config = {
            data,
            height: 400,
            xField: 'day',
            yField: 'order',
            point: {
            size: 5,
            shape: 'diamond',
            },
            label: {
                style: {
                    fill: '#aaa',
                },
            },
        };

        return(
            <div style={{background: "#fff", padding: 30, boxShadow: "0 1px 9px -3px rgba(0, 0, 0, 0.2)"}}>
                <div>
                    <h4>Monthly Growth Statistics</h4>
                </div>
                <br/>
                
                {data.length === 0 &&
                <Col xs={24} sm={24} md={24} xl={24}>
                    <div className="" style={{background: "#fff", padding:150, paddingTop: 150, height: "500px"}}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                </Col>}

                {data.length > 0 &&
                <div>
                    <Line {...config} />
                </div>}
            </div>
        )
    }
}

export default Chart;