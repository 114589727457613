import React, { Component } from 'react'
import {Button, Result} from 'antd';
import {Link} from "react-router-dom";

class ConfirmationFailed extends Component{

    render(){
        return(
            <Result
                status="error"
                title="Unable to complete request"
                subTitle={`${this.props.match.params.errorMessage}`}
                style={{paddingTop: "25vh"}}
                extra={[
                    <Button type="primary" shape="round" size={"large"} style={{border: "none"}}>
                        <Link to="/login">Login</Link>
                    </Button>
                ]}
            />
    )
    }
}

export default ConfirmationFailed
