import React, {Component} from 'react';
import { Spin } from 'antd';
import './index.css'

class Loading extends Component{
    state = {
        background: this.props.background
    };

    render(){
        const { background } = this.state;
        return(
            <div className="box" style={{background: background}}>
                <div className="spin" style={{justifyContent: 'center', alignItems: 'center'}}>
                    <Spin tip={"Please wait..."}/>
                </div>
            </div>
        )
    }
}

export default Loading
