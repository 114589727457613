import React, { Component }  from 'react';
import {Drawer, Row, Col, Input, Form, Button, Tag, Modal} from 'antd';
import {
    SwapOutlined,
    CreditCardOutlined,
    StarOutlined
} from '@ant-design/icons';
import WalletService from '../../../components/wallet/services/wallet.service';
import Notification from '../notification';
import UtilService from '../../../services/util.service';
import OrderService from '../../../components/order/services/order.service';

class WalletWidget extends Component{

    componentDidMount(){
        this.getWalletBalance();
    }

    state = {
        formData: {
            destination: undefined,
            amount: undefined,
            password: undefined,
            otp: undefined,
        },
        notification: {},
        balance: 0,
        merchantDetails: {
            accountNumber: undefined,
            bankName: undefined
        },
        currency: undefined,
        userId: undefined,
        loading: false,
        merchantPhone: undefined,
        showOtpModal: false,
        performAction: undefined,
        businessName: undefined,
        visible: undefined,
    };

    getWalletBalance = () => {
        let userId = UtilService.getUserId();
        WalletService.getWalletsByUserId(userId)
        .then(response => {
            if(response.id){
                if(UtilService.getBank() === 'undefined' || UtilService.getBank() === null){
                    this.getBankByCode(response.bankName);
                }
                this.setState({
                    merchantDetails: {
                        accountNumber: response.accountNumber,
                    },
                    currency: response.currency,
                    userId: userId,
                    businessName: response.businessName,
                }, () => {
                    this.showWidget();
                });
                let formData = {
                    userId: this.state.userId,
                    currency: this.state.currency
                };
                WalletService.getWalletBalance(formData)
                .then(response => {
                    if(response.balance !== undefined){
                        this.setState({
                            balance: response.balance
                        })
                    }else{
                        this.notify("error", "An error occurred", "Unable to retrieve wallet details at the moment.")
                    }
                })
            }else{
                this.notify("error", "Unable to process request", "Kindly create a wallet to proceed")
            }
        })
    };

    getBankByCode = (code) => {
        OrderService.getBankByCode(code)
            .then(response => {
                UtilService.setBank(response.name);
            })
    };

    showWidget = () =>{
        const { action } = this.props;
        
        if("Transfer" === action){
            this.setState({
                formData: {}
            })
        }else if("Withdraw" === action){
            this.setState({
                formData: {}
            })
        }
    }

    validateTransferRequest = () => {
        const { formData, currency, userId } = this.state;
        formData.currency = currency;
        formData.source = userId;

        if(this.validateForm(formData.destination, "transfer")) {
            this.setState({
                loading: true
            });
            WalletService.validateTransferRequest(formData)
                .then(response =>{
                    if(response.status === "success"){
                        this.setState({
                            merchantPhone: response.message.replace(/.(?=.{4,}$)/g, '#'),
                            showOtpModal: true,
                            loading: false,
                            performAction: "transfer"
                        })
                    }else {
                        this.setState({loading: false});
                        this.notify("error", "Unable to complete request", response.message);
                    }
                });
        }
    };

    validateWithdrawalRequest = () => {
        const { formData, currency, userId } = this.state;
        formData.currency = currency;
        formData.source = userId;
        formData.destination = "DESTINATION";

        if(this.validateForm(formData.destination, "withdraw")) {
            this.setState({
                loading: true
            });
            WalletService.validateWithdrawalRequest(formData)
                .then(response =>{
                    if(response.status === "success"){
                        this.setState({
                            merchantPhone: response.message.replace(/.(?=.{4,}$)/g, '#'),
                            showOtpModal: true,
                            loading: false,
                            performAction: "withdraw"
                        })
                    }else {
                        this.setState({loading: false});
                        this.notify("error", "Unable to complete request", response.message);
                    }
                });
        }
    };

    transfer = () => {
        const { formData, currency, userId } = this.state;
        formData.currency = currency;
        formData.source = userId;

        this.setState({
            loading: true
        });
        WalletService.doTransfer(formData)
            .then(response => {
                if(response.status === "SUCCESS"){
                    this.notify("success", "Success", "Transfer successful");
                    setTimeout(function(){ window.location.href = "/wallet"; }, 2000);
                }else{
                    this.setState({
                        loading: false
                    });
                    this.notify("error", "Unable to complete request", response.message)
                    setTimeout(function(){ window.location.href = "/wallet"; }, 2000);
                }
            })
    };

    withdraw = () => {
        const { formData, currency, userId } = this.state;
        formData.currency = currency;
        formData.source = userId;

        this.setState({
            loading: true
        });
        WalletService.withdraw(formData)
            .then(response => {
                if(response.status === "SUCCESS"){
                    this.notify("success", "Success", "Withdrawal request has been initiated successfully")
                    setTimeout(function(){ window.location.href = "/wallet"; }, 2000);
                }else{
                    this.setState({
                        loading: false
                    });
                    this.notify("error", "Unable to complete request", response.message)
                    setTimeout(function(){ window.location.href = "/wallet"; }, 2000);
                }
            })
    };

    validateForm = (destination, type) => {
        const {formData, currency, userId } = this.state;
        const emailValidation = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(formData.destination === undefined || formData.destination === "") {
            this.notify("error", "Unable to process request", "Kindly specify destination wallet ID")
            return false;
        }else {
            if(type === "transfer" && !emailValidation.test(destination)){
                this.notify("error", "Unable to process request", "Invalid Merchant ID Supplied");
                return false;
            }
        }
        if(formData.amount === undefined || formData.amount === "") {
            this.notify("error", "Unable to process request", "Amount is required");
            return false;
        }else if(type === "transfer" && formData.amount < 1){
            this.notify("error", "Unable to process request", "Amount must be greater than 0");
            return false;
        }
        else if(type === "withdraw" && formData.amount < 100){
            this.notify("error", "Unable to process request", "Minimum withdrawal amount is N100");
            return false;
        }
        else if(isNaN(formData.amount)){
            this.notify("error", "Unable to process request", "Invalid Amount Supplied")
            return false;
        }
        if(formData.password === undefined || formData.password === "") {
            this.notify("error", "Unable to process request", "Password is required");
            return false;
        }
        if(currency === undefined || currency === "" || userId === undefined || userId === "") {
            this.notify("error", "An error occurred", "Unable to process request at the moment. Please try again")
            return false;
        }
        return true;
    };

    handleInputChange = (e) => {
        const { name, value} = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        })
    };

    closeOtpModal = () => {
        this.setState({
            showOtpModal: !this.state.showOtpModal,
            formData: {
                ...this.state.formData,
                otp: undefined
            }
        });
    };

    notify = (type, title, message) => {
        let notification = {
          type: type,
          title: title,
          message: message,
        };
        this.setState({ notification });
    };

    clearNotificationType = () => {
        this.setState({notification: {}})
    };

    render() {
        const {formData, balance, merchantDetails, loading, showOtpModal, performAction} = this.state;
        const {action, isVisible} = this.props;
        return (
            <>
                <Notification notification={this.state.notification} clearNotificationType = {this.clearNotificationType} />
                    <Row className="sidenav-overlay">
                        <Col className="transferContainer">
                            <Drawer title="Transfer Widget" width={350} placement="right" onClose={this.props.isHideDrawer} visible={isVisible && "Transfer" === action}>
                                <div style={{paddingTop: "20vh", padding: 20}}>
                                    <h3 align="center">Enter Transaction Details Below</h3><br></br>
                                    <div style={{paddingBottom: 30}} align="center">
                                        <Tag color="#FE9C96" style={{fontSize: 16, padding: 8, paddingLeft: 13, paddingRight: 13, borderRadius: 20}}><CreditCardOutlined />
                                            &nbsp; Balance - N{balance === 0 ? "0.00" : balance.toLocaleString()}
                                        </Tag>
                                    </div>
                                    <Form>
                                        <Form.Item>
                                            <label>Destination Wallet ID:</label> <span style={{color: "red"}}>*</span>
                                            <Input name="destination" placeholder="Destination Wallet ID" value={formData.destination} onChange={this.handleInputChange} />
                                        </Form.Item>
                                        <Form.Item>
                                            <label>Amount:</label> <span style={{color: "red"}}>*</span>
                                            <Input name="amount" placeholder="Amount"
                                                value={formData.amount} onChange={this.handleInputChange} />
                                        </Form.Item>
                                        <Form.Item >
                                            <label>Password:</label> <span style={{color: "red"}}>*</span>
                                            <Input name="password" placeholder="Enter password" type="password"
                                                value={formData.password} onChange={this.handleInputChange} />
                                        </Form.Item>
                                        <Button type="primary" shape="round" icon={<SwapOutlined />} size={"medium"} onClick={this.validateTransferRequest}
                                            style={{float: "right", background: "#65D6C9", border: "none", marginBottom: 20}} loading={loading}>
                                            Make a Transfer
                                        </Button>
                                    </Form>
                                </div>
                            </Drawer>
                        </Col>
                    </Row>
                
                    <Row className="sidenav-overlay">
                        <Col className="transferContainer">
                            <Drawer title="Withdraw Widget" width={350} placement="right" onClose={this.props.isHideDrawer} visible={isVisible && "Withdraw" === action}>
                                <div style={{paddingTop: "20vh", padding: 20}}>
                                    <h3 align="center" >Enter Withdrawal Details Below</h3><br></br>
                                    <div style={{paddingBottom: 30}} align="center">
                                        <Tag color="#FE9C96" style={{fontSize: 16, padding: 8, paddingLeft: 13, paddingRight: 13, borderRadius: 20}}><CreditCardOutlined />
                                            &nbsp; Balance - N{balance === 0 ? "0.00" : balance.toLocaleString()}
                                        </Tag>
                                    </div>
                                    <Form>
                                        <Form.Item style={{fontSize: 12}}>
                                            { merchantDetails.accountNumber !== undefined &&
                                                <span><StarOutlined /> &nbsp; Funds will be sent to your {UtilService.getBank()} ({merchantDetails.accountNumber})</span>}
                                        </Form.Item>
                                        <Form.Item>
                                            <label>Amount (min - N100):</label> <span style={{color: "red"}}>*</span>
                                            <Input name="amount" placeholder="Amount"
                                                value={formData.amount} onChange={this.handleInputChange} />
                                        </Form.Item>
                                        <Form.Item >
                                            <label>Password:</label> <span style={{color: "red"}}>*</span>
                                            <Input name="password" placeholder="Enter password" type="password"
                                                value={formData.password} onChange={this.handleInputChange} />
                                        </Form.Item>
                                        <Button type="primary" shape="round" icon={<SwapOutlined />} size={"medium"} onClick={this.validateWithdrawalRequest}
                                            style={{float: "right", background: "#65D6C9", border: "none", marginBottom: 20}} loading={loading}>
                                            Withdraw to bank account
                                        </Button>
                                    </Form>
                                </div>
                            </Drawer>
                        </Col>
                    </Row>
                
                <Modal visible={showOtpModal}
                    width={300}
                    onOk={() => { performAction === "transfer" ? this.transfer() : this.withdraw()}}
                    onCancel={this.closeOtpModal}
                    maskClosable={true} confirmLoading={loading}
                    centered
                    footer={[
                        <Button key="cancel" onClick={this.closeOtpModal} loading={loading}>
                            Cancel
                        </Button>,
                        <Button key="proceed" type="primary" loading={loading}
                                onClick={() => { performAction === "transfer" ? this.transfer() : this.withdraw()}}>
                            Proceed
                        </Button>,
                    ]}
                >
                    <h2 style={{color: "#1890FF", textAlign: "center"}}>Enter OTP</h2>
                    {/* <p style={{fontSizs: 10}}>We have just sent a 6-digit code to your registered phone number ({merchantPhone}).
                        <br/>Valid for 5minutes
                    </p> */}
                    <p style={{fontSizs: 10}}>We have just sent a 6-digit code to your mail {UtilService.getUserId()}.
                        <br/>Valid for 5minutes
                    </p>

                    <Input name="otp" placeholder="Enter valid OTP..." value={formData.otp} onChange={this.handleInputChange} />
                </Modal>
            </>
        )
    }
}
export default WalletWidget