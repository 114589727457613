import React, { Component }  from 'react';
import { Button, Modal, Input, Form, Select, Divider, Col } from 'antd';
import Notification from '../../shared/components/notification';
import { PlusOutlined } from '@ant-design/icons';
import ProductService from './services/product.service'
import WalletService from '../wallet/services/wallet.service'
import UtilService from '../../services/util.service'

const { TextArea } = Input;
const { Option } = Select;

class CreateProduct extends Component{

    componentDidMount(){
        this.getAllCategories()
    }

    state={
        formData: {
            productName: undefined,
            price: undefined,
            deliveryDate: undefined,
            discount: 0,
            mediaLink: undefined,
            description: undefined,
            categoryId: undefined,
            quantity: 1
        },
        categories: [],
        isModalVisible: false,
        setConfirmLoading: false,
        notification: {},
        deliveryDateList: ['Same day', '1 - 3 days', '3 - 5 days', '5 - 7 days', '7 - 14 days'],
        deliveryDateItem: '',
        selectedImage: '',
    };

    getAllCategories = () =>{
        ProductService.getAllCategories()
        .then(response => {
            this.setState({
                categories: response
            })
        })
    }

    showModal = () => {
        this.setState({
            isModalVisible: true
        })
    };

    handleCancel = () => {
        this.setState({
            isModalVisible: false,
            selectedImage: ''
        });
        this.clearForm();
    };
    
    clearForm = () => {
        this.setState({
            formData: {
                productName: undefined,
                price: undefined,
                deliveryDate: undefined,
                discount: 0,
                mediaLink: undefined,
                description: undefined,
                quantity: 1,
                categoryId: undefined
            }
        })
    };

    handleOk = () => {
        if(this.validateForm()) {
            this.setState({
                setConfirmLoading: true
            });
            const {formData} = this.state;

            const formData2 = new FormData();
            for (const key in formData) {
                if(key !== "mediaLink"){
                    formData2.append(key, formData[key]);
                }else if(key === "mediaLink" && formData.mediaLink !== undefined){
                    formData2.append(key, formData[key]);
                }else if(key === "mediaLink" && formData.mediaLink === undefined){
                }
            }

            let userId = UtilService.getUserId();
            WalletService.getWalletsByUserId(userId)
            .then(response => {
                if(response.id){
                    ProductService.generateProductLink(formData2, response.id)
                    .then(response => {
                        if(response.status === "success"){
                            this.setState({
                                isModalVisible: false,
                                setConfirmLoading: false,
                            });
                            this.notify("success", "Success", "Successfully generated");
                            setTimeout(function(){ window.location.reload(); }, 2000);
                        }else{
                            this.setState({
                                setConfirmLoading: false
                            });
                            this.notify("error", "Unable to process request", response.message)
                        }
                    })
                }else{
                    this.notify("error", "Unable to process request", "Kindly create a wallet to proceed")
                }
            })
            

            // this.setState({setConfirmLoading: true})
            // call endpoint then set loading false
        }
    };
    
    validateForm = () => {
        const {formData} = this.state;

        if(formData.productName === undefined || formData.productName === "") {
            this.notify("error", "Unable to process request", "Product name is required")
            return false;
        }
        if(formData.categoryId === undefined || formData.categoryId === "") {
            this.notify("error", "Unable to process request", "Please provide a category")
            return false;
        }
        if(formData.price === undefined || formData.price === "") {
            this.notify("error", "Unable to process request", "Price is required")
            return false;
        }
        if(isNaN(formData.price) || formData.price <= 0) {
            this.notify("error", "Invalid price supplied", "Price should be a numeric value greater than 0")
            return false;
        }
        if(formData.quantity === undefined || formData.quantity === "") {
            this.notify("error", "Unable to process request", "Quantity should be minimum of 1")
            return false;
        }
        if(isNaN(formData.quantity) || formData.quantity <= 0) {
            this.notify("error", "Unable to process request", "Quantity should be minimum of 1")
            return false;
        }
        if(isNaN(formData.discount) || formData.discount < 0) {
            this.notify("error", "Invalid discount supplied", "Discount should be a numeric value not less than 0")
            return false;
        }
        if(formData.discount > 99) {
            this.notify("error", "Unable to process request", "Discount can not be 100% or greater")
            return false;
        }
        if(formData.deliveryDate === undefined || formData.deliveryDate === "") {
            this.notify("error", "Unable to process request", "Delivery date is required")
            return false;
        }
        if(formData.mediaLink && formData.mediaLink.length > 250) {
            this.notify("error", "Unable to process request", "Media link should be maximum of 250 characters")
            return false;
        }
        if(formData.description === undefined || formData.description === "") {
            this.notify("error", "Unable to process request", "Description is required")
            return false;
        }
        if(formData.description.length > 500) {
            this.notify("error", "Unable to process request", "Description should be maximum of 500 characters")
            return false;
        }
        return true;
    };

    notify = (type, title, message) => {
        let notification = {
          type: type,
          title: title,
          message: message,
        };
        this.setState({ notification });
    };

    clearNotificationType = () => {
        this.setState({notification: {}})
    };

    handleInputChange = (e) => {
        const { name, value} = e.target
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        })
    };

    handleFileUploadChange = (e) => {
        const file = e.target.files[0]; 

         //to display the file in the <img> tag
         const reader = new FileReader();
         reader.onloadend = () => {
             this.setState({ selectedImage: reader.result });
         };
         if (file) {
             reader.readAsDataURL(file);
         }
         //to display the file in the <img> tag

        this.setState({
            formData: {
                ...this.state.formData,
                mediaLink: file,
            },
        });
    };


    handlePriceChange = (e) => {
        const { name, value} = e.target
        let formattedValue = value.split(',').join('')
        this.setState({ 
            formData: {
                ...this.state.formData,
                [name]: formattedValue
            }
        });
    };

    handleTextAreaChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                description: e.target.value
            }
        })
    };

    handleDeliveryDateChange = value => {
        this.setState({
            formData : {
                ...this.state.formData,
                deliveryDate: value
            }
        })
    };

    handleSelectChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                categoryId: e,
            }
        })
    };

    onDeliveryDateChange = event => {
        this.setState({
          deliveryDateItem: event.target.value,
        });
    };

    addItem = () => {
        const { deliveryDateList, deliveryDateItem } = this.state;
        if(deliveryDateItem.length > 0){
            this.setState({
                deliveryDateList: [...deliveryDateList, deliveryDateItem],
                deliveryDateItem: '',
              });
        }
    };

    render() {

        const { isModalVisible, setConfirmLoading, formData, deliveryDateList, deliveryDateItem, selectedImage} = this.state;

        //Try to fix this function, it also adds (,) after decimal
        let formattedPrice = formData.price === undefined ? '' : formData.price.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        return (
            <>
                <Notification notification={this.state.notification} clearNotificationType = {this.clearNotificationType} />
                <Col xs={0} sm={0} md={24} span={24}> 
                    <Button type="primary" shape="round" size={"medium"} icon={<PlusOutlined />} onClick={this.showModal}
                            style={{float: "right", marginBottom: 20, background: "#65D6C9", border: "none"}}>
                        Generate Product
                    </Button>
                </Col>
                <Col md={0} lg={0} span={24}>
                    <Button type="primary" shape="round" size={"small"} icon={<PlusOutlined />} onClick={this.showModal}
                            style={{float: "right", margin: 20, background: "#65D6C9", border: "none"}}>
                        Create Product
                    </Button>
                </Col>
    
                <Modal visible={isModalVisible}
                    width={500}
                    title="Generate Product"
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    maskClosable={true}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                        Cancel
                        </Button>,
                        <Button key="submit" type="primary" loading={setConfirmLoading} onClick={this.handleOk}>
                        Generate
                        </Button>,
                    ]}
                >
                    <Form>
                        <Form.Item>
                            <label>Product Name:</label> <span style={{color: "red"}}>*</span>
                            <Input name="productName" placeholder="Product Name"
                                value={formData.productName} onChange={this.handleInputChange} />
                        </Form.Item>
                        <Form.Item>
                            <label>Category:</label> <span style={{color: "red"}}>*</span>
                            <Select onChange={this.handleSelectChange} placeholder="Select Category" value={formData.categoryId}>
                                {this.state.categories.map((option) =>
                                <Option key={option.name} value={option.id}>
                                    {option.name}
                                </Option>
                                )}
                            </Select> 
                        </Form.Item>
                        <Form.Item>
                            <label>Price (NGN):</label> <span style={{color: "red"}}>*</span>
                            <Input name="price" placeholder="Price"
                                value={formattedPrice} onChange={this.handlePriceChange} />
                        </Form.Item> 
                        <Form.Item>
                            <label>Quantity:</label>
                            <Input name="quantity" placeholder="Quantity" type='number' min="1"
                                value={formData.quantity} onChange={this.handleInputChange} />
                        </Form.Item> 
                        <Form.Item>
                            <label>Estimated Delivery Date:</label> <span style={{color: "red"}}>*</span>
                            <Select
                                style={{ width: '100%' }}
                                placeholder="Select delivery date"
                                onChange={this.handleDeliveryDateChange}
                                value={formData.deliveryDate}
                                dropdownRender={menu => (
                                <div>
                                    {menu}
                                    <Divider style={{ margin: '4px 0' }} />
                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                    <Input style={{ flex: 'auto' }} value={deliveryDateItem}
                                    onChange={this.onDeliveryDateChange} placeholder="E.g. 1 month, 3 weeks"/>
                                    <a  href="#"
                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                        onClick={this.addItem}
                                    >
                                        <PlusOutlined /> Add item
                                    </a>
                                    </div>
                                </div>
                                )}
                            >
                                {deliveryDateList.map(date => (
                                <Option key={date}>{date}</Option>
                                ))}
                            </Select>
                            {/* <DateInput
                                name="deliveryDate"
                                onChange={this.handleDateChange}
                                value={formData.deliveryDate}
                                format="YYYY-MM-DD"
                                disabled={false}
                            /> */}
                        </Form.Item>  
                        <Form.Item >
                            <label>Discount(%):</label>
                            <Input name="discount" placeholder="discount (%)" min="0" type="number"
                                value={formData.discount} onChange={this.handleInputChange} />
                        </Form.Item>  

                        {selectedImage != '' && <Form.Item>
                            <img
                                src={selectedImage}
                                alt="image"
                                width="100%"
                            />
                        </Form.Item>}

                        <Form.Item>
                            <label>Media Link: (Optional)</label>
                            <Input name="mediaLink" type='file' onChange={this.handleFileUploadChange} />
                        </Form.Item>  
                        <Form.Item>
                            <label>Detailed Description (max 500 characters):</label> <span style={{color: "red"}}>*</span>
                            <TextArea name="description" placeholder="Please be detailed as possible" value={formData.description} onChange={this.handleTextAreaChange} />
                        </Form.Item>                         
                    </Form>
                </Modal>
            </>   
        )
    }
}
export default CreateProduct