import React, {Component} from 'react';
import './App.css';
import { Redirect, BrowserRouter, Switch, Route } from 'react-router-dom';
import { PrivateRoute } from './private-route';
import LoginForm from './components/login/loginForm';
import RegisterForm from './components/login/registerForm';
import Dashboard from './components/dashboard/index';
import Wallet from './components/wallet/index';
import Product from './components/product/index';
import Order from './components/order/index';
import Dispute from './components/dispute/index';
import Settings from './components/settings/index';
import ForgotPassword from './components/login/forgotPassword/index';
import ResetPassword from './components/login/forgotPassword/reset-password';
import ProductView from './components/product/productView/index';
import NotFoundPage from './components/error/index';
import successPage from './components/product/productView/success';
import CustomerView from './components/customerView/index';
import ConfirmationSuccessful from "./components/success/ConfirmationSuccessful";
import ConfirmationFailed from "./components/error/ConfirmationFailed";
import StoreFront from "./components/storeFront/index"
import Cart from "./components/storeFront/cart"
import CheckoutComponent from "./components/storeFront/checkout"


// import { connect } from 'react-redux';

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    render() {
        return (
            <div className="Body">
                <BrowserRouter>
                    <Switch>
                        {/* ROUTE PATH THAT ARE PUBLIC */}

                        <Route  exact path="/login" component={LoginForm} />
                        <Route  exact path="/register" component={RegisterForm} />
                        <Route  exact path="/forgot-password" component={ForgotPassword} />
                        <Route  exact path="/product/reference/:reference" component={ProductView} />
                        <Route  exact path="/order/success/:reference" component={successPage} />
                        <Route  exact path="/reset-password/token/:token" component={ResetPassword} />
                        <Route  exact path="/order-tracker" component={CustomerView} />
                        <Route  exact path="/confirmation-successful" component={ConfirmationSuccessful} />
                        <Route  exact path="/confirmation-failed/:errorMessage" component={ConfirmationFailed} />
                        <Route  exact path="/store/:reference" component={StoreFront} />
                        <Route  exact path="/user/cart" component={Cart} />
                        <Route  exact path="/user/cart/checkout" component={CheckoutComponent} />

                        {/* ROUTE PATH THAT ARE PRIVATE */}

                        <PrivateRoute  exact path="/" component={Dashboard} />
                        <PrivateRoute  exact path="/dashboard" component={Dashboard} />
                        <PrivateRoute  exact path="/wallet" component={Wallet} />
                        <PrivateRoute  exact path="/products" component={Product} />
                        <PrivateRoute  exact path="/orders" component={Order} />
                        <PrivateRoute  exact path="/dispute" component={Dispute} />
                        <PrivateRoute  exact path="/settings" component={Settings} />

                        <Route path="/404" component={NotFoundPage} />
                        <Redirect to="/404" />

                    </Switch>
                </BrowserRouter>
            </div>
        );
    }
}

export default App;