import React, { Component } from 'react'
import {Button, Result} from 'antd';
import {Link} from "react-router-dom";

class ConfirmationFailed extends Component{

    render(){
        return(
            <Result
                status="success"
                title="Email Confirmed Successfully"
                subTitle={`Proceed to login below`}
                style={{paddingTop: "25vh"}}
                extra={[
                    <Button type="primary" shape="round" size={"large"} style={{border: "none"}} >
                        <Link to="/login">Login</Link>
                    </Button>
                ]}
            />
       )
    }
}

export default ConfirmationFailed
