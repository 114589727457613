import UrlService from '../../services/url.service'
import UtilService from '../../services/util.service'

const request = {};
const bearer = "Bearer ";

request.make = (ops) => {
  let url;
  if (ops.repo === "USER-MANAGEMENT") {
    url = UrlService.USER_MGT_BASE_PATH + ops.url;
  } else if (ops.repo === "WALLET_CURRENCY") {
    url = UrlService.WALLET_SERVICE_BASE_PATH_CURRENCY + ops.url;
  }else if (ops.repo === "WALLET_WALLET") {
    url = UrlService.WALLET_SERVICE_BASE_PATH_WALLET + ops.url;
  }else if (ops.repo === "WALLET_TRANSACTION") {
    url = UrlService.WALLET_SERVICE_BASE_PATH_TRANSACTIONS + ops.url;
  } else if (ops.repo === "ESCROW_PRODUCT") {
    url = UrlService.ESCROW_SERVICE_PRODUCT_BASE_PATH + ops.url;
  }else if (ops.repo === "ESCROW_ORDERS") {
    url = UrlService.ESCROW_SERVICE_ORDER_DETAILS_BASE_PATH + ops.url;
  }else if (ops.repo === "ESCROW_GET_BANK") {
    url = UrlService.ESCROW_SERVICE_BANK_BASE_PATH + ops.url;
  }else if (ops.repo === "ESCROW_DASHBOARD") {
    url = UrlService.ESCROW_SERVICE_DASHBOARD_PATH + ops.url;
  }else if (ops.repo === "ESCROW_DISPUTE") {
    url = UrlService.ESCROW_SERVICE_DISPUTE_PATH + ops.url;
  }else if (ops.repo === "ESCROW_OTP") {
    url = UrlService.ESCROW_SERVICE_OTP_PATH + ops.url;
  }else if (ops.repo === "WALLET_OTP") {
    url = UrlService.WALLET_SERVICE_OTP_PATH + ops.url;
  }else if (ops.repo === "ESCROW_THIRD_PARTY") {
    url = UrlService.ESCROW_THIRD_PARTY_SERVICE_PATH + ops.url;
  }else if (ops.repo === "ESCROW_STORE_FRONT") {
    url = UrlService.ESCROW_STORE_FRONT_SERVICE_PATH + ops.url;
  }
  
  
  let headers = new Headers();
  if(ops.multipart){
    //do nothing 
  } else if (ops.formData) {
    headers.delete('Content-Type');
  } else if (ops.content) {
    headers.append('Content-Type', ops.content);
  } else {
    headers.append('Content-Type', 'application/json');
  }

  if (ops.authenticated) {
    headers.append('Authorization', bearer+UtilService.getToken())
  }

  if (ops.headers) {
    for (var key in ops.headers) {
      headers.append(key, ops.headers[key])
    }
  }

  let body = null
  if(ops.data && ops.multipart){
    body = ops.data;
  } else if (ops.data) {
    body = JSON.stringify(ops.data)
  } 
  var request = new Request(url, {
    method: ops.method,
    body: body,
    headers: headers
  })

  if (ops.headers && ops.headers.response_type) {
    return fetch(request)
      .then(response => {
        return response.text()
      })
      .catch(error => {
        return error
      })
      .then(data => {
        if (data.code === '0403') {
          UtilService.removeSession()
        }
        return data
      })
  }

  return fetch(request)
    .then(response => {
      return response.json()
    })
    .catch(error => {
      return error
    })
    .then(data => {
      if (data.status === '0403') {
        UtilService.removeSession()
        window.location.href = "/login"
      }
      return data
    })
}

request.get = options => {
  options.method = 'GET'
  return request.make(options)
}

request.post = options => {
  options.method = 'POST'
  return request.make(options)
}

request.put = options => {
  options.method = 'PUT'
  return request.make(options)
}

request.delete = options => {
  options.method = 'DELETE'
  return request.make(options)
}

export default request