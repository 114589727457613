import { Layout, BackTop } from 'antd';
import Sidebar from '../shared/wrapper/sidebar'
import HeaderNav from '../shared/wrapper/header'
import Footer from '../shared/wrapper/footer'
// import Twak from '../shared/wrapper/twak'
import '../shared/wrapper/index.css'
import UtilService from '../services/util.service'
import { CaretUpOutlined } from '@ant-design/icons';
import React from 'react';

import './index.css'

const style = {
    height: 40,
    width: 40,
    lineHeight: '40px',
    borderRadius: 4,
    backgroundColor: '#1088e9',
    color: '#fff',
    textAlign: 'center',
    fontSize: 14,
  };

export function Wrapper(props) {

    let firstName = null;
    let lastName = null;

    if(UtilService.getMerchantDetails() !== null){
        firstName = UtilService.getMerchantDetails().firstName;
        lastName = UtilService.getMerchantDetails().lastName;
    }
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sidebar 
                firstName= { firstName ? firstName : props.firstName }
                lastName= { lastName ? lastName : props.lastName }
            />
            <Layout className="site-layout">
                <HeaderNav />

                {props.children}
                <BackTop>
                    <div style={style}><CaretUpOutlined /></div>
                </BackTop>
                <Footer />
            </Layout>
        </Layout>
    )
}