import request from '../../../shared/services/request.service'
import UrlService from './url.service'
import { Component } from 'react';

class WalletService extends Component{

    static getWalletsByUserId(userId){
        let options = Object();

        options.url = UrlService.getWalletsByUserId+"?userId="+userId;
        options.repo = "WALLET_WALLET";
        options.authenticated = true;
      
        return request.get(options);
    }

    static getTransactionsByIdAndCurr(data){
        let options = Object();

        options.url = UrlService.getTransactionsByIdAndCurr;    
        options.repo = "WALLET_TRANSACTION";
        options.authenticated = true;
        options.data = data;

        return request.post(options);
    }

    static getCurrencies(){
        let options = Object();

        options.url = UrlService.getCurrencies;    
        options.repo = "WALLET_CURRENCY";
        options.authenticated = true;

        return request.get(options);
    }

    static createWallet(data){
        let options = Object();

        options.url = "";    
        options.repo = "WALLET_WALLET";
        options.authenticated = true;
        options.data = data;

        return request.post(options);
    }

    static getWalletBalance(data){
        let options = Object();

        options.url = UrlService.getWalletBalance;    
        options.repo = "WALLET_WALLET";
        options.authenticated = true;
        options.data = data;

        return request.post(options);
    }
    
    static doTransfer(data){
        let options = Object();

        options.url = UrlService.doTransfer;    
        options.repo = "WALLET_TRANSACTION";
        options.authenticated = true;
        options.data = data;

        return request.post(options);
    }

    static withdraw(data){
        let options = Object();

        options.url = UrlService.withdraw;    
        options.repo = "WALLET_TRANSACTION";
        options.authenticated = true;
        options.data = data;

        return request.post(options);
    }

    static validateTransferRequest(data){
        let options = Object();

        options.url = UrlService.validateTransferRequest;
        options.repo = "WALLET_TRANSACTION";
        options.authenticated = true;
        options.data = data;

        return request.post(options);
    }

    static validateWithdrawalRequest(data){
        let options = Object();

        options.url = UrlService.validateWithdrawalRequest;
        options.repo = "WALLET_TRANSACTION";
        options.authenticated = true;
        options.data = data;

        return request.post(options);
    }

    static generateOtp(userId) {
        let options = Object();

        options.url = UrlService.generateOtp + "?request=" + userId;
        options.repo = "WALLET_OTP";
        options.authenticated = true;

        return request.get(options);
    }
}

export default WalletService