const urls = {
  getCustomerOrdersByWalletId: "/get-orders-by-wallet-id",
  getPendingOrdersByWalletIdAndProductReference: "/pending/",
  getAllBanks: "/get-all-banks",
  getBankByCode: "/get-bank-by-code",
  getCancellationFee: "/calculate-cancellation-fee",
  updateDeliveryStatus: "/order-reference",
  cancelOrder: "/cancel-order",
  getOrderByReference: "/get-orders-by-reference",
  confirmOrder: "/confirm-order",
  rejectOrder: "/reject-order",
  generateOtp: "/generate-opt",
  getNewOrdersCount: "/get-all-new-orders-by-wallet-id",
  getBatchOrder: "/batch",
  getAllOrdersInBatch: "/",
  getAllOrdersByWalletId: "/walletId/",
  filter: "/filter"
}
export default urls