import React, { Component }  from 'react';
import DynamicIcon from '../dynamicIcon'
import svg from '../../../assets/circle.svg'

import './index.css'

class CardComponent extends Component{
    render() {
        const { name, content, icon, gradient } = this.props;

        return (
            <div className="stretch-card" style={{width: '100%'}}>
                <div className={"card " + gradient}>
                    <div className="card-body">
                        <img src = {svg} className="card-img-absolute" alt=""/>
                        <div>
                            <div style={{float: "left"}}>
                                <h4 className="card-title">{ name }</h4>
                            </div>
                            <div style={{float: "right"}}>
                                <DynamicIcon type = { icon } style={{color: "#fff"}}/>
                            </div>
                        </div>
                        <br /><br />
                        <div style={{float: "left", marginTop: "30px"}}>
                            <h4 className="card-content">{ content }</h4>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default CardComponent