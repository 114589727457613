import React, { Component }  from 'react';
import { Button, Modal, Form, Input, Checkbox, Tag, Select, Divider } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import Notification from '../../shared/components/notification';
import ProductService from './services/product.service'

import './index.css'

const { TextArea } = Input;
const { Option } = Select;

class EditProduct extends Component {

    componentDidMount(){
        this.getAllCategories()
    }

    initialFormState = {
        ...this.props.product,
    }

    state = { 
        formData: this.initialFormState,
        newSelectedFile: undefined,
        selectedImage: '',
        open: false,
        loading: false,
        notification: {},
        deliveryDateList: ['Same day', '1 - 3 days', '3 - 5 days', '5 - 7 days', '7 - 14 days'],
        deliveryDateItem: '',
        categories: [],
        categoryId: this.initialFormState.category.id
    }

    getAllCategories = () =>{
        ProductService.getAllCategories()
        .then(response => {
            this.setState({
                categories: response
            })
        })
    }

    open = () => { 
        this.setState({ open: true  });
    }

    close = () => {
        this.setState({ open: false, selectedImage: '' });
        this.clearForm();
    }

    submit = () => {
        const { formData, categoryId, newSelectedFile } = this.state;
        let request = formData
        
        request={
            ...request,
            categoryId
        }

        if (this.validForm(request)) {
            this.setState({
                loading:true
            })

            const formData2 = new FormData();
            for (const key in request) {

                if(key !== "mediaLink"){
                    formData2.append(key, request[key]);
                }else if(key === "mediaLink" && newSelectedFile !== undefined){
                    formData2.append(key, request[key]);
                }

            }


            ProductService.updateProductLink(formData2, formData.productRef)
            .then(response => {
                if(response.productName){
                    this.notify("success", "Success", "Successfully updated")
                    setTimeout(function(){ window.location.reload(); }, 2000);
                }else{
                    this.setState({
                        loading:false
                    })
                    this.notify("error", "Unable to process request", response.message)
                }
            })
        }
    }

    validForm = (formData) => {

        if(formData.productName === undefined || formData.productName === "") {
            this.notify("error", "Unable to process request", "Product name is required")
            return false;
        }
        if(formData.categoryId === undefined || formData.categoryId === "") {
            this.notify("error", "Unable to process request", "Please provide a category")
            return false;
        }
        if(formData.price === undefined || formData.price === "") {
            this.notify("error", "Unable to process request", "Price is required")
            return false;
        }
        if(isNaN(formData.price) || formData.price <= 0) {
            this.notify("error", "Invalid price supplied", "Price should be a numeric value greater than 0")
            return false;
        }
        if(formData.quantity === undefined || formData.quantity === "") {
            this.notify("error", "Unable to process request", "Quantity should be minimum of 1")
            return false;
        }
        if(isNaN(formData.quantity) || formData.quantity <= 0) {
            this.notify("error", "Unable to process request", "Quantity should be minimum of 1")
            return false;
        }
        if(isNaN(formData.discount) || formData.discount < 0) {
            this.notify("error", "Invalid discount supplied", "Discount should be a numeric value not less than 0")
            return false;
        }
        if(formData.discount > 99) {
            this.notify("error", "Unable to process request", "Discount can not be 100%")
            return false;
        }
        if(formData.deliveryDate === undefined || formData.deliveryDate === "") {
            this.notify("error", "Unable to process request", "Delivery date is required")
            return false;
        }
        if(formData.mediaLink && formData.mediaLink.length > 250) {
            this.notify("error", "Unable to process request", "Media link should be maximum of 250 characters")
            return false;
        }
        if(formData.description === undefined || formData.description === "") {
            this.notify("error", "Unable to process request", "Description is required")
            return false;
        }
        if(formData.description.length > 500) {
            this.notify("error", "Unable to process request", "Description should be maximum of 500 characters")
            return false;
        }

        return true;
    }

    handleInputChange = (e) => {
        const { name, value} = e.target
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        })
    }

    handleFileUploadChange = (e) => {
        const {newSelectedFile} = this.state;
        let newFile = e.target.files[0]; 
        
        //to display the file in the <img> tag
        const reader = new FileReader();
        reader.onloadend = () => {
            this.setState({ selectedImage: reader.result });
        };
        if (newFile) {
            reader.readAsDataURL(newFile);
        }
        //to display the file in the <img> tag

        this.setState({
            formData: {
                ...this.state.formData,
                mediaLink: newFile,
            },
            newSelectedFile: newFile,
        });
    };
    

    handlePriceChange = (e) => {
        const { name, value} = e.target
        let formattedValue = value.split(',').join('')
        this.setState({ 
            formData: {
                ...this.state.formData,
                [name]: formattedValue
            }
        });
    };

    handleCheckboxChange = (e) => {
        const { name, checked } = e.target
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: checked  === true ? "ACTIVE" : "INACTIVE"
            }
        })
    }

    handleTextAreaChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                description: e.target.value
            }
        })
    }

    handleDateChange = (params) => {
        const { date, name } = params;
        if (date) {
          let formData = { ...this.state.formData };
          formData[name] = date;
    
          this.setState({ formData });
        }
    };

    clearForm = () => {
        this.setState({
            formData: this.initialFormState
        })
    }

    handleDeliveryDateChange = value => {
        this.setState({
            formData : {
                ...this.state.formData,
                deliveryDate: value
            }
        })
    }

    handleSelectChange = (e) => {
        this.setState({
            categoryId: e,
        })
    };

    onDeliveryDateChange = event => {
        this.setState({
          deliveryDateItem: event.target.value,
        });
    };

    addItem = () => {
        const { deliveryDateList, deliveryDateItem } = this.state;
        if(deliveryDateItem.length > 0){
            this.setState({
                deliveryDateList: [...deliveryDateList, deliveryDateItem],
                deliveryDateItem: '',
              });
        }
    };

    notify = (type, title, message) => {
        let notification = {
          type: type,
          title: title,
          message: message,
        };
        this.setState({ notification });
    };

    clearNotificationType = () => {
        this.setState({notification: {}})
    }

    render() {
        const { open, loading, formData, deliveryDateList, deliveryDateItem, selectedImage } = this.state;
        //Try to fix this function, it also adds (,) after decimal
        let formattedPrice = formData.price === undefined ? '' : ''+formData.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        return (
            <>
                <Notification notification={this.state.notification} clearNotificationType = {this.clearNotificationType} />
                <Button onClick={this.open} type="text"><EditOutlined /> </Button>
                <Modal visible={open}
                    width={500}
                    title="Edit Product"
                    onOk={this.submit}
                    onCancel={this.close}
                    maskClosable={true}
                    footer={[
                        <Button key="back" onClick={this.close}>
                        Cancel
                        </Button>,
                        <Button key="submit" type="primary" loading={loading} onClick={this.submit}>
                        Update
                        </Button>,
                    ]}
                >
                    <Form>
                        <Form.Item>
                            <label>Product Name:</label>
                            <Input name="productName" placeholder="Product Name"
                                value={formData.productName} onChange={this.handleInputChange} />
                        </Form.Item>
                        <Form.Item>
                            <label>Category:</label> <span style={{color: "red"}}>*</span>
                            <Select onChange={this.handleSelectChange} defaultValue={formData.category.id} placeholder="Select Category">
                                {this.state.categories.map((option) =>
                                <Option key={option.name} value={option.id}>
                                    {option.name}
                                </Option>
                                )}
                            </Select> 
                        </Form.Item>
                        <Form.Item>
                            <label>Price (NGN):</label>
                            <Input name="price" placeholder="Price"
                                value={formattedPrice} onChange={this.handlePriceChange} />
                        </Form.Item> 
                        <Form.Item>
                            <label>Quantity:</label>
                            <Input name="quantity" placeholder="Quantity" type='number' min="1"
                                value={formData.quantity} onChange={this.handleInputChange} />
                        </Form.Item> 
                        <Form.Item>
                            <label>Estimated Delivery Date:</label>
                            <Select
                                style={{ width: '100%' }}
                                placeholder="Select delivery date"
                                onChange={this.handleDeliveryDateChange}
                                value={formData.deliveryDate}
                                dropdownRender={menu => (
                                <div>
                                    {menu}
                                    <Divider style={{ margin: '4px 0' }} />
                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                    <Input style={{ flex: 'auto' }} value={deliveryDateItem}
                                    onChange={this.onDeliveryDateChange} placeholder="E.g. 1 month, 3 weeks"/>
                                    <a  href="#"
                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                        onClick={this.addItem}
                                    >
                                        <PlusOutlined /> Add item
                                    </a>
                                    </div>
                                </div>
                                )}
                            >
                                {deliveryDateList.map(date => (
                                <Option key={date}>{date}</Option>
                                ))}
                            </Select>
                        </Form.Item>  
                        <Form.Item >
                            <label>Discount:</label>
                            <Input name="discount" placeholder="discount (%)" min="0" type="number"
                                value={formData.discount} onChange={this.handleInputChange} />
                        </Form.Item>  

                        {formData.mediaLink !== null &&
                        <Form.Item>
                            <img
                                src={selectedImage != '' ? selectedImage : formData.mediaLink}
                                alt="image"
                                width="100%"/>
                        </Form.Item>}

                        <Form.Item>
                            <label>Media Link:</label>
                            <Input name="mediaLink" type='file' onChange={this.handleFileUploadChange} />
                        </Form.Item>  
                        <Form.Item>
                            <label>Detailed Description (max 500 characters):</label>
                            <TextArea name="description" placeholder="Description" value={formData.description} onChange={this.handleTextAreaChange} />
                        </Form.Item>
                        <Form.Item>
                            <Checkbox
                                name="status"
                                onChange={this.handleCheckboxChange}
                                checked={formData.status === "ACTIVE" ? true : false}>
                                {formData.status === "ACTIVE" ?
                                    <Tag color="#65D6C9" className="product-status">Active</Tag> :
                                    <Tag color="#FE9C96" className="product-status">Inactive</Tag> }
                            </Checkbox>
                        </Form.Item>                         
                    </Form>
                </Modal>
            </>   
        )
    }
}

export default EditProduct;