import React, {Component} from "react";
import {Row, Col, Tag} from "antd";
import './index.css';

class ResponsiveView extends Component{
    state = {
        data: this.props.data,
    }

    handleDeliveryStatus = (deliveryStatus) =>{
        if(deliveryStatus === 'NEW'){
            return <Tag color="#A4A4A4" className="deliveryStatus" style={{cursor: "default", width: 60, height: 20, fontSize: 7, marginRight: 0}}>{deliveryStatus}</Tag>
        }else if(deliveryStatus === 'ENROUTE'){
            return <Tag color="#FFCC00" className="deliveryStatus" style={{cursor: "default", width: 60, height: 20, fontSize: 7, marginRight: 0}}>{deliveryStatus}</Tag>
        }else if(deliveryStatus === 'DELIVERED'){
            return <Tag color="#68BAFD" className="deliveryStatus" style={{cursor: "default", width: 60, height: 20, fontSize: 7, marginRight: 0}}>{deliveryStatus}</Tag>
        }else if(deliveryStatus === 'CONFIRMED'){
            return <Tag color="#65D6C9" className="deliveryStatus" style={{cursor: "default", width: 60, height: 20, fontSize: 7, marginRight: 0}}>{deliveryStatus}</Tag>
        }else{
            return <Tag color="#FE9C96" className="deliveryStatus" style={{cursor: "default", width: 60, height: 20, fontSize: 7, marginRight: 0}}>{deliveryStatus}</Tag>
        }
    }

    formatDate = (timeOfDelivery) => {
        let dateDelivered = new Date(timeOfDelivery);
        let hours = dateDelivered.getHours();
        let minutes = dateDelivered.getMinutes();
        let ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0'+minutes : minutes;
        let strTime = " " + hours + ':' + minutes + ' ' + ampm;
    
        return dateDelivered.toLocaleDateString() + strTime;
    };
    

    render() {
        const {data} = this.state;
        return(
            <div className="TT-responsive-card">
                <Row className="responsive-view-row">
                    <Col span={12} style={{fontSize: "8px"}}>
                        <span style={{fontSize: 10, color: "gray"}}>Order Ref: </span><br/>
                        <span style={{fontSize: "8px"}}>{data.orderReference && data.orderReference !== null ? data.orderReference : "NIL" }</span>
                    </Col>
                    
                    <Col span={12}>
                        <span style={{fontSize: 10, color: "gray"}}>Status: </span><br/>
                        {data.deliveryStatus && data.deliveryStatus !== null ? this.handleDeliveryStatus(data.deliveryStatus) : "NIL"}
                    </Col>
                </Row>
                <br></br>
                <Row className="responsive-view-row">
                    <Col span={12}>
                        <span style={{fontSize: 10, color: "gray"}}>Customer Name: </span><br/>
                        {data.name && data.name !== null ? data.name : "NIL" }
                    </Col>
                    <Col span={12}>
                        <span style={{fontSize: 10, color: "gray"}}>Date Ordered: </span><br/>
                        <div style={{fontSize: "8px"}}>{data.createdDate && data.createdDate !== null ? this.formatDate(data.createdDate) : "-- -- --" }</div>
                    </Col>
                </Row>
                <br></br>
                <Row className="responsive-view-row">
                    <Col span={24}>
                        <span style={{fontSize: 10, color: "gray"}}>Address: </span><br/>
                        <div style={{fontSize: "8px"}}>{data.address && data.address !== null ? data.address : "NIL" }</div>
                    </Col>
                </Row>
                <br></br>
                <Row className="responsive-view-row">
                    <Col span={24}>
                        <span style={{fontSize: 10, color: "gray"}}>Product Ref: </span><br/>
                        <div style={{fontSize: "8px"}}>{data.productReference && data.productReference !== null ? data.productReference : "NIL" }</div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default ResponsiveView;