import React, { Component } from 'react';
import {Layout, Menu, Row, Col, Button, Badge, Modal, Popconfirm, Empty, Spin, Breadcrumb } from 'antd';
import {ShoppingCartOutlined, DeleteOutlined}from '@ant-design/icons';
import Notification from '../../shared/components/notification';
import ProductService from '../product/services/product.service'
import Loading from '../../shared/components/loading/index'
import logo from '../../assets/Paydoor.png'
import "../storeFront/index.css"
import { Link } from 'react-router-dom';


const { Header, Content, Footer } = Layout;

class CartComponent extends Component {

    componentDidMount(){
        this.getAllProductsByReference();

        var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
        (function(){
        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src='https://embed.tawk.to/629bd9d0b0d10b6f3e75b86f/1g4ocllu4';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
        })();  
    }

    state = {
        loading: true,
        notification: {},
        showProductModal: false,
        cart: localStorage.getItem('cart') !== null ? JSON.parse(localStorage.getItem('cart')) : [],
        currentItem: [],
        data: [],
        cartSummary: 0,
        cartSummaryLoader: true,
        totalItemsInCart: 0
    }

    getAllProductsByReference = () =>{

        const cart = localStorage.getItem('cart') !== null ? JSON.parse(localStorage.getItem('cart')) : [];

        if(cart.length > 0){
            let referenceList = []

            cart.map(item => {
                referenceList=[
                    ...referenceList,
                    item.productRef
                ]
            });
            this.dail(referenceList)
        }else{
            this.setState({
                loading: false
            })
        }
    }

    dail = (referenceList) =>{
        const productReferences = {
            productReferences : referenceList
        } 

        ProductService.getAllProductsByListOfReferences(productReferences)
        .then(response => {
            if(response && response.length > 0){
                this.setState({
                    data: response,
                }, () => {
                    this.getSubTotal(this.state.cart)
                    this.getTotalItemsInCart()
                })
            }else{
                this.setState({
                    loading: false
                })
            }
        })  
    }

    getSubTotal = (data) =>{

        this.setState({
            cartSummaryLoader: true
        })

        const items = {
            items : data
        } 

        ProductService.getCartSummary(items)
        .then(response => {
            if(response && response.status === "success"){
                this.setState({
                    cartSummary: response.message,
                    loading: false,
                    cartSummaryLoader: false
                })
            }else{
                this.setState({
                    loading: false,
                    cartSummaryLoader: false
                })
            }
        }) 
    }

    getTotalItemsInCart = () =>{
        let totalQuantity = 0;
        this.state.cart.map((item) => {
            totalQuantity = (totalQuantity + item.quantity)
            return totalQuantity
        })
        this.setState({
            totalItemsInCart: totalQuantity
        })
    }

    openProductModal = (item) => {
        this.setState ({
            showProductModal: true,
            currentItem: item
        })
    }

    closeProductModal = () => {
        this.setState ({
            showProductModal: false,
            currentItem: []
        })
    }

    removeItem = (itemToDelete) =>{
        let cartItems = this.state.cart
        
        for (var i =0; i< cartItems.length; i++) {
            var item = cartItems[i];
            if (item.productRef === itemToDelete.productRef) {
                cartItems.splice(i, 1);
            }
        }
        this.setState({
            cart: cartItems
        }, () => {
            this.updateLocalStorage()
            this.getAllProductsByReference()
            this.notify("success", "Successful", "Successfully removed from cart");
        })
    }
   
    updateLocalStorage = () =>{
        localStorage. setItem("cart", JSON.stringify(this.state.cart))
    }

    increaseQuantity = (reference) =>{
        let itemIndex = this.state.cart.findIndex((c) => c.productRef === reference)

        if(itemIndex >= 0){
            this.state.cart[itemIndex] = {
                ...this.state.cart[itemIndex],
                quantity: this.state.cart[itemIndex].quantity + 1
            }
            this.setState({
                cart: [
                    ...this.state.cart,
                ]
            }, () => {
                this.updateLocalStorage()
                this.getSubTotal(this.state.cart)
                this.getTotalItemsInCart()
            })
        }
    }

    decreaseQuantity = (reference) =>{
        let itemIndex = this.state.cart.findIndex((c) => c.productRef === reference)

        if(itemIndex >= 0){
            this.state.cart[itemIndex] = {
                ...this.state.cart[itemIndex],
                quantity: this.state.cart[itemIndex].quantity === 1 ? 1 : this.state.cart[itemIndex].quantity - 1
            }
            this.setState({
                cart: [
                    ...this.state.cart,
                ]
            }, () => {
                this.updateLocalStorage()
                this.getSubTotal(this.state.cart)
                this.getTotalItemsInCart()
            })
        }
    }

    

    
    notify = (type, title, message) => {
        let notification = {
          type: type,
          title: title,
          message: message,
        };
        this.setState({ notification });
    };

    clearNotificationType = () => {
        this.setState({notification: {}})
    };

  render(){
    
    const {showProductModal, currentItem, loading, data, cart, cartSummary, cartSummaryLoader, totalItemsInCart} = this.state
    const totalCount = Object.values(cart).flat().length;
    let mergedList = data.map((item) => Object.assign({}, item, cart.find((product) => product.productRef === item.productRef)));

    const params = { 
        pathname: "/user/cart/checkout", 
        amount: cartSummary,
        cart: this.state.cart
      };

    return (
        <Layout>
          <Notification notification={this.state.notification} clearNotificationType = {this.clearNotificationType} />
          <Header style={{ position: 'sticky', top: 0, zIndex: 1, width: '100%' }}>
          <div style={{float: 'right'}}>
                <Badge size="small" offset={[7, 0]} count={totalCount}>
                    <ShoppingCartOutlined style={{color:"white", fontSize: 25}}/>
                </Badge>
            </div>
            <div>
                <div className="logo" style={{marginTop: -10, marginLeft: -10, textAlign: "left"}}>
                    <img src={ logo } style={{width: 100, height: 25}} alt =""/>
                </div>
            </div>
            <Menu
              theme="dark"
            />
          </Header>
          <Content className="site-layout">
            <Breadcrumb style={{ margin: '16px 0', marginBottom:"-15px", padding: "0px 30px"}}>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>store</Breadcrumb.Item>
                <Breadcrumb.Item>cart</Breadcrumb.Item>
            </Breadcrumb>
            <br/>
            {loading && <div style={{background:"white", height:500, width:"auto"}}><Loading background={"#fff"}/></div>}
            {!loading &&
            <Row>
                <Col xs={0} sm={0} md={24} style={{padding: "0px 30px"}}>
                    <div style={{height: "auto", marginTop:"30px"}}>
                        <Row>
                            {totalCount === 0 && !this.state.loading &&
                            <>
                                <Col xs={24} sm={24} md={24} xl={24} style={{padding:"10px 20px 0px", background:"white"}}>
                                    <h3>Cart ({totalCount})</h3>
                                </Col>
                                <Col xs={24} sm={24} md={24} xl={24}>
                                    <div className="" style={{background: "#fff", padding:150, paddingTop: 100, height: "500px",  boxShadow: "0 1px 9px -3px rgba(0, 0, 0, 0.2)"}}>
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    </div>
                                </Col>
                            </>}
                            {totalCount > 0 && !this.state.loading &&
                                <>
                                <Col xs={24} sm={24} md={16} xl={16} style={{background:"white"}}>
                                    <div style={{padding:"10px 20px 0px", background:"white"}}>
                                        <h3>Cart ({totalCount})</h3>
                                    </div>
                                    <hr style={{height: "0.5px", borderWidth: 0, color: "gray", backgroundColor: "gray"}}></hr>
                                    {mergedList.map((item) => {
                                        return(<>
                                        <Row>
                                            <Col xs={3} sm={3} md={3} xl={3} style={{margin: "auto", cursor:"pointer"}} onClick={() => this.openProductModal(item)}>
                                                <div style={{padding: "0px 10px 0 10px"}}>
                                                    <img src={item.mediaLink === null ? "http://www.sitech.co.id/assets/img/products/default.jpg" : item.mediaLink} 
                                                    alt="image"  className="cover"/>
                                                </div>
                                            </Col>
                                            <Col xs={16} sm={16} md={16} xl={16}>
                                                <div style={{padding: 20}}>
                                                    <h3>{item.productName}</h3>
                                                    <h4 style={{color: "gray"}}>Seller : {item.businessName}</h4>
                                                    <Button type="primary" shape="circle" size="small" onClick={()=>this.decreaseQuantity(item.productRef)}>-</Button>
                                                    &nbsp;&nbsp;{item.quantity}&nbsp;&nbsp;
                                                    <Button type="primary" shape="circle" size="small" onClick={()=>this.increaseQuantity(item.productRef)}>+</Button>
                                                </div>
                                            </Col>
                                            <Col xs={5} sm={5} md={5} xl={5} style={{padding: 20,  margin: "auto"}}>
                                                <div>
                                                    <div><b>N{item.discountedPrice.toLocaleString()}</b></div>
                                                    {item.discount > 0 &&
                                                    <div>
                                                        <div style={{float: 'left', textDecoration: "line-through", color: "gray", padding:3}}>{item.price.toLocaleString()}</div>
                                                        <div style={{float: 'left', border:"0.5px solid red", padding:2}}>{item.discount > 0 ? `${-item.discount.toLocaleString()}%` : item.discount.toLocaleString()}</div>
                                                    </div>}
                                                </div>
                                                <br/><br/>
                                                <div>
                                                    <Popconfirm title="Are you sure you want to remove this item?" okText="CONFIRM" onConfirm={() => this.removeItem(item)}>
                                                        <DeleteOutlined style={{color: "red", fontSize: 20}}/>
                                                    </Popconfirm>
                                                </div>
                                            </Col>
                                        </Row>
                                        <hr style={{height: "0.5px", borderWidth: 0, color: "gray", backgroundColor: "gray"}}></hr>
                                        </>)
                                    })}
                                </Col>
                                <Col xs={24} sm={24} md={7} xl={7} style={{border: "0.5px solid gray", background:"white", padding:"50px", height:"300px"}} offset={1}>
                                <div>
                                    <div>
                                        <b>Cart summary</b>
                                    </div>
                                    <hr style={{height: "0.5px", borderWidth: 0, color: "gray", backgroundColor: "gray"}}></hr>
                                    
                                    <Row>
                                        <Col xs={24} sm={24} md={12} xl={12}><h3><b>Subtotal: </b></h3></Col>
                                        <Col xs={24} sm={24} md={12} xl={12}><h3>{cartSummaryLoader ?  <Spin /> : Number(cartSummary).toLocaleString()}</h3></Col>
                                    </Row>
                                    <Row>
                                        <Col xs={24} sm={24} md={12} xl={12}><h3><b>Total Item(s): </b></h3></Col>
                                        <Col xs={24} sm={24} md={12} xl={12}><h3>{totalItemsInCart}</h3></Col>
                                    </Row>
                                    <br/><br/>
                                    <div>
                                    <Link to={params}>
                                        <Button size='large' block type='primary'> Checkout</Button>
                                    </Link>
                                    </div>
                                </div>
                            </Col>
                            </>}
                        </Row>
                    </div>
                </Col>
            
                {/* ===========>>>>RESPONSIVE MOBILE PRODUCT VIEW<<<<================  */}
                <Col md={0} lg={0} style={{width: "100%"}}>
                    <Row>
                        {totalCount === 0 && !this.state.loading &&
                        <Col xs={24} sm={24} md={16} xl={16}>
                            <div className="" style={{background: "#fff", padding:50, paddingTop: 50, height: "500px",  boxShadow: "0 1px 9px -3px rgba(0, 0, 0, 0.2)"}}>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </div>
                        </Col>}
                        {totalCount > 0 && !this.state.loading &&
                        <>
                            <Col xs={24} sm={24} md={16} xl={16} style={{background:"white"}}>
                                <div style={{padding:"20px 20px 0px", background:"white"}}>
                                    <h3>Cart ({totalCount})</h3>
                                </div>
                                <hr style={{height: "0.5px", borderWidth: 0, color: "gray", backgroundColor: "gray"}}></hr>
                                {mergedList.map((item) => {
                                    return(<>
                                        <Row>
                                            <Col xs={5} sm={5} md={5} xl={5} style={{margin: "auto"}} onClick={() => this.openProductModal(item)}>
                                                <div style={{marginLeft: 15}}>
                                                    <img src={item.mediaLink === null ? "http://www.sitech.co.id/assets/img/products/default.jpg" : item.mediaLink}  
                                                    alt="image"  className="cover-responsive"/>
                                                </div>
                                            </Col>
                                            <Col xs={14} sm={14} md={14} xl={14}>
                                                <div style={{paddingTop: 20, paddingLeft: 20}}>
                                                    <h3>{item.productName}</h3>
                                                    <h4 style={{color: "gray"}}>Seller : {item.businessName}</h4>
                                                </div>
                                                <div style={{marginLeft: 20}}>
                                                    <Button type="primary" shape="circle" size="small" onClick={()=>this.decreaseQuantity(item.productRef)}>-</Button>
                                                    &nbsp;&nbsp;{item.quantity}&nbsp;&nbsp;
                                                    <Button type="primary" shape="circle" size="small" onClick={()=>this.increaseQuantity(item.productRef)}>+</Button>
                                                </div>
                                            </Col>
                                            <Col xs={5} sm={5} md={5} xl={5} style={{margin: "auto", paddingRight: 15}}>
                                                <div style={{margin: "auto"}}>
                                                    <div><b>N{item.discountedPrice.toLocaleString()}</b></div>
                                                    {item.discount > 0 &&
                                                    <div>
                                                        <div style={{float: 'left', textDecoration: "line-through", color: "gray", padding:3}}>{item.price.toLocaleString()}</div>
                                                        <div style={{float: 'left', border:"0.5px solid red", padding:2}}>{item.discount > 0 ? `${-item.discount.toLocaleString()}%` : item.discount.toLocaleString()}</div>
                                                    </div>}
                                                </div>
                                                <br/><br/><br/>
                                                <div>
                                                    <Popconfirm title="Are you sure you want to remove this item?" okText="CONFIRM" onConfirm={() => this.removeItem(item)}>
                                                        <DeleteOutlined style={{color: "red", fontSize: 20}}/>
                                                    </Popconfirm>
                                                </div>
                                            </Col>
                                        </Row>
                                        <hr style={{height: "0.5px", borderWidth: 0, color: "gray", backgroundColor: "gray"}}></hr>
                                    </>)
                                })}
                            </Col>
                        
                            <Col xs={24} sm={24} md={8} xl={8} style={{ background:"white", padding:"50px"}}>
                                <div>
                                    <div>
                                        <b>Cart summary</b>
                                    </div>
                                    <hr style={{height: "0.5px", borderWidth: 0, color: "gray", backgroundColor: "gray"}}></hr>
                                    
                                    <Row>
                                        <Col xs={12} sm={12} md={12} xl={12}><h3><b>Subtotal: </b></h3></Col>
                                        <Col xs={12} sm={12} md={12} xl={12}><h3>{cartSummaryLoader ?  <Spin /> : Number(cartSummary).toLocaleString()}</h3></Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} xl={12}><h3><b>Total Item(s): </b></h3></Col>
                                        <Col xs={12} sm={12} md={12} xl={12}><h3>{cartSummaryLoader ?  <Spin /> : Number(cartSummary).toLocaleString()}</h3></Col>
                                    </Row>
                                    <br/><br/>
                                    <Link to={params}>
                                        <Button size='large' block type='primary'> Checkout</Button>
                                    </Link>
                                </div>
                            </Col>
                        </>}
                    </Row>
                </Col>
            </Row>}
            <Modal visible={showProductModal}
                title="Product reference"
                width={800}
                onCancel={this.closeProductModal}
                maskClosable={true}
                footer={null}
            >
                <div style={{width: "100%"}}>
                    <img src={currentItem !== undefined && currentItem.mediaLink !== null ? currentItem.mediaLink : "http://www.sitech.co.id/assets/img/products/default.jpg"} 
                    alt="image" className="cover"/>
                </div>
                <div style={{padding: "25px 0px 25px 0px"}}>
                    <h2>{currentItem !== undefined ? currentItem.productName : ""}</h2>
                    
                    <h3>Product details</h3>
                    <hr style={{height: "0.5px", borderWidth: 0, color: "gray", backgroundColor: "gray"}}></hr>
                    <p>
                        {currentItem !== undefined ? currentItem.description : ""}
                    </p>
                </div>
            </Modal>
          </Content>
          <Footer style={{ textAlign: 'center'}}>Paydoor ©2021 Created by Blaise Leo</Footer>
        </Layout>
      );
  }
};

export default CartComponent;